import { di } from 'react-magnetic-di';
import { useSendMessage as useSendVirtualAgentMessage } from '@atlassian/help-center-virtual-agent/src/controllers/send-message';
import { ConversationProvider } from '../../../common/types';
import { useActions as useAIAnswersActions } from '../../../store/converse';
import { useActions, useProvider } from '../../../store/routing';

export const useSendMessage = () => {
    di(useProvider, useActions, useAIAnswersActions, useSendVirtualAgentMessage);
    const oldProvider = useProvider();
    const { decideProvider } = useActions();
    const { sendMessage: sendVirtualAgentMessage } = useSendVirtualAgentMessage();
    const {
        initConversationForUI,
        takeOverConversationFromVA,
        addHelpseekerMessage: addAIAnswersMessage,
    } = useAIAnswersActions();

    let provider = oldProvider;
    const sendMessage = async (message: string) => {
        if (!provider) {
            initConversationForUI(message);
            provider = await decideProvider(message);
        }

        if (provider === ConversationProvider.AI_ANSWERS) {
            if (!oldProvider) {
                await takeOverConversationFromVA();
            } else {
                addAIAnswersMessage(message);
            }
        }

        if (provider === ConversationProvider.VIRTUAL_AGENT) {
            sendVirtualAgentMessage(message);
        }
    };

    return { sendMessage };
};
