import type { State } from './types';

export const initialState: State = {
    isLoading: false,
    conversation: null,
    searchAll: false,
    pipeline: '',
    isTesting: false,
    feedbackCollectorConfig: {
        isVisible: false,
        messageId: undefined,
    },
    isAuthPromptVisible: false,
    providerAuthStatus: [],
    activeTimer: null,
    ui: {
        conversationScrollBarWidth: '0px',
    },
};

export const ASSISTANT_USER_TYPE = 'assistant';

export const AUTHENTICATION_USER_TYPE = 'authentication';

export const HELP_SEEKER_USER_TYPE = 'helpseeker';
