import React from 'react';
import magnifyingGlass from 'assets/images/search-modal-magnifying-glass.svg';
import { useIntl } from 'react-intl-next';
import Heading from '@atlaskit/heading';
import { Stack } from '@atlaskit/primitives';
import { Img } from '../common';
import messages from './messages';

export const SearchResultNone = ({ term }: { term: string }) => {
    const { formatMessage } = useIntl();

    return (
        <Stack alignInline="center">
            <Img src={magnifyingGlass} alt="" />
            <Heading as="h2" level="h400">
                {formatMessage(messages.noSearchResultTitle)}
            </Heading>
            {/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Disabled to rollout go/ui-styling-standard tooling, please resolve */}
            <p style={{ textAlign: 'center' }}>{formatMessage(messages.noSearchResultText, { term })}</p>
        </Stack>
    );
};
