import { defineMessages } from 'react-intl-next';

export default defineMessages({
    noSearchResultTitle: {
        defaultMessage: 'We couldn\u2019t find a match for your search',
        description: 'Text to tell the user that there was no search results found after they have conducted a search',
        id: 'cv.search.none.no-search-result-title',
    },

    noSearchResultText: {
        defaultMessage:
            'We couldn\u2019t find any matches for \u2018{ term }\u2019. Check your search for typos, or try another search term.',
        description: 'Text to tell the user how to proceed after finding no search results',
        id: 'cv.search.none.no-search-result-text',
    },
});
