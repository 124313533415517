import type { StoreActionApi } from 'react-sweet-state';
import { sendUIEvent } from '../../../common/utils';
import type { State } from '../types';

export const clearConversation =
    () =>
    ({ getState, setState }: StoreActionApi<State>) => {
        // https://data-portal.internal.atlassian.com/analytics/registry/69030
        sendUIEvent({
            action: 'clicked',
            actionSubject: 'clearConversationButton',
        });

        const { abortController } = getState();

        if (abortController) {
            abortController.abort();
        }
        setState({ messages: [], conversationId: undefined, isLoading: false, abortController: undefined });
    };
