import type { StoreActionApi } from 'react-sweet-state';
import type { IntentInferenceProvider } from '../../common/types';
import { ConversationProvider } from '../../common/types';
import type { State } from './types';

export const decideProvider =
    (message: string) =>
    async (
        { setState }: StoreActionApi<State>,
        { intentInferenceProvider }: { intentInferenceProvider: IntentInferenceProvider }
    ): Promise<ConversationProvider> => {
        try {
            setState({ isRouting: true });
            const intentResponse = await intentInferenceProvider.inferIntent(message);
            const hasMatchedIntents = intentResponse.highConfidenceIntent || intentResponse.suggestedIntents.length > 0;
            if (hasMatchedIntents) {
                setState({ provider: ConversationProvider.VIRTUAL_AGENT, isRouting: false });
                return ConversationProvider.VIRTUAL_AGENT;
            }
            setState({ provider: ConversationProvider.AI_ANSWERS, isRouting: false });
            return ConversationProvider.AI_ANSWERS;
        } catch {
            // Fall back to AI answers if inference fails
            setState({ provider: ConversationProvider.AI_ANSWERS, isRouting: false });
            return ConversationProvider.AI_ANSWERS;
        }
    };

export const setProvider =
    (provider: ConversationProvider | undefined) =>
    ({ setState }: StoreActionApi<State>) =>
        setState({ provider });

export const resetStateOnContextIdChanged =
    () =>
    ({ setState, getState }: StoreActionApi<State>, { contextId }: { contextId: string }) => {
        const { contextId: storedContextId } = getState();
        if (storedContextId !== contextId) {
            setState({
                contextId,
                provider: undefined,
                isRouting: false,
            });
        }
    };
