import { isRedirectionToNotFoundPageFixEnabled, isWrmOptimizationsEnabled } from 'feature-flags';
import { awaitGlobal } from '@atlassian/help-center-common-util/connect/await-global';
import { initialise as initialiseConnect } from '@atlassian/help-center-common-util/connect/register-api';
import { correctedHashModeReplaceUrl } from '@atlassian/help-center-common-util/hash-url';
import { removeParamsFromUrl, set } from '@atlassian/help-center-common-util/history-qs';
import { href, replace } from '@atlassian/help-center-common-util/location';
import { isSentryEnabled } from '@atlassian/help-center-common-util/sentry';
import { install as installSentry } from '@atlassian/help-center-common-util/sentry-installer';
import { sentryQueue } from '../sentry-queue';

export const initialiseConnectAndSentryAndCleanUrl = () => {
    if (!isWrmOptimizationsEnabled()) {
        awaitGlobal('connectHost', initialiseConnect);
    }

    if (isSentryEnabled()) {
        installSentry();
        sentryQueue.flushErrors();
    }

    let url = href();

    const modifiedUrl = removeParamsFromUrl(url, ['sda_source']);
    if (url !== modifiedUrl) {
        set({
            sda_source: undefined,
        });
    }
    if (!isRedirectionToNotFoundPageFixEnabled()) {
        url = href();
        const hashModeReplaceUrl = correctedHashModeReplaceUrl(url);
        if (hashModeReplaceUrl && url !== hashModeReplaceUrl) {
            replace(hashModeReplaceUrl);
        }
    }
};
