import { metrics } from '@atlassian/browser-metrics';
import { ScreenName } from '@atlassian/help-center-common-component/constants';

export const getSSRConfig = () => {
    if (window.__IS_PAGE_SERVER_SIDE_RENDERED__) {
        return {
            ssr: {
                doneAsFmp: true,
            },
        };
    }

    return {};
};

/**
 * Object containing page load metric for all instrumented screens,
 * as we instrument BM3 for more screens we update values here
 * all new keys will follow the `service-management.help-center.[screenName]` schema
 * and can be accessed using `jira.fe.page-load.service-management.help-center.[screenName]`
 */
const perfConfig = {
    [ScreenName.HELP_CENTER]: metrics.pageLoad({
        key: 'service-management.help-center.portal-help-center',
        ...getSSRConfig(),
    }),
    [ScreenName.PORTAL_REORDER_SETTINGS]: metrics.pageLoad({
        key: 'service-management.help-center.portal-reorder-settings',
        ...getSSRConfig(),
    }),
    [ScreenName.VIEW_TOPIC]: metrics.pageLoad({
        key: 'service-management.help-center.view-topic',
        ...getSSRConfig(),
    }),
    [ScreenName.EDIT_TOPIC]: metrics.pageLoad({
        key: 'service-management.help-center.edit-topic',
        ...getSSRConfig(),
    }),
    [ScreenName.EDIT_HOMEPAGE]: metrics.pageLoad({
        key: 'service-management.help-center.edit-homepage',
        ...getSSRConfig(),
    }),
    [ScreenName.ADD_HELP_DESK]: metrics.pageLoad({
        key: 'service-management.help-center.add-help-desk-page',
        ...getSSRConfig(),
    }),
    [ScreenName.EDIT_HELP_DESK]: metrics.pageLoad({
        key: 'service-management.help-center.edit-help-desk-page',
        ...getSSRConfig(),
    }),
    [ScreenName.ADD_HELP_CONTENT_KNOWLEDGE_RESOURCES]: metrics.pageLoad({
        key: 'service-management.help-center.add-help-content-knowledge-resources',
        ...getSSRConfig(),
    }),
    [ScreenName.ADD_HELP_CONTENT_EXTERNAL_LINKS]: metrics.pageLoad({
        key: 'service-management.help-center.add-help-content-external-links',
        ...getSSRConfig(),
    }),
    [ScreenName.UNIFIED_HELP_HOME_PAGE]: metrics.pageLoad({
        key: 'service-management.unified-help-center.home-page',
        ...getSSRConfig(),
    }),
    [ScreenName.UNIFIED_HELP_CONVERSATIONS_LOG]: metrics.pageLoad({
        key: 'service-management.unified-help-center.conversations-log',
        ...getSSRConfig(),
    }),
    [ScreenName.HELP_CENTERS_MANAGEMENT]: metrics.pageLoad({
        key: 'service-management.help-center.help-centers-management',
        ...getSSRConfig(),
    }),
};

const bm3BatchOneMetrics = {
    [ScreenName.REQUEST_LIST]: metrics.pageLoad({
        key: 'service-management.help-center.portal-requests',
        ...getSSRConfig(),
    }),
    [ScreenName.LOGIN]: metrics.pageLoad({
        key: 'service-management.help-center.portal-login',
        ...getSSRConfig(),
    }),
    [ScreenName.KNOWLEDGE_ARTICLE]: metrics.pageLoad({
        key: 'service-management.help-center.knowledge-article',
        ...getSSRConfig(),
    }),
    [ScreenName.LOGIN_SINGLE_SIGN_ON]: metrics.pageLoad({
        key: 'service-management.help-center.portal-login-single-sign-on',
        ...getSSRConfig(),
    }),
    [ScreenName.SIGNUP]: metrics.pageLoad({
        key: 'service-management.help-center.portal-signup',
        ...getSSRConfig(),
    }),
    [ScreenName.APPROVAL_LIST]: metrics.pageLoad({
        key: 'service-management.help-center.portal-approvals',
        ...getSSRConfig(),
    }),
    [ScreenName.KNOWLEDGE_CATEGORY]: metrics.pageLoad({
        key: 'service-management.help-center.category-page',
        ...getSSRConfig(),
    }),
    [ScreenName.USER_PROFILE]: metrics.pageLoad({
        key: 'service-management.help-center.portal-profile',
        ...getSSRConfig(),
    }),
    [ScreenName.CHECK_EMAIL]: metrics.pageLoad({
        key: 'service-management.help-center.portal-validate-email',
        ...getSSRConfig(),
    }),
    [ScreenName.COMPLETE_SIGNUP]: metrics.pageLoad({
        key: 'service-management.help-center.portal-complete-signup',
        ...getSSRConfig(),
    }),
    [ScreenName.RESET_PASSWORD]: metrics.pageLoad({
        key: 'service-management.help-center.portal-reset-password',
        ...getSSRConfig(),
    }),
    [ScreenName.INVITED_SIGNUP]: metrics.pageLoad({
        key: 'service-management.help-center.portal-accept-invite',
        ...getSSRConfig(),
    }),
    [ScreenName.COMPLETE_SIGNUP_TOKEN_INVALID]: metrics.pageLoad({
        key: 'service-management.help-center.portal-complete-signup-token-invalid',
        ...getSSRConfig(),
    }),
    [ScreenName.COMPLETE_SIGNUP_TOKEN_EXPIRED]: metrics.pageLoad({
        key: 'service-management.help-center.portal-complete-signup-token-expired',
        ...getSSRConfig(),
    }),
    [ScreenName.LOGIN_FORGOT_PASSWORD_CONFIRMATION]: metrics.pageLoad({
        key: 'service-management.help-center.portal-login-forgot-password-confirmation',
        ...getSSRConfig(),
    }),
    [ScreenName.CONSENT_FORM_PAGE]: metrics.pageLoad({
        key: 'service-management.help-center.user-consent',
        ...getSSRConfig(),
    }),
};

const bm3BatchTwoMetrics = {
    [ScreenName.REQUEST_DETAILS]: metrics.pageLoad({
        key: 'service-management.help-center.portal-request-details',
        ...getSSRConfig(),
    }),
    [ScreenName.REQUEST_CREATE]: metrics.pageLoad({
        key: 'service-management.help-center.portal-request-create',
        ...getSSRConfig(),
    }),
    [ScreenName.PORTAL_HOME]: metrics.pageLoad({
        key: 'service-management.help-center.single-portal',

        ...getSSRConfig(),
    }),
    [ScreenName.REQUEST_GROUP]: metrics.pageLoad({
        key: 'service-management.help-center.request-group',
        ...getSSRConfig(),
    }),
    [ScreenName.REQUEST_GROUP_LIST]: metrics.pageLoad({
        key: 'service-management.help-center.request-group-list',
        ...getSSRConfig(),
    }),
    [ScreenName.FEEDBACK]: metrics.pageLoad({
        key: 'service-management.help-center.portal-request-feedback',
        ...getSSRConfig(),
    }),
    [ScreenName.REQUEST_CREATE_ANONYMOUS_SUCCESS]: metrics.pageLoad({
        key: 'service-management.help-center.request-create-anonymous-success',
        ...getSSRConfig(),
    }),
    [ScreenName.UNSUBSCRIBE]: metrics.pageLoad({
        key: 'service-management.help-center.portal-unsubscribe-confirmation',
        ...getSSRConfig(),
    }),
    [ScreenName.FEEDBACK_SUBMITTED]: metrics.pageLoad({
        key: 'service-management.help-center.portal-request-feedback-comment-submitted',
        ...getSSRConfig(),
    }),
};

Object.assign(perfConfig, bm3BatchOneMetrics, bm3BatchTwoMetrics);

/**
 * This returns true if BM3 has been instrumented for the specific screen
 * @param screenName  Name of the Page for which we want to measure the page load
 */
export const isPageLoadInstrumented = (screenName: ScreenName | undefined): boolean => {
    if (!screenName) return false;
    return perfConfig.hasOwnProperty(screenName);
};

export default perfConfig;
