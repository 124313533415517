import { defineMessages } from 'react-intl';

export default defineMessages({
    requests: {
        defaultMessage: 'Requests',
        description: 'Requests header in avatar drop-down menu',
        id: 'sd.customerview.profile.dropdown.menu.header.requests',
    },
    account: {
        defaultMessage: 'Account',
        description: 'Account header in avatar drop-down menu',
        id: 'sd.customerview.profile.dropdown.menu.header.account',
    },
    logout: {
        defaultMessage: 'Log out',
        description: 'Log out link in avatar drop-down menu',
        id: 'sd.customerview.profile.dropdown.menu.logout',
    },
    profile: {
        defaultMessage: 'Profile',
        description: 'Profile link in avatar drop-down menu',
        id: 'sd.customerview.profile.dropdown.menu.link',
    },
    createdByMe: {
        defaultMessage: 'Created by me',
        description: 'Created by me link in avatar drop-down menu',
        id: 'sd.customerview.profile.dropdown.menu.created.by.me',
    },
    all: {
        defaultMessage: 'All',
        description: 'All requests link in avatar drop-down menu',
        id: 'sd.customerview.profile.dropdown.avatar.menu.all',
    },
    approvals: {
        defaultMessage: 'Approvals',
        description: 'Approvals link in avatar drop-down menu',
        id: 'sd.customerview.profile.dropdown.avatar.menu.approvals',
    },
    reporting: {
        defaultMessage: 'Reporting',
        description: 'Navigates to Reporting page',
        id: 'sd.customerview.profile.dropdown.menu.reporting-non-final',
    },
});
