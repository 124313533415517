import type { StoreActionApi } from 'react-sweet-state';
import type { ConversationProvider } from '../../../common/types';
import { sendTrackEvent } from '../../../common/utils';
import type { State } from '../types';

export const startConversation =
    () =>
    async (
        { getState, setState }: StoreActionApi<State>,
        { conversationProvider }: { conversationProvider: ConversationProvider }
    ) => {
        const { projectId, portalId, contextId } = getState();
        const abortController = new AbortController();
        setState({ abortController });

        const handleError = (error: string) => {
            // https://data-portal.internal.atlassian.com/analytics/registry/65973
            sendTrackEvent({
                action: 'failed',
                actionSubject: 'startConversation',
                attributes: {
                    contextId,
                    projectId: projectId?.toString(),
                    portalId: portalId?.toString(),
                },
            });

            setState({ error });
            throw new Error(error);
        };

        try {
            const response = await conversationProvider.startConversation(abortController.signal);

            if (abortController.signal.aborted) {
                return undefined;
            }

            if (!response?.id) {
                throw Error('No conversation id.');
            }

            // https://data-portal.internal.atlassian.com/analytics/registry/65970
            sendTrackEvent({
                action: 'started',
                actionSubject: 'conversation',
                actionSubjectId: response.id,
                attributes: {
                    contextId,
                    conversationId: response.id,
                    projectId: projectId?.toString(),
                    portalId: portalId?.toString(),
                },
            });

            setState({ conversationId: response.id });
            return response.id;
        } catch (error: unknown) {
            if (error instanceof Error) {
                return handleError(error.message);
            }
            return handleError('Failed to start conversation.');
        }
    };
