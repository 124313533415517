import type { ComponentPropsWithoutRef } from 'react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isCSMHelpCenter } from 'util/advanced-help-center';
import { fetchJson } from 'util/fetch';
import { styled } from '@compiled/react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled2 from 'styled-components';
import enterIconUrl from 'assets/images/enter-icon.svg';
import { isJsmSearchHintEnabled, isVirtualAgentInSearchDropdownEnabled } from 'feature-flags';
import { isNumber } from 'lodash';
import { useIntl } from 'react-intl-next';
import { di } from 'react-magnetic-di';
import { usePathParam, useRouter } from 'react-resource-router';
import { useSendMessage as useSendVirtualAgentMessage } from 'unified-help-center-alpha/src/controllers/routing';
import { useConversationActions as useUnifiedHelpConversationActions } from 'unified-help-center-alpha/src/store/converse';
import { getProductIdentifierAttributes } from 'unified-help-center-alpha/src/utils/analytics/get-product-identifier-attributes';
import { ScreenName } from 'view/common/constants';
import { SEARCH_INPUT_FONT_SIZE } from 'view/common/styled';
import { isMacOs } from 'view/layout-primitives/utils';
import * as grid from 'view/styles/grid';
import * as mixins from 'view/styles/mixins';
import { useAnalyticsEvents, withAnalyticsContext } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import SearchIcon from '@atlaskit/icon/glyph/search';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { WidthObserver } from '@atlaskit/width-detector';
import { UI_EVENT_TYPE } from '@atlassian/analytics-web-react';
import { ExperienceType } from '@atlassian/conversation-assistant';
import { getEnv } from '@atlassian/help-center-common-util/env';
import { getHelpCenterAri, getCloudId } from '@atlassian/help-center-common-util/meta';
import { withHelpCenterConversationStateContainer } from '@atlassian/help-center-virtual-agent/src/controllers/conversation-store/container-wrapper';
import { useVirtualAgentOnHelpCenterAvailability } from '@atlassian/help-center-virtual-agent/src/services/help-center-virtual-agent-availability';
import { isValidSearchTerm } from '../common';
import { useInlineResultsContext } from '../inline-results-context';
import { SearchPromptPopup } from '../search-prompt-popup';
import type { PopularPortalWithVA } from '../search-prompt-popup/search-prompt-popup';
import messages from './messages';

const usePopularPortalsWithVA = () => {
    const { createAnalyticsEvent } = useAnalyticsEvents();
    const [popularPortalsWithVA, setPopularPortalsWithVA] = useState<PopularPortalWithVA[]>([]);
    const [isFetching, setIsFetching] = useState(false);
    const cloudId = useMemo(() => getCloudId(), []);

    const fetchData = useCallback(async () => {
        setIsFetching(true);
        try {
            const { mostPopularVAsPortalData } = await fetchJson<{
                mostPopularVAsPortalData: PopularPortalWithVA[];
            }>(`/gateway/api/jsm/virtual-agent/cloudId/${cloudId}/public/api/v1/virtualagent/mostPopularVAs`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    helpCenterId: getHelpCenterAri(),
                }),
            });
            setPopularPortalsWithVA(mostPopularVAsPortalData);
        } catch (error) {
            createAnalyticsEvent({
                analyticsType: UI_EVENT_TYPE,
                action: 'failed',
                actionSubjectId: 'fetchPopularPortalsWithVA',
            }).fire();
        } finally {
            setIsFetching(false);
        }
    }, [cloudId, createAnalyticsEvent, setIsFetching, setPopularPortalsWithVA]);

    return {
        isFetching,
        popularPortalsWithVA,
        fetchData,
    };
};

export interface SearchTextboxV2Props {
    term: string;
    isAiAnswersEnabled: boolean;
    disableSearch?: boolean;
}

const SearchTextboxV2 = ({ term, isAiAnswersEnabled, disableSearch }: SearchTextboxV2Props) => {
    di(
        SearchPromptPopup,
        useUnifiedHelpConversationActions,
        useRouter,
        useAnalyticsEvents,
        useSendVirtualAgentMessage,
        useVirtualAgentOnHelpCenterAvailability,
        usePathParam
    );
    const searchBarRef = useRef<HTMLInputElement>(null);
    const [isChoiceOpen, setIsChoiceOpen] = useState(false);
    const [inputValue, setInputValue] = useState(term);
    const [searchBoxWidth, setSearchBoxWidth] = useState<number>();
    const [{ location }, { push }] = useRouter();
    const { formatMessage } = useIntl();
    const { startNewConversation, setProviderAuthStatus } = useUnifiedHelpConversationActions();
    const { sendMessage } = useSendVirtualAgentMessage();
    const { createAnalyticsEvent } = useAnalyticsEvents();
    const { resultsCount } = useInlineResultsContext();
    const cloudId = getCloudId();
    const { isVAOnHCAvailable } = useVirtualAgentOnHelpCenterAvailability({ cloudId });
    const isVirtualAgentEnabled = isVAOnHCAvailable || isAiAnswersEnabled;
    const isJsmSearchHintFFEnabled = isJsmSearchHintEnabled();
    const isVirtualAgentInSearchDropdownFFEnabled = isAiAnswersEnabled && isVirtualAgentInSearchDropdownEnabled();
    const {
        popularPortalsWithVA,
        fetchData: fetchPopularPortalsWithVA,
        isFetching: isFetchingPopularPortalsWithVA,
    } = usePopularPortalsWithVA();

    useEffect(() => {
        if (isVirtualAgentInSearchDropdownFFEnabled) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            fetchPopularPortalsWithVA();
        }
    }, [isVirtualAgentInSearchDropdownFFEnabled, fetchPopularPortalsWithVA]);

    const openChoicesIfPresent = useCallback(() => {
        setIsChoiceOpen(isVirtualAgentInSearchDropdownFFEnabled || isValidSearchTerm(inputValue));
    }, [inputValue, setIsChoiceOpen, isVirtualAgentInSearchDropdownFFEnabled]);

    const handleInputChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const newQuery = event.target.value;
            setInputValue(newQuery);
            setIsChoiceOpen(isValidSearchTerm(newQuery));
        },
        [setInputValue, setIsChoiceOpen]
    );

    const handleCrossClick = useCallback(() => {
        setInputValue('');
        setIsChoiceOpen(false);
    }, [setInputValue, setIsChoiceOpen]);

    const onSeeSearchResults = useCallback(() => {
        if (!isValidSearchTerm(inputValue)) {
            return;
        }
        createAnalyticsEvent({
            analyticsType: UI_EVENT_TYPE,
            action: 'selected',
            actionSubjectId: 'allResults',
            ...getProductIdentifierAttributes(ExperienceType.UNIFIED_HELP),
        }).fire();
        push(`${location.pathname}?q=${inputValue.trim()}`) as unknown as void;
    }, [inputValue, createAnalyticsEvent, push, location.pathname]);

    const onAskAtlassianIntelligence = useCallback(() => {
        if (!isValidSearchTerm(inputValue)) {
            return;
        }

        createAnalyticsEvent({
            analyticsType: UI_EVENT_TYPE,
            action: 'selected',
            actionSubjectId: 'askAtlassianIntelligence',
            ...getProductIdentifierAttributes(ExperienceType.UNIFIED_HELP),
        }).fire();

        if (isVAOnHCAvailable) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            sendMessage(inputValue.trim());
        } else {
            startNewConversation(inputValue.trim());
        }

        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        push('/conversation');
    }, [inputValue, isVAOnHCAvailable, createAnalyticsEvent, sendMessage, startNewConversation, push]);

    const visibleResultsCount =
        (resultsCount?.article || 0) + (resultsCount?.portal || 0) + (resultsCount?.requestForm || 0);

    const isFetchingResults = !(
        isNumber(resultsCount?.article) &&
        isNumber(resultsCount?.portal) &&
        isNumber(resultsCount?.requestForm)
    );

    const handleKeyDown = useCallback(
        (event: React.KeyboardEvent<HTMLDivElement>) => {
            if (event.key === 'Enter') {
                if (isVirtualAgentEnabled) {
                    if ((event.metaKey || (!isMacOs() && event.ctrlKey)) && visibleResultsCount > 0) {
                        onSeeSearchResults();
                    } else {
                        onAskAtlassianIntelligence();
                    }
                } else if (isFetchingResults || visibleResultsCount !== 0) {
                    // If the search results are still fetched, resultsCount will be null
                    onSeeSearchResults();
                }
            }
        },
        [visibleResultsCount, isFetchingResults, isVirtualAgentEnabled, onAskAtlassianIntelligence, onSeeSearchResults]
    );

    useEffect(() => {
        setIsChoiceOpen(isValidSearchTerm(inputValue));
    }, [inputValue, setIsChoiceOpen]);

    useEffect(() => {
        if (isVirtualAgentEnabled) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            setProviderAuthStatus();
        }
    }, [setProviderAuthStatus, isVirtualAgentEnabled]);

    const [requestIdParam] = usePathParam('requestId');

    useEffect(() => {
        if (isCSMHelpCenter(getEnv().helpCenterType) && requestIdParam) return;
        searchBarRef.current?.focus();
    }, [requestIdParam]);

    return (
        <SearchPromptPopup
            isOpen={isChoiceOpen}
            isFetchingPopularPortalsWithVA={isFetchingPopularPortalsWithVA}
            popularPortalsWithVA={popularPortalsWithVA}
            onClose={() => setIsChoiceOpen(false)}
            searchBoxWidth={searchBoxWidth}
            searchQuery={inputValue}
            onSelectSearchResults={onSeeSearchResults}
            isAiAnswersEnabled={isVirtualAgentEnabled}
            trigger={(triggerProps) => (
                <Box {...triggerProps} xcss={searchBoxWrapperStyles}>
                    <WidthObserver setWidth={setSearchBoxWidth} />
                    <Box
                        xcss={searchBoxStyles}
                        style={{
                            // We need to manually use different token fallbacks here because the contrast isn't good enough with the default ones, while Portal is not yet using tokens. xcss doesn't allow specifying a token(...) as a value hence needing to use style prop
                            // These should be removed once Portal has finished migrating to using tokens
                            // eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Disabled to rollout go/ui-styling-standard tooling, please resolve
                            borderColor: token('color.border.input', '#dee0e4'),
                            // eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Disabled to rollout go/ui-styling-standard tooling, please resolve
                            backgroundColor: token('color.background.input', 'white'),
                        }}
                    >
                        <Inline>
                            <SearchIconWrapper>
                                <SearchIcon primaryColor={token('color.icon.subtle', '#6B778C')} label="" />
                            </SearchIconWrapper>
                            <Input
                                data-testid="search-textbox-v2"
                                type="text"
                                autoComplete="off"
                                innerRef={searchBarRef}
                                // eslint-disable-next-line jsx-a11y/no-autofocus -- need to auto focus when choice is open because when the popup loads in async, the text field remounts and loses focus
                                autoFocus={isChoiceOpen}
                                spellCheck={false}
                                isAiAnswersEnabled={isVirtualAgentEnabled}
                                aria-label={
                                    isVirtualAgentEnabled
                                        ? formatMessage(messages.inputAriaLabel)
                                        : formatMessage(messages.inputAriaLabelNoAI)
                                }
                                placeholder={
                                    isVirtualAgentEnabled
                                        ? formatMessage(messages.inputPlaceholder)
                                        : formatMessage(messages.inputPlaceholderNoAI)
                                }
                                onClick={() => openChoicesIfPresent()}
                                onFocus={(event) => {
                                    // When coming from another focus element (meaning tabbing forwards or backwards)
                                    // We can't always open choices because that would trigger if you're in the popup menu and press 'Escape'
                                    // This focuses the text input so by re-opening the menu we'd be causing another A11Y issue
                                    if (event.relatedTarget != null) {
                                        openChoicesIfPresent();
                                    }
                                }}
                                onChange={handleInputChange}
                                onKeyDown={handleKeyDown}
                                value={inputValue}
                                disabled={disableSearch}
                            />

                            {isValidSearchTerm(inputValue) && (
                                <>
                                    {isVirtualAgentEnabled ? (
                                        <Box xcss={askAiHintStyles}>
                                            <Inline space="space.100" alignBlock="center">
                                                {formatMessage(messages.askTheVirtualAgent)}
                                                <img src={enterIconUrl} alt={formatMessage(messages.enter)} />
                                            </Inline>
                                        </Box>
                                    ) : (
                                        <>
                                            {isJsmSearchHintFFEnabled ? (
                                                <>
                                                    {(isFetchingResults || visibleResultsCount !== 0) && (
                                                        <Box xcss={askAiHintStyles}>
                                                            <Inline space="space.100" alignBlock="center">
                                                                {formatMessage(messages.search)}
                                                                <img
                                                                    src={enterIconUrl}
                                                                    alt={formatMessage(messages.enter)}
                                                                />
                                                            </Inline>
                                                        </Box>
                                                    )}
                                                </>
                                            ) : (
                                                <CrossIconButton>
                                                    <Button
                                                        appearance="subtle"
                                                        aria-label={formatMessage(messages.clearLabel)}
                                                        iconBefore={<CrossIcon label="" size="small" />}
                                                        onClick={handleCrossClick}
                                                    />
                                                </CrossIconButton>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </Inline>
                    </Box>
                </Box>
            )}
        />
    );
};

SearchTextboxV2.displayName = 'SearchTextboxV2';
const WrappedComponent = withHelpCenterConversationStateContainer(SearchTextboxV2);
const SearchTextBoxV2withConversationContainers = (props: ComponentPropsWithoutRef<typeof SearchTextboxV2>) => {
    const contextId = getHelpCenterAri();
    return <WrappedComponent {...props} contextId={contextId} />;
};

export default withAnalyticsContext({
    component: ScreenName.HELP_CENTER_SEARCH,
})(SearchTextBoxV2withConversationContainers);

const searchBoxWrapperStyles = xcss({
    position: 'relative',
});

const searchBoxStyles = xcss({
    backgroundColor: 'color.background.input',
    borderStyle: 'solid',
    borderWidth: 'border.width',
    borderColor: 'color.border.input',
    borderRadius: 'border.radius.100',
    ':focus-within': {
        outlineStyle: 'solid',
        outlineWidth: 'border.width.outline',
        outlineColor: 'color.border.focused',
    },
});

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const Input = styled2.input`
    ${SEARCH_INPUT_FONT_SIZE};
    ${mixins.placeholder(token('color.text.subtlest', '#6B778C'))};
    background-color: ${token('color.background.input', 'white')};
    border-radius: ${token('border.radius.100', '3px')};
    padding-top: ${grid.multiple(2.125).px};
    padding-bottom: ${grid.multiple(2.125).px};
    /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
    padding-right: ${(props: { isAiAnswersEnabled: boolean }) =>
        props.isAiAnswersEnabled ? '148px' : token('space.600', '48px')};
    padding-left: ${token('space.050', '4px')};
    flex-grow: 1;
    border: 0;
    outline: none;
    color: ${token('color.text', colors.N800)};
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const CrossIconButton = styled.div({
    position: 'absolute',
    padding: `0 ${token('space.100', '8px')}`,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const SearchIconWrapper = styled.div({
    padding: `0 ${token('space.200', '16px')}`,
    height: '100%',
    left: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center',
    paddingRight: token('space.050', '4px'),
});

const askAiHintStyles = xcss({
    display: 'flex',
    alignItems: 'center',
    gap: 'space.050',
    position: 'absolute',
    padding: 'space.150',
    right: 'space.0',
    color: 'color.text.subtle',
    top: 'space.0',
    bottom: 'space.0',
    fontSize: 11,
});
