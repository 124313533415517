import * as React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import type { OnOpenChangeArgs } from '@atlaskit/dropdown-menu';
import DropdownMenu from '@atlaskit/dropdown-menu';

import { RequestsMenuButton } from '../requests-menu-button';
import { RequestsMenuGroup } from '../requests-menu-group';

interface Props {
    myRequestCount: number;
    pendingApprovalCount: number;
    approvalCount: number;
    onOpenAndClose?: (e: { isOpen: boolean }) => void;
    organisations: Organisations[];
}

export interface Organisations {
    name: string;
    id: number;
}

interface State {
    isOpen: boolean;
}

export default class RequestsMenu extends React.Component<Props, State> {
    state: State = {
        isOpen: false,
    };

    onOpenChange = ({ isOpen }: OnOpenChangeArgs) => {
        if (!!this.props.onOpenAndClose) {
            this.props.onOpenAndClose({ isOpen });
        }
        this.setState({ isOpen });
    };

    onItemClick = () => {
        this.setState({ isOpen: false });
    };

    render() {
        const { myRequestCount, pendingApprovalCount, approvalCount, organisations } = this.props;

        const totalRequests = pendingApprovalCount + myRequestCount;

        return (
            <RequestsMenuContainer>
                <DropdownMenu<HTMLDivElement>
                    shouldFlip={false}
                    appearance="tall"
                    trigger={({ triggerRef, ...props }) => (
                        <div ref={triggerRef} {...props}>
                            <RequestsMenuButton totalRequests={totalRequests} isMenuOpen={this.state.isOpen} />
                        </div>
                    )}
                    placement="bottom-end"
                    onOpenChange={this.onOpenChange}
                    isOpen={this.state.isOpen}
                >
                    <RequestsMenuGroup
                        approvalCount={approvalCount}
                        myRequestCount={myRequestCount}
                        onItemClick={this.onItemClick}
                        organisations={organisations}
                        pendingApprovalCount={pendingApprovalCount}
                    />
                </DropdownMenu>
            </RequestsMenuContainer>
        );
    }
}

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const RequestsMenuContainer = styled.div`
    padding: 0;
`;
