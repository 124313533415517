import { createRelayResource } from '@atlassian/help-center-common-util/resource-router';
import type { contentGapTableQuery } from './__generated__/contentGapTableQuery.graphql';
import ContentGapTableQuery from './__generated__/contentGapTableQuery.graphql';

export const HelpReportingContentGapResource = createRelayResource<contentGapTableQuery>({
    type: 'CONTENT_GAP_TABLE',
    getQuery: (_, { cloudId, helpCenterAri }: { cloudId: string; helpCenterAri: string }) => {
        return {
            parameters: ContentGapTableQuery,
            variables: {
                cloudId,
                helpCenterAri,
            },
            options: {
                fetchPolicy: 'store-or-network',
            },
        };
    },
});
