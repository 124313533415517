import React, { useState, useCallback, useEffect } from 'react';
import { isCSMHelpCenter } from 'util/advanced-help-center';
import { useIntl } from 'react-intl-next';
import { di } from 'react-magnetic-di';
import { graphql, useFragment } from 'react-relay';
import { usePathParam, useRouterActions } from 'react-resource-router';
import { ThemedButton } from 'view/common/themed-button';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { withAnalyticsEvents } from '@atlaskit/analytics-next';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import { Inline } from '@atlaskit/primitives';
import { getEnv } from '@atlassian/help-center-common-util/env';
import { addParamsToUrl } from '@atlassian/help-center-common-util/history-qs';
import { ExperienceSuccess } from '@atlassian/ufo';
import { ArticleItem } from '../article-item';
import {
    ALL_RESULTS_COUNT,
    INITIAL_ARTICLES_COUNT,
    SHOW_MORE_RESULTS_COUNT,
    ResultTitle,
    useQueryParams,
    isSinglePortalSearch,
    getPortalIdAsNumber,
    sendSearchOperationalAnalyticEvents,
    sendSearchUIAnalyticEvents,
    ResultList,
} from '../common';
import { csmSearchExperience } from '../experiences';
import type { articlesFragment$key } from './__generated__/articlesFragment.graphql';
import messages from './messages';

export interface Props {
    term: string;
    result: articlesFragment$key;
    showAllResults?: boolean;
    updateResultsCount: (count: number) => void;
    createAnalyticsEvent?: CreateUIAnalyticsEvent;
}

export const Articles = ({ result, term, showAllResults, updateResultsCount, createAnalyticsEvent }: Props) => {
    di(getEnv);

    const isCSM = isCSMHelpCenter(getEnv().helpCenterType);
    const { formatMessage } = useIntl();
    const data = useFragment<articlesFragment$key>(
        graphql`
            fragment articlesFragment on HelpObjectStoreQueryApi {
                articles: searchHelpObjects(
                    searchInput: {
                        cloudId: $cloudId
                        queryTerm: $queryTerm
                        resultLimit: $resultLimit
                        entityType: ARTICLE
                        portalIds: $portalIds
                        categoryIds: $categoryIds
                    }
                ) @required(action: THROW) {
                    ... on HelpObjectStoreSearchResult {
                        __typename @required(action: THROW)
                        isExternal @required(action: THROW)
                        searchBackend
                        ...articleItemFragment
                    }

                    ... on HelpObjectStoreQueryError {
                        message
                    }
                }
            }
        `,
        result
    );
    const { push } = useRouterActions();
    const [portalId] = usePathParam('portalId');
    const portalIdAsNumber = getPortalIdAsNumber(portalId);
    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const queryStringParams = useQueryParams();
    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { resultLimit = showAllResults ? ALL_RESULTS_COUNT : INITIAL_ARTICLES_COUNT } = queryStringParams;
    // We fetch one more than the required count, i.e. (4+1 or 8+1), to identify if there are any more results
    // but will show only 4, 8 or max 30 results
    const [numberOfResultsShown, setNumberOfResultsShown] = useState(
        showAllResults ? ALL_RESULTS_COUNT : resultLimit - 1
    );

    const showMoreArticlesButton = !showAllResults && data.articles.length > numberOfResultsShown;
    const slicedArticles = data.articles.slice(0, numberOfResultsShown);
    const showSection = slicedArticles.length > 0;

    useEffect(() => {
        setNumberOfResultsShown(resultLimit - 1);
    }, [resultLimit]);

    useEffect(() => {
        let externalResourcesCount = 0;
        let firstPartySearchPlatformResourcesCount = 0;
        let hasError = false;
        let errorMessage: string | undefined;

        slicedArticles.forEach((resource) => {
            if (resource) {
                const { __typename, isExternal, searchBackend, message } = resource;

                if (__typename === 'HelpObjectStoreSearchResult') {
                    if (isExternal) {
                        externalResourcesCount += 1;
                    }
                    if (searchBackend === 'SEARCH_PLATFORM') {
                        firstPartySearchPlatformResourcesCount += 1;
                    }
                    if (message && !errorMessage) {
                        hasError = true;
                        errorMessage = message;
                    }
                }
            }
        });

        if (showSection && hasError) {
            sendSearchOperationalAnalyticEvents(
                {
                    hasError,
                    errorMessage,
                    action: portalId ? 'KBArticleSinglePortalSearch failed' : 'KBArticleHomePageSearch failed',
                },
                createAnalyticsEvent,
                portalId
            );
        }

        if (showSection && !hasError) {
            sendSearchUIAnalyticEvents(
                {
                    externalResourcesCount,
                    firstPartySearchPlatformResourcesCount,
                    action: 'rendered',
                    resourceType: 'article',
                    resourceCount: slicedArticles.length,
                },
                createAnalyticsEvent,
                portalId
            );

            sendSearchOperationalAnalyticEvents(
                {
                    action: portalId ? 'KBArticleSinglePortalSearch success' : 'KBArticleHomePageSearch success',
                },
                createAnalyticsEvent,
                portalId
            );
        }
        // adding slicedArticles to deps array causes multiple 'rendered' events to be fired
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [term, showSection, slicedArticles.length, createAnalyticsEvent, portalId, numberOfResultsShown]);

    useEffect(() => {
        updateResultsCount(slicedArticles.length);
    }, [term, slicedArticles.length, updateResultsCount]);

    const onShowMoreClick = useCallback(() => {
        if (numberOfResultsShown === 4) {
            setNumberOfResultsShown(numberOfResultsShown + (SHOW_MORE_RESULTS_COUNT - (resultLimit - 1)));
            push(
                addParamsToUrl('', { ...queryStringParams, resultLimit: SHOW_MORE_RESULTS_COUNT + 1 })
            ) as unknown as void;
        } else if (!isSinglePortalSearch(portalIdAsNumber)) {
            push(addParamsToUrl('/articles/search', { q: term })) as unknown as void;
        } else {
            push(addParamsToUrl(`/portal/${portalIdAsNumber}/articles/search`, { q: term })) as unknown as void;
        }
    }, [numberOfResultsShown, portalIdAsNumber, resultLimit, push, queryStringParams, term]);

    return (
        <>
            {showSection && (
                <>
                    <ResultTitle>{formatMessage(messages.articles)}</ResultTitle>
                    <ResultList>
                        {slicedArticles.map((resource, index) => {
                            if (resource?.__typename === 'HelpObjectStoreSearchResult') {
                                const itemOrder = index + 1;
                                return (
                                    <li key={itemOrder}>
                                        <ArticleItem
                                            itemOrder={itemOrder}
                                            term={term}
                                            showAllResults={showAllResults}
                                            result={resource}
                                        />
                                    </li>
                                );
                            }
                            return null;
                        })}
                    </ResultList>
                    {showMoreArticlesButton && (
                        <Inline alignBlock="center" alignInline="center">
                            <ThemedButton
                                data-test-id="show-more-button"
                                appearance="link"
                                actionSubjectId="showMoreButton"
                                iconAfter={<ChevronDownIcon label={formatMessage(messages.showMoreIcon)} />}
                                onClick={onShowMoreClick}
                            >
                                {formatMessage(messages.showMore)}
                            </ThemedButton>
                        </Inline>
                    )}
                </>
            )}
            {isCSM && <ExperienceSuccess experience={csmSearchExperience} />}
        </>
    );
};

export default withAnalyticsEvents()(Articles);
