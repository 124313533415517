import { createRelayResource } from '@atlassian/help-center-common-util/resource-router';
import type { helpReportingPageQuery } from './__generated__/helpReportingPageQuery.graphql';
import HelpReportingPageQuery from './__generated__/helpReportingPageQuery.graphql';

export const HelpReportingMetricTileResource = createRelayResource<helpReportingPageQuery>({
    type: 'HELP_REPORTING_PAGE',
    getQuery: (_, { cloudId, helpCenterAri }: { cloudId: string; helpCenterAri: string }) => {
        return {
            parameters: HelpReportingPageQuery,
            variables: {
                cloudId,
                helpCenterAri,
            },
            options: {
                fetchPolicy: 'store-or-network',
            },
        };
    },
});
