import { defineMessages } from 'react-intl-next';

export default defineMessages({
    clearLabel: {
        defaultMessage: 'Reset search',
        description: 'Label for icon that clears the search term',
        id: 'search.input.clear',
    },
    inputAriaLabel: {
        defaultMessage: 'Question or search terms',
        description: 'ARIA label for textbox that allows asking a question or searching for results',
        id: 'search.input.label.ai',
    },
    inputPlaceholder: {
        defaultMessage: 'Ask a question or search for information',
        description: 'Placeholder message for the new search bar describing what they can look for',
        id: 'search.input.placeholder.ai',
    },
    inputAriaLabelNoAI: {
        defaultMessage: 'Search terms',
        description: 'ARIA label for textbox that allows asking a question or searching for results',
        id: 'search.input.label.no-ai',
    },
    inputPlaceholderNoAI: {
        defaultMessage: 'Search for information',
        description: 'Placeholder message for the new search bar describing what they can look for',
        id: 'search.input.placeholder.no-ai',
    },
    askTheVirtualAgent: {
        defaultMessage: 'Ask the virtual agent',
        description: 'Hint to the user to initiate the Virtual Agent conversation',
        id: 'search.input.placeholder.ai.ask-the-virtual-agent',
    },
    enter: {
        defaultMessage: 'Enter',
        description: 'Enter key from the keyboard',
        id: 'search.see.search.results.key.enter.non-final',
    },
    search: {
        defaultMessage: 'Search',
        description: 'Hint to the user to press enter to go to search results page',
        id: 'search.input.hint',
    },
});
