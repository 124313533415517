import * as React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled, { css } from 'styled-components';
import domPurify from 'dompurify';
import { isPortalCardMarkupFixEnabled, isSanitizationOfPortalDescriptionToRemoveTabIndexEnabled } from 'feature-flags';
import { useIntl } from 'react-intl-next';
import { di } from 'react-magnetic-di';
import { useRouterActions } from 'react-resource-router';
import { InteractiveElement } from 'view/common/interactive-element';
import { useIsPageInEditMode } from 'view/layout-next/controllers';
import * as bp from 'view/styles/breakpoints';
import * as fonts from 'view/styles/fonts';
import * as grid from 'view/styles/grid';
import * as mixins from 'view/styles/mixins';
import * as transitions from 'view/styles/transitions';
import * as zIndex from 'view/styles/z-index';
import { AnalyticsContext, useAnalyticsEvents } from '@atlaskit/analytics-next';
import { colors, elevation } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { UI_EVENT_TYPE } from '@atlassian/analytics-web-react';
import { Link } from '@atlassian/help-center-common-component/analytics/link';
import { AutoScaledImage } from '@atlassian/help-center-common-component/auto-scaled-image';
import messages from './messages';
import { cardStyle } from './styled';

interface PortalCardProps {
    id: number;
    // index of this portal in popular portal list view
    index?: number;
    portalName: string;
    description?: string;
    portalUrl: string;
    iconUrl?: string;
    onClick?: (index?: number) => void;
}

export const customPurify = domPurify();

customPurify.addHook('afterSanitizeAttributes', (node) => {
    if (node.tagName === 'A' || node.tagName === 'BUTTON' || node.tagName === 'INPUT') {
        node.setAttribute('tabIndex', '-1');
    }
});

const PortalCard: React.FunctionComponent<PortalCardProps> = ({
    onClick,
    index,
    id,
    portalName,
    description,
    portalUrl,
    iconUrl,
}) => {
    di(useIsPageInEditMode, useRouterActions, useAnalyticsEvents, isPortalCardMarkupFixEnabled);
    const [isPageInEditMode] = useIsPageInEditMode();
    const { push } = useRouterActions();
    const { createAnalyticsEvent } = useAnalyticsEvents();

    const hasIcon = !!iconUrl;
    const hasPortalUrl = !!portalUrl;
    const hasDescription = !!description;
    const hasOnlyPortalName = !description && !iconUrl;

    const memoizedOnClick = React.useCallback(() => {
        if (isPortalCardMarkupFixEnabled()) {
            const event = createAnalyticsEvent({
                analyticsType: UI_EVENT_TYPE,
                action: 'clicked',
                actionSubjectId: 'portal',
            });
            event.context.push({
                componentName: 'link',
                attributes: {
                    index,
                    hasIcon,
                    hasPortalUrl,
                    hasDescription,
                    portalId: id,
                },
            });

            event.fire();
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            push(portalUrl);
        }

        onClick && onClick(index);
    }, [createAnalyticsEvent, index, hasIcon, hasPortalUrl, hasDescription, id, push, portalUrl, onClick]);

    const { formatMessage } = useIntl();
    const content = (
        <Container hasIcon={hasIcon}>
            {!!iconUrl && (
                <IconContainer>
                    <AutoScaledImage
                        src={iconUrl}
                        alt={formatMessage(messages.portalLogoAlt, { portalName })}
                        draggable="false"
                    />
                </IconContainer>
            )}
            <Content>
                <PortalName hasOnlyPortalName={hasOnlyPortalName} title={portalName}>
                    {portalName}
                </PortalName>
                {!!description && (
                    <Description
                        title={description}
                        dangerouslySetInnerHTML={{
                            __html: isSanitizationOfPortalDescriptionToRemoveTabIndexEnabled()
                                ? customPurify.sanitize(description)
                                : domPurify.sanitize(description),
                        }}
                    />
                )}
            </Content>
        </Container>
    );

    const portalCardContent = isPortalCardMarkupFixEnabled() ? (
        <CardRoot onClick={memoizedOnClick} data-test-id={`portal:${portalName}`}>
            {content}
        </CardRoot>
    ) : (
        <Root to={portalUrl} onClick={memoizedOnClick} actionSubjectId="portal" data-test-id={`portal:${portalName}`}>
            {content}
        </Root>
    );

    return (
        <AnalyticsContext
            data={{
                attributes: {
                    index,
                    hasIcon,
                    hasPortalUrl,
                    hasDescription,
                    portalId: id,
                },
            }}
        >
            {isPageInEditMode ? <RootWhenEditingLayout>{content}</RootWhenEditingLayout> : portalCardContent}
        </AnalyticsContext>
    );
};

export default PortalCard;

interface PortalNameProps {
    hasOnlyPortalName: boolean;
}

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const PortalName = styled.div<PortalNameProps>`
    ${fonts.h400};
    /* setting max-width 100% because on IE, text flows out of the container */
    max-width: 100%;
    margin-top: 0;
    margin-bottom: ${token('space.050', '4px')};

    ${(props) => {
        const lines = props.hasOnlyPortalName ? 3 : 1;
        const lineHeight = grid.multiple(2).unitless;
        return mixins.multiLineClamp(lines, lineHeight);
    }};

    ${bp.fromXSmall.css`
        ${fonts.h500};
        margin-top: 0;
        margin-bottom: ${token('space.100', '8px')};
        max-height: ${(props) => (props.hasOnlyPortalName ? grid.multiple(7.5).px : grid.multiple(2.5).px)};
    `};

    /* Because of AK now sets the color in the font-size mixins (inside a media query) we need to */
    /* define our color with a higher specicifity. */
    /* stylelint-disable-next-line no-duplicate-selectors */
    & {
        color: ${(props) => token('color.text.brand', props.theme.color)};
    }
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const Description = styled.div`
    ${fonts.xsmall};
    ${mixins.multiLineClamp(3, grid.multiple(2.25).unitless)};
    /* setting max-width 100% because on IE, text flows out of the container */
    max-width: 100%;
    color: ${token('color.text', colors.N800)};
    line-height: ${grid.multiple(2.25).px};

    ${bp.fromXSmall.css`
        ${fonts.regular};
        line-height: ${grid.multiple(2.5).px};
        max-height: ${grid.multiple(7.5).px};
    `};
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const Content = styled.div`
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const Root = styled(Link)`
    ${cardStyle}
    ${mixins.raiseUpOnHoverOrFocus()};
    background-color: ${token('elevation.surface.raised', colors.N0)};
    transition: transform ${transitions.speedMs.fast}ms ${transitions.timingFunctions.easeInOut};

    &:hover,
    &:focus {
        z-index: ${zIndex.portalCardLayout};
        text-decoration: none;
    }
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled
const CardRoot = styled(InteractiveElement)`
    ${cardStyle}
    ${mixins.raiseUpOnHoverOrFocus()};
    background-color: ${token('elevation.surface.raised', colors.N0)};
    transition: transform ${transitions.speedMs.fast}ms ${transitions.timingFunctions.easeInOut};

    &:hover,
    &:focus {
        z-index: ${zIndex.portalCardLayout};
        text-decoration: none;
    }
    cursor: pointer;
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const RootWhenEditingLayout = styled.div`
    ${cardStyle}
    ${elevation.e100}
    background-color: ${token('elevation.surface.raised', colors.N0)};
    transition: transform ${transitions.speedMs.fast}ms ${transitions.timingFunctions.easeInOut};

    &:hover,
    &:focus {
        z-index: ${zIndex.portalCardLayout};
        text-decoration: none;
    }
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const IconContainer = styled.div`
    ${mixins.flexCenter};
    height: ${grid.multiple(5).px};
    width: ${grid.multiple(5).px};
    flex-shrink: 0;
    margin-right: ${token('space.200', '16px')};

    ${bp.fromXSmall.css`
        height: ${grid.multiple(6).px};
        width: ${grid.multiple(6).px};
    `};
`;

// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const largePadding = css`
    padding: ${token('space.200', '16px')} ${token('space.300', '24px')}; /* check with Kate for padding top and bottom */

    ${bp.fromXSmall.css`
        padding: ${token('space.300', '24px')} ${token('space.400', '32px')};
    `};
`;

// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const smallPadding = css`
    padding: ${token('space.200', '16px')} ${token('space.200', '16px')}; /* check with Kate for padding top and bottom */

    ${bp.fromXSmall.css`
        padding: ${token('space.300', '24px')} ${token('space.300', '24px')};
    `};
`;

interface ContainerProps {
    hasIcon: boolean;
}

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const Container = styled.div<ContainerProps>`
    display: flex;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-start;

    ${(props) => (props.hasIcon ? smallPadding : largePadding)};
`;
