import { addJiraFieldMessage } from './add-jira-field-message';
import { addMessage } from './add-message';
import { clearConversation } from './clear-conversation';
import { resetStateOnContextIdChanged } from './reset-state-on-context-id-changed';
import { retryLastHelpseekerMessage } from './retry-last-helpseeker-message';
import { selectOption } from './select-option';

export const actions = {
    addMessage,
    selectOption,
    clearConversation,
    addJiraFieldMessage,
    retryLastHelpseekerMessage,
    resetStateOnContextIdChanged,
};
