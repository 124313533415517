import { isDefaultHelpCenter } from 'util/advanced-help-center/advanced-help-center';
import defaultBannerImage from 'assets/images/default-banner.png';
import { isAdvancedCustomizationEnabled, isCustomThemingEnabled } from 'feature-flags';
import { mapValues } from 'lodash';
import type { HCBrandingResponse, HCBrandingPortalTranslationResponse } from 'rest/help-center-branding';
import type {
    UpdateHCBrandingModelAction,
    UpdateHelpCenterModelAction,
    HelpCenterResponse,
    UpdateHCThemeColorAction,
    UpdateHCBrandingLoaderAction,
} from 'state/actions/help-center';
import {
    UPDATE_HELP_CENTER_BRANDING_MODEL,
    UPDATE_HELP_CENTER_MODEL,
    UPDATE_HELP_CENTER_THEME_COLOR,
    UPDATE_HELP_CENTER_BRANDING_LOADER,
} from 'state/actions/help-center';
import { colors } from '@atlaskit/theme';
import { isAdvancedHelpCenter } from '@atlassian/help-center-common-util/advanced-help-center';
import { initialModel } from '@atlassian/help-center-common-util/model';
export interface HelpCenterKnowledgeBaseState {
    appLinkUrl: string;
    kbLinkDomainURLs?: string[];
}

export interface HelpCenterTranslationState {
    sharedPortalName: string;
    helpCenterTitle: string;
    announcementHeader?: string;
    announcementMessage?: string;
    localeDisplayName: string;
}

export interface HelpCenterState {
    sharedPortalName: string;
    helpCenterTitle: string;
    helpCenterTitleColor: string;
    translations: { [key: string]: HelpCenterTranslationState };
    themeColor: string;
    isLoaded: boolean;
    canCustomizeHelpCenter: boolean;
    helpCenterBannerUrl?: string;
    bannerMediaApiFileId?: string;
    logoUrl?: string;
    logoMediaApiFileId?: string;
    knowledgeBase?: HelpCenterKnowledgeBaseState;
    announcementHeader?: string;
    announcementMessage?: string;
    useDefaultBanner: boolean;
    rawBrandingResponse?: HCBrandingResponse;
    isBrandingLoaded?: boolean;
}

function reduceHCBrandingToState(helpCenterBrandingResponse: HCBrandingResponse) {
    const translationState = mapValues(
        helpCenterBrandingResponse.translations,
        (translation: HCBrandingPortalTranslationResponse) => ({ ...translation })
    );
    const isAdvancedHelpCenterThemingEnabled =
        isCustomThemingEnabled() &&
        (isAdvancedHelpCenter() || isDefaultHelpCenter()) &&
        isAdvancedCustomizationEnabled();

    return {
        logoUrl: helpCenterBrandingResponse.logoUrl,
        logoMediaApiFileId: helpCenterBrandingResponse.logoId,
        sharedPortalName: helpCenterBrandingResponse.sharedPortalName,
        translations: translationState,
        helpCenterBannerUrl: helpCenterBrandingResponse.useDefaultBanner
            ? defaultBannerImage
            : helpCenterBrandingResponse.bannerMediaApiUrl,
        bannerMediaApiFileId: helpCenterBrandingResponse.bannerMediaApiFileId,
        canCustomizeHelpCenter: !!helpCenterBrandingResponse.canEditAnnouncement,
        helpCenterTitle: helpCenterBrandingResponse.helpCenterTitle,
        helpCenterTitleColor: helpCenterBrandingResponse.helpCenterTitleColor,
        rawBrandingResponse: helpCenterBrandingResponse,
        announcementHeader: helpCenterBrandingResponse.userInitialAnnouncementHeader,
        announcementMessage: helpCenterBrandingResponse.userInitialAnnouncementMessageWiki,
        useDefaultBanner: helpCenterBrandingResponse.useDefaultBanner,
        ...(isAdvancedHelpCenterThemingEnabled ? {} : { themeColor: helpCenterBrandingResponse.portalThemeColor }),
    };
}

function reduceHelpCenterToState(helpCenterResponse: HelpCenterResponse) {
    const newState =
        helpCenterResponse.kbs && helpCenterResponse.kbs.kbEnabled
            ? {
                  knowledgeBase: {
                      appLinkUrl: helpCenterResponse.kbs.appLinkUrl,
                      kbLinkDomainURLs: helpCenterResponse.kbs.kbLinkDomainURLs,
                  } as HelpCenterKnowledgeBaseState,
              }
            : {};

    return { ...newState, isLoaded: true };
}

const getInitialBrandingState = () => {
    const initialModelState = initialModel();
    if (initialModelState && initialModelState.helpCenterBranding) {
        return reduceHCBrandingToState(initialModelState.helpCenterBranding);
    }
    return undefined;
};

const getInitialHelpCenterState = () => {
    const initialModelState = initialModel();
    if (initialModelState && initialModelState.sharedPortal) {
        return reduceHelpCenterToState(initialModelState.sharedPortal);
    }
    return undefined;
};

const defaultState: HelpCenterState = {
    ...{
        sharedPortalName: '',
        helpCenterTitle: '',
        helpCenterTitleColor: colors.N0, // do not tokenize as of now (this might fail on the polished methods)
        translations: {
            'en-US': {
                sharedPortalName: '',
                helpCenterTitle: '',
                localeDisplayName: '',
            },
        },
        canCustomizeHelpCenter: false,
        isLoaded: false,
        themeColor: colors.B400, // do not tokenize as of now (this will fail on the polished methods)
        useDefaultBanner: true,
    },
    ...getInitialHelpCenterState(),
    ...getInitialBrandingState(),
    isBrandingLoaded: false,
};

export type HandledActions =
    | UpdateHCBrandingModelAction
    | UpdateHelpCenterModelAction
    | UpdateHCThemeColorAction
    | UpdateHCBrandingLoaderAction;

export default function reducer(state: HelpCenterState = defaultState, action: HandledActions) {
    switch (action.type) {
        case UPDATE_HELP_CENTER_THEME_COLOR: {
            return { ...state, ...action.payload };
        }
        case UPDATE_HELP_CENTER_MODEL: {
            const newState = reduceHelpCenterToState(action.payload);
            return { ...state, ...newState };
        }
        case UPDATE_HELP_CENTER_BRANDING_MODEL: {
            const newState = reduceHCBrandingToState(action.payload);
            return { ...state, ...newState };
        }
        case UPDATE_HELP_CENTER_BRANDING_LOADER: {
            return { ...state, isBrandingLoaded: action.payload.loaded };
        }
        default:
            return state;
    }
}
