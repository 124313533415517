import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { logOutAction } from 'epics/logout/actions';
import type { State } from 'state';
import { getJsmAIConfigEnabled } from 'state/selectors/env';
import { getAvatarSrc, getCustomMenuLinks, getCanAdministerJIRA } from 'state/selectors/user';
import { AvatarMenuDumb } from '../avatar-menu-dumb';

const getCustomMenuLinksProps = (state: State) => {
    const customMenuLinks = getCustomMenuLinks(state);
    return customMenuLinks.map((menuLink) => {
        return {
            id: menuLink.id,
            key: menuLink.key,
            label: menuLink.label,
            href: menuLink.href,
            styleClass: menuLink.styleClass,
        };
    });
};

const selector = createSelector(
    getAvatarSrc,
    getCustomMenuLinksProps,
    getCanAdministerJIRA,
    getJsmAIConfigEnabled,
    (avatarSrc, customMenuLinks, canAdministerJira, jsmAiConfigEnabled) => ({
        avatarSrc,
        customMenuLinks,
        canAdministerJira,
        jsmAiConfigEnabled,
    })
);

export default connect(selector, {
    onLogoutClick: logOutAction,
})(AvatarMenuDumb);
