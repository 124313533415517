import environment from '@helpCenter/util/relay/environment';
import { fetchQuery, graphql } from 'react-relay';
import { getMeta } from '@atlassian/help-center-common-util/meta';
import type { ProviderAuthDetail } from '../../rest/converse';
import type { providerAuthStatusQuery } from './__generated__/providerAuthStatusQuery.graphql';

export const fetchProviderAuthStatus = async (): Promise<ProviderAuthDetail[]> => {
    const data = await fetchQuery<providerAuthStatusQuery>(
        environment,
        graphql`
            query providerAuthStatusQuery($cloudId: ID!) {
                helpObjectStore {
                    providerAuthStatuses(cloudId: $cloudId) {
                        ... on HelpObjectStoreProviderAuthStatuses {
                            __typename
                        }
                        ... on HelpObjectStoreProviderAuthStatuses {
                            providerAuthStatuses {
                                provider
                                authUrl
                                isAuthRequired
                            }
                        }
                    }
                }
            }
        `,
        {
            cloudId: getMeta('tenant-id') ?? '',
        }
    ).toPromise();

    const resultType = data?.helpObjectStore?.providerAuthStatuses?.__typename;

    const authStatusesData =
        resultType === 'HelpObjectStoreProviderAuthStatuses' &&
        data?.helpObjectStore?.providerAuthStatuses?.providerAuthStatuses;

    if (authStatusesData) {
        const providerAuthStatuses: ProviderAuthDetail[] = authStatusesData
            .filter((providerAuthDetail) => providerAuthDetail?.isAuthRequired)
            .map((authProvider: { authUrl: string; provider: string; isAuthRequired: boolean }) => ({
                authUrl: authProvider?.authUrl,
                unauthorizedProvider: authProvider?.provider,
            }));

        return providerAuthStatuses;
    }
    return [];
};
