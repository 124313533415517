import type { PropsWithChildren } from 'react';
import React from 'react';
import { NewGlobalBGColor } from '@helpCenter/view/styles/global';
import { useMediaQuery } from 'view/help-center/use-screen-size/use-screen-size';
import { Header as DefaultHeader } from 'view/layout/header';
import * as grid from 'view/styles/grid';
import { sizes } from 'view/styles/sizes-viewport';
import { Main, Content, PageLayout, TopNavigation } from '@atlaskit/page-layout';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { SIDEBAR_WIDTH } from '../constants';
import type { NavigationContentItem } from '../sidebar';
import { Sidebar } from '../sidebar';

interface Props {
    featureName: string;
}
export const AdminManagementLayout: React.FC<PropsWithChildren<Props>> = ({ children, featureName }) => {
    const navigationContentItems: NavigationContentItem[] = [];
    const isLaptop = !useMediaQuery(`(max-width: ${sizes.small}px)`);
    return (
        <Box xcss={PageLayoutWrapper} testId="admin-layout-management-page-wrapper">
            <PageLayout>
                <TopNavigation isFixed={false}>
                    <Box xcss={HeaderContainer}>
                        <DefaultHeader logoTextColor="theme" hideSearchButton />
                    </Box>
                </TopNavigation>
                <Content>
                    {isLaptop && (
                        <Sidebar
                            width={SIDEBAR_WIDTH}
                            navigationContentItems={navigationContentItems}
                            featureName={featureName}
                        />
                    )}
                    <Main>
                        <Box xcss={Container}>{children}</Box>
                    </Main>
                    <NewGlobalBGColor />
                </Content>
            </PageLayout>
        </Box>
    );
};

export default AdminManagementLayout;

const Container = xcss({
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
    maxWidth: grid.multiple(145).px,
    margin: '0 auto',
    paddingBottom: token('space.050', '4px'),
});
const HeaderContainer = xcss({
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
    borderBottom: `1px solid ${token('color.border', colors.N40)}`,
});

const PageLayoutWrapper = xcss({
    overflowAnchor: 'none',
    height: '100%',
});
