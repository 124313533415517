import { searchExperienceId } from '@atlassian/help-center-common-util/analytics/ufo/ufo';
import { ExperiencePerformanceTypes, ExperienceTypes, UFOExperience } from '@atlassian/ufo';

export const searchExperience = new UFOExperience(searchExperienceId, {
    type: ExperienceTypes.Load,
    performanceType: ExperiencePerformanceTypes.PageLoad,
});

export const csmSearchExperience = new UFOExperience('csm-help-center-search', {
    type: ExperienceTypes.Load,
    performanceType: ExperiencePerformanceTypes.InlineResult,
});
