import type { ProviderAuthDetail } from '../../rest/converse';
import type { AuthenticationMessage, ConversationMessageMetadata } from '../../store/converse/types';

export const getAuthMessages = (
    showAuthenticationMessage: boolean,
    providerAuthDetails: ProviderAuthDetail[],
    metadata?: ConversationMessageMetadata
): AuthenticationMessage[] => {
    return showAuthenticationMessage && providerAuthDetails?.length
        ? providerAuthDetails.map((providerAuthDetail) => ({
              userType: 'authentication',
              userAccountId: null,
              messageContentAdf: '',
              messageMetadata: {
                  ...(metadata ?? {}),
                  providerAuthDetail,
              },
          }))
        : [];
};
