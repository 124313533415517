import { useCallback, useMemo } from 'react';
import { fetchJson } from '@helpCenter/util/fetch/fetch';
import { di } from 'react-magnetic-di';
import type { AgentMessage, ConversationProvider, HelpseekerMessage } from '../../common/types';
import type {
    ExternalSource,
    CreateVirtualAgentConversationResponse,
    VirtualAgentConversationMessageResponse,
} from './types';

export const useVirtualAgentConversationV2Provider = ({
    contextId,
    cloudId,
    isTestConversation,
    externalSource,
    baseUrl = '',
}: {
    contextId: string;
    cloudId: string;
    isTestConversation: boolean | undefined;
    externalSource: ExternalSource;
    baseUrl?: string;
}): ConversationProvider => {
    di(fetchJson);

    const startConversation = useCallback(
        async (abortSignal: AbortSignal): Promise<CreateVirtualAgentConversationResponse | undefined> => {
            try {
                const response = await fetchJson<CreateVirtualAgentConversationResponse>(
                    `${baseUrl}/gateway/api/jsm/virtual-agent/cloudId/${cloudId}/public/api/v2/web/conversation`,
                    {
                        signal: abortSignal,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            contextId,
                            createdAt: new Date().toISOString(),
                            isTestConversation: isTestConversation === true,
                        }),
                        method: 'POST',
                    }
                );
                return response;
            } catch (error: unknown) {
                if (error instanceof DOMException) {
                    // TODO: add analytics for this
                    return undefined;
                }
                throw error;
            }
        },
        [baseUrl, cloudId, isTestConversation, contextId]
    );

    const sendMessage = useCallback(
        async (message: HelpseekerMessage, abortSignal: AbortSignal): Promise<AgentMessage[]> => {
            try {
                const response = await fetchJson<VirtualAgentConversationMessageResponse[]>(
                    `${baseUrl}/gateway/api/jsm/virtual-agent/cloudId/${cloudId}/public/api/v2/web/conversation/message`,
                    {
                        signal: abortSignal,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            externalSource,
                            message: {
                                content: JSON.stringify(message.message.content),
                                optionId: message.message.optionId,
                                jiraField: message.message.jiraField,
                            },
                            timeCreated: message.timeCreated,
                            conversationChannelId: message.conversationChannelId,
                        }),
                        method: 'POST',
                    }
                );

                return response;
            } catch (error: unknown) {
                if (error instanceof DOMException) {
                    // TODO: add analytics for this
                    return [];
                }
                throw error;
            }
        },
        [baseUrl, cloudId, externalSource]
    );

    return useMemo(() => ({ startConversation, sendMessage }), [sendMessage, startConversation]);
};
