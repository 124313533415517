export const SUBSCRIBE_ACTION_KEY = 'subscribe';
export const UNSUBSCRIBE_ACTION_KEY = 'unsubscribe';
export const NO_SUBSCRIBE_ACTION_KEY = 'none';

export interface SimpleUserResponse {
    email: string;
    displayName: string;
    avatarUrl: string;
    accountId: string;
}

export interface CustomerOrganisationResponse {
    id: number;
    name: string;
    domain: string;
}

export interface RequestDetailsResponse {
    reporter?: SimpleUserResponse; // this is optional is somebody deletes the reporter in agent view (anonymous)
    participants: SimpleUserResponse[];
    organisations: CustomerOrganisationResponse[];
    requestTypeName: string;
    key: string;
    issueType: string;
    issueTypeName: string;
    issue: IssueResponse;
    canCreateAttachments: boolean;
    canCreateIssues: boolean;
    canAddComment: boolean;
    canViewIssueInJIRA: boolean;
    canAddParticipants: boolean;
    canRemoveParticipants: boolean;
    canSearchParticipants: boolean;
    canSignupParticipants: boolean;
    canSubmitWithEmailAddress: boolean;
    canShareRequest: boolean;
    topPanels: WebPanelBean[];
    detailsPanels: WebPanelBean[];
    optionPanels: WebPanelBean[];
    actionSections: WebSectionBean[];
    issueLinkUrl: string;
    requestDetailsBaseUrl: string;
    customerInvited: boolean;
    subscribeAction?: SubscribeAction;
    pendingApprovalId?: number;
    workflowTransitions?: WorkflowTransition[];
    approvalStatus: Approval[];
    readFileMediaCredentials: ReadFileMediaCredentials;
    hasProformaForm: boolean;
    userMentionedDuringRequestCreation?: boolean;
    isProformaHarmonisationEnabled: boolean;
}

export interface AddParticipantsResponse {
    participants: SimpleUserResponse[];
    organisations: CustomerOrganisationResponse[];
}

export interface WorkflowTransition {
    name: string;
    id: number;
}

export type SubscribeAction =
    | typeof NO_SUBSCRIBE_ACTION_KEY
    | typeof SUBSCRIBE_ACTION_KEY
    | typeof UNSUBSCRIBE_ACTION_KEY;

export interface WebPanelBean {
    key: string;
    name: string;
    html: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params: any;
}

export interface WebSectionBean {
    id: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params: any;
    key: string;
    label: string;
    items: WebItemBean[];
}

export interface WebItemBean {
    key: string;
    label: string;
    href: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params: any;
    styleClass: string;
}

export interface IssueResponse {
    id: number;
    key: string;
    creatorAccountId: string;
    reporter?: SimpleUserResponse; // this is optional is somebody deletes the reporter in agent view (anonymous)
    participants: SimpleUserResponse[];
    organisations: CustomerOrganisationResponse[];
    sequence: number;
    serviceDeskKey: string;
    requestTypeName: string;
    requestTypeId: number;
    summary: string;
    isNew: boolean;
    status: string;
    resolution: string;
    date: string;
    friendlyDate: string;
    fields: FieldAndValue[];
    activityStream: ActivityItem[];
    requestIcon: number;
    iconUrl: string;
    canBrowse: boolean;
    canAttach: boolean;
    categoryKey: string;
    formKey: string;
}

export interface FieldAndValue {
    id: string;
    label: string;
    value: AdfFieldValue | HtmlFieldValue | TextFieldValue;
}

export type FieldValue = AdfFieldValue | HtmlFieldValue | TextFieldValue;

export interface HtmlFieldValue {
    html: string;
    rawValue: string;
}

export interface AdfFieldValue {
    adf: string;
}

export interface TextFieldValue {
    text: string;
}

export type ActivityItem =
    | ResolutionUpdate
    | RequesterComment
    | ApprovalSystemDecided
    | ApproverDecision
    | ApprovalCreated
    | StatusUpdate
    | WorkerComment;

export interface ResolutionUpdate {
    type: 'resolution-update';
    activityText: string;
    date: string;
    friendlyDate: string;
    resolution: string;
    status: string;
}

export interface RequesterComment {
    type: 'requester-comment';
    activityText: string;
    date: string;
    friendlyDate: string;
    author: string;
    avatarUrl: string;
    commentId: number;
    comment: string;
    rawComment: string;
    adfComment: string;
}

export interface WorkerComment {
    type: 'worker-comment';
    activityText: string;
    date: string;
    friendlyDate: string;
    author: string;
    avatarUrl: string;
    commentId: number;
    comment: string;
    rawComment: string;
    invisibleToReporter: boolean;
    outsiderComment: boolean;
    canAddAuthorAsParticipant: boolean;
    adfComment: string;
}

export interface ApprovalSystemDecided {
    type: 'approval-system-decision';
    activityText: string;
    date: string;
    friendlyDate: string;
    decision: string;
}

export interface ApproverDecision {
    type: 'approver-decision';
    activityText: string;
    date: string;
    friendlyDate: string;
    approverName: string;
    decision: string;
    avatarUrl: string;
    pendingCount?: number;
}

export interface ApprovalCreated {
    type: 'approval-created';
    activityText: string;
    date: string;
    friendlyDate: string;
    pendingCount?: number;
}

export interface StatusUpdate {
    type: 'status-update';
    activityText: string;
    date: string;
    friendlyDate: string;
    status: string;
}

export interface Approval {
    name: string;
    pendingApprovalCount?: number;
    decision?: ApprovalDecision;
    approvers: Approver[];
}

export interface ApprovalDecision {
    decision: ApprovalDecisionType;
    systemDecided: boolean;
}

export type ApprovalDecisionType = 'APPROVED' | 'REJECTED';

export type ApprovalByEmailDecisionType = 'APPROVE' | 'DECLINE';

export interface Approver {
    displayName: string;
    avatarUrl: string;
    decision?: ApproverDecisionType;
}

export interface ReadFileMediaCredentials {
    clientId: string;
    endpointUrl: string;
    tokenLifespanInSeconds: number;
    tokensWithFiles: TokenWithFile[];
}

export interface TokenWithFile {
    token: string;
    files: AttachmentFile[];
}

export interface AttachmentFile {
    attachmentId: number;
    attachmentMediaApiId: string;
    issueId: number;
}

export type ApproverDecisionType = 'pending' | 'approved' | 'rejected';
