import * as React from 'react';
import type { InjectedIntlProps } from 'react-intl';
import { injectIntl } from 'react-intl';
import { styled } from '@compiled/react';
import { DropdownItemGroup, DropdownItem } from '@atlaskit/dropdown-menu';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { ThemedBadge } from '@atlassian/help-center-common-component/badges';
import { InteractiveElement } from '@atlassian/help-center-common-component/interactive-element';
import { getRequestListUrl } from '@atlassian/help-center-common-util/url';

import { NoUnderlineLink } from '../styled';
import messages from './messages';

const createdByMeMenuItem = (myRequestCount: number, intl: ReactIntl.InjectedIntl) => {
    const requestListUrl = getRequestListUrl({
        page: 1,
        statuses: ['open'],
    });

    return (
        <NoUnderlineLink to={requestListUrl} id="myrequestsmenuitem" actionSubjectId="navbarCreatedByMe">
            <DropdownItem elemAfter={myRequestCount > 0 ? <MenuItemBadge>{myRequestCount}</MenuItemBadge> : undefined}>
                <Box xcss={themelessStyle}>{intl.formatMessage(messages.createdByMe)}</Box>
            </DropdownItem>
        </NoUnderlineLink>
    );
};

const allRequestsMenuItem = (intl: ReactIntl.InjectedIntl) => {
    const allRequestsPageUrl = getRequestListUrl({
        page: 1,
        reporter: 'all',
        statuses: ['open'],
    });

    return (
        <NoUnderlineLink to={allRequestsPageUrl} id="allrequestsmenuitem" actionSubjectId="navbarAllRequests">
            <DropdownItem testId="allrequestsmenuitem">
                <Box xcss={themelessStyle}>{intl.formatMessage(messages.all)}</Box>
            </DropdownItem>
        </NoUnderlineLink>
    );
};

const approvalMenuItem = (pendingApprovalCount: number, intl: ReactIntl.InjectedIntl) =>
    pendingApprovalCount ? myPendingApprovalMenuItem(pendingApprovalCount, intl) : myApprovalMenuItem(intl);

const approvalLinkFor = (approvalQueryType: string) => {
    return `/user/approvals?approvalQueryType=${approvalQueryType}&page=1`;
};

const myPendingApprovalMenuItem = (pendingApprovalCount: number, intl: ReactIntl.InjectedIntl) => {
    return (
        <NoUnderlineLink
            to={approvalLinkFor('myPending')}
            id="approvalsMenuItem"
            actionSubjectId="navbarMyPendingApprovals"
        >
            <DropdownItem testId="approvalsMenuItem" elemAfter={<MenuItemBadge>{pendingApprovalCount}</MenuItemBadge>}>
                <Box xcss={themelessStyle}>{intl.formatMessage(messages.approvals)}</Box>
            </DropdownItem>
        </NoUnderlineLink>
    );
};

const myApprovalMenuItem = (intl: ReactIntl.InjectedIntl) => {
    return (
        <NoUnderlineLink to={approvalLinkFor('myApproval')} id="approvalsMenuItem" actionSubjectId="navbarMyApprovals">
            <DropdownItem testId="approvalsMenuItem">
                <Box xcss={themelessStyle}>{intl.formatMessage(messages.approvals)}</Box>
            </DropdownItem>
        </NoUnderlineLink>
    );
};

const organisationMenuItems = (organisations: Organisation[]) => {
    return organisations.map((org) => organisationMenuItem(org));
};

const organisationMenuItem = (org: Organisation) => {
    const allOrgsRequestsPageUrl = getRequestListUrl({
        page: 1,
        reporter: `org-${org.id}`,
        statuses: ['open'],
    });

    return (
        <NoUnderlineLink
            key={`orgrequestsmenuitem-${org.id}`}
            to={allOrgsRequestsPageUrl}
            id={`orgrequestsmenuitem-${org.id}`}
            actionSubjectId="navbarOrganisation"
        >
            <DropdownItem testId={`orgrequestsmenuitem-${org.id}`}>
                <Box xcss={themelessStyle}>{org.name}</Box>
            </DropdownItem>
        </NoUnderlineLink>
    );
};

interface RequestsMenuGroupProps {
    onItemClick: () => void;
    pendingApprovalCount: number;
    approvalCount: number;
    myRequestCount: number;
    organisations: Organisation[];
}

export interface Organisation {
    name: string;
    id: number;
}

const RequestsMenuGroup: React.FC<RequestsMenuGroupProps & InjectedIntlProps> = (props) => {
    const { onItemClick, pendingApprovalCount, approvalCount, myRequestCount, organisations, intl } = props;

    const showApproval = pendingApprovalCount > 0 || approvalCount > 0;

    return (
        <DropdownItemGroup title={intl.formatMessage(messages.requests)}>
            <InteractiveElement tagName="span" onClick={onItemClick} tabIndex={-1}>
                {createdByMeMenuItem(myRequestCount, intl)}
                {showApproval ? approvalMenuItem(pendingApprovalCount, intl) : undefined}
                {organisationMenuItems(organisations)}
                {allRequestsMenuItem(intl)}
            </InteractiveElement>
        </DropdownItemGroup>
    );
};

export default injectIntl(RequestsMenuGroup);

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const MenuItemBadge = styled(ThemedBadge)({
    float: 'right',
    marginLeft: token('space.600', '48px'),
});

const themelessStyle = xcss({
    color: 'color.text',
});
