/**
 * Note: we want to hide CTA button on request creation page as it's distracting how user would fill out the request
 * There are a couple of ways (route name is either request-create, or portal-home)to know if we are
 * on the request creation page or not.
 *
 * For `request-create` route it's straightforward, we check the route.name
 * - /portal/:portalId/create/:requestTypeId
 *
 * However, for route `portal-home`, there are two possible cases, so we also need to check the `requestTypeId` presents
 * - /portal/:portalId/:create?/:requestTypeId - without group
 * - /portal/:portalId/:group?/:requestGroupId?/:create?/:requestTypeId - with group
 *
 * @param routeName
 * @param requestTypeId
 */
export const shouldShowCTA = (routeName: string, requestTypeId?: string) => {
    switch (routeName) {
        case 'portal-home':
            return requestTypeId === undefined;
        case 'request-create':
            return false;
        case 'viewHelpDesk':
            return false;
        default:
            return true;
    }
};
