import { get } from 'epics/ajax';
import { Observable } from 'epics/rxjs';
import { isApolloStargatePathEnabled } from 'feature-flags';
import * as qs from 'query-string';
import type { Category } from 'rest/category';
import { APOLLO_CONTEXT_ROOT, APOLLO_STARGATE_CONTEXT_ROOT } from 'rest/constants';
import type { AjaxError } from 'rxjs/observable/dom/AjaxObservable';
import { trackError } from '@atlassian/help-center-common-util/analytics';
import { contextPath as historyContextPath } from '@atlassian/help-center-common-util/history';
import { getMeta } from '@atlassian/help-center-common-util/meta';
import messages from './messages';
import type { ApolloErrorResponse } from 'epics/model/types';
export interface FetchArticlesForCategoryRequest {
    projectId: number;
    categoryId: string;
    contextPath?: string;
    apolloPath?: string;
}

export interface Article {
    id: string;
    title: string;
    titleExcerpt: string;
    summaryExcerpt: string;
    viewUrl: string;
    shareUrl: string;
    editUrl: string;
    lastModified: string;
    friendlyLastModified: string;
    viewCount: number;
    categories?: Category[];
    featuredInCategories?: string[];
}

export interface ArticlesResponse {
    results: Article[];
    totalSize: number;
    start: number;
    limit: number;
}

const errorKeyToMessage = {
    'jsd.apollo.error.category.not.found': messages.categoryNotFound,
    'jsd.apollo.error.project.permission.no.view.project.permission': messages.noPermission,
    'jsd.apollo.error.article.not.found': messages.articleNotFound,
};

const mapApolloErrorResponse = (error: AjaxError): ApolloErrorResponse => {
    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response: ApolloErrorResponse | undefined = error.response;
    // @ts-ignore TS(7053) TypeScript upgrade 5.1.6, please fix this violation when you revisit this code.: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    if (response && response.errorKey && errorKeyToMessage[response.errorKey]) {
        // @ts-ignore TS(7053) TypeScript upgrade 5.1.6, please fix this violation when you revisit this code.: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        // Suppressing existing violation. Please fix this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const translatedMessage = errorKeyToMessage[response.errorKey];

        // Suppressing existing violation. Please fix this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-assignment
        return { ...error.response, errorMessage: translatedMessage };
    }

    return { statusCode: error.status, errorKey: '', errorMessage: '' };
};

export const fetchArticlesForCategory = ({
    projectId,
    categoryId,
    contextPath = historyContextPath,
    apolloPath = APOLLO_CONTEXT_ROOT,
}: FetchArticlesForCategoryRequest): Observable<ArticlesResponse> => {
    const queryString = qs.stringify({ limit: 100, orderBy: '+featured', expand: 'category' });
    const uri = isApolloStargatePathEnabled()
        ? // TypeScript upgrade (v4.4.3). Please correct when you revisit this code. Please correct when this code is revisited.
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          `${contextPath}${APOLLO_STARGATE_CONTEXT_ROOT}/${getMeta(
              'activation-id'
          )}/api/project/${projectId}/category/${categoryId}/article?${queryString}`
        : `${contextPath}${apolloPath}/api/project/${projectId}/category/${categoryId}/article?${queryString}`;
    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return (
        get(uri, { 'Content-Type': 'application/json' })
            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            .map(({ response }) => response)
            .catch((e: AjaxError) => {
                trackError('category.article.fetch.failed', {}, e);
                return Observable.throw(mapApolloErrorResponse(e));
            })
    );
};
