import type { Action, Store } from 'redux';
import type { ActionsObservable, Epic } from 'redux-observable';
import { combineEpics } from 'redux-observable';
import { BehaviorSubject } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import type { State } from 'state';
import { completeSignupEpic, sendNewSignupLinkEpic } from './complete-signup';
import { envEpic } from './env';
import { handleAjaxErrorEpic } from './flags';
import {
    updateHelpCenterBrandingEpic,
    updateHelpCenterBrandingEpicMutationSuccess,
    updateHelpCenterBrandingEpicMutationFailure,
} from './help-center-branding';
import { invitedSignUpEpic } from './invited-sign-up';
import { checkLoginActionEpic, forgotPasswordEpic, loginEpic, exitTestModeEpic } from './login';
import { updateLoginAnnouncementsEpic } from './login-announcements';
import { loginConsentEpic } from './login-consent';
import { logOutEpic } from './logout';
import modelEpic from './model/epics';
import { updatePortalAnnouncementsEpic } from './portal-announcements';
import {
    changeRequestSubscriptionEpic,
    createCommentEpic,
    decideApprovalEpic,
    removeOrganisationEpic,
    removeParticipantEpic,
    shareRequestEpic,
    transitionRequestEpic,
    getMediaReadCredentialsEpic,
} from './request-details';
import { requestGroupsEpic } from './request-groups';
import {
    reorderRequestTypeEpic,
    deleteRequestTypeFromGroupEpic,
    createGroupWithRequestTypeEpic,
    addRequestTypeToRequestGroupEpic,
    setRequestTypeGroupsEpic,
} from './request-types';
import { resendVerifyEmailEpic } from './resend-verify-email';
import { resetPasswordEpic } from './reset-password';
import { epic as searchEpic } from './search';
import { fetchServiceProjectPermissionsEpic } from './service-project-request-permissions';
import { resendValidationEmailEpic } from './sign-up';
import { validateAndSavePasswordChangeEpic, changePasswordEpic, updateUserProfileEpic, validateEpic } from './user';
import { userProfileForgotPasswordEpic } from './user/forgot-password';

export const epicsList = [
    modelEpic,
    searchEpic,
    logOutEpic,
    envEpic,
    requestGroupsEpic,
    createGroupWithRequestTypeEpic,
    addRequestTypeToRequestGroupEpic,
    deleteRequestTypeFromGroupEpic,
    setRequestTypeGroupsEpic,
    reorderRequestTypeEpic,
    changeRequestSubscriptionEpic,
    createCommentEpic,
    decideApprovalEpic,
    removeOrganisationEpic,
    removeParticipantEpic,
    shareRequestEpic,
    transitionRequestEpic,
    getMediaReadCredentialsEpic,
    handleAjaxErrorEpic,
    resendValidationEmailEpic,
    validateAndSavePasswordChangeEpic,
    validateEpic,
    changePasswordEpic,
    updateUserProfileEpic,
    checkLoginActionEpic,
    forgotPasswordEpic,
    loginEpic,
    invitedSignUpEpic,
    completeSignupEpic,
    sendNewSignupLinkEpic,
    updateHelpCenterBrandingEpic,
    updateHelpCenterBrandingEpicMutationSuccess,
    updateHelpCenterBrandingEpicMutationFailure,
    resetPasswordEpic,
    updateLoginAnnouncementsEpic,
    updatePortalAnnouncementsEpic,
    userProfileForgotPasswordEpic,
    exitTestModeEpic,
    fetchServiceProjectPermissionsEpic,
    resendVerifyEmailEpic,
    loginConsentEpic,
];

const staticEpics = combineEpics(...epicsList);

export const epic$ = new BehaviorSubject(staticEpics);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const combinedEpic: Epic<Action, State> = (action$: ActionsObservable<Action>, store$: Store<State>, deps: any) =>
    epic$.pipe(mergeMap((epic) => epic(action$, store$, deps)));

export default combinedEpic;
