import React from 'react';
import { isDefaultHelpCenter } from 'util/advanced-help-center/advanced-help-center';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import {
    isAdvancedCustomizationEnabled,
    isMultiHcKoshIntegrationEnabled,
    isJsmSkipToMainA11yFixEnabled,
} from 'feature-flags';
import { di } from 'react-magnetic-di';
import { LoadingSkeleton } from 'view/common/loading';
import { BANNER_HEIGHT, HEADER_HEIGHT } from 'view/common/styled';
import { Header as DefaultHeader } from 'view/layout/header';
import type { PaperAppearance } from 'view/layout/paper';
import { Paper } from 'view/layout/paper';
import { AutoHeightBody } from 'view/layout/styled';
import * as zIndex from 'view/styles/z-index';
import { Content, PageLayout } from '@atlaskit/page-layout';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { BodyClassName } from '@atlassian/help-center-common-component/body-class-name';
import { Flipper } from '@atlassian/help-center-common-component/flip-toolkit';
import { SidebarPusher } from '@atlassian/help-center-common-component/pushing-customize-sidebar';
import {
    isAdvancedHelpCenter as isAdvancedHelpCenterUtil,
    isKoshEnabledForDefaultHelpCenter,
} from '@atlassian/help-center-common-util/advanced-help-center';
import UFOLoadHold from '@atlassian/ufo-load-hold';

interface PageLayoutContentWrapperProps {
    children: JSX.Element;
}

const PageLayoutContentWrapper: React.FC<PageLayoutContentWrapperProps> = ({ children }) =>
    isJsmSkipToMainA11yFixEnabled() ? (
        <PageLayout testId="page-layout">
            <Content>{children}</Content>
        </PageLayout>
    ) : (
        <>{children}</>
    );

export interface Props {
    children: JSX.Element;
    isAnonymous?: boolean;
    headerless?: boolean;
    hideSearchButton?: boolean;
    helpCenterBanner: string | undefined;
    isBrandingLoaded?: boolean;
    paperAppearance?: PaperAppearance;
    // Used as a key to determine whether or not we should add fade transitions between pages
    // we use the top level path from app.tsx to differentiate between different pages
    path: string | undefined;
    searchShown: boolean;
    fadeInSearchModal: boolean;
    hideBanner?: boolean;
}

/**
 * Paper in this context is the floating paper that is ontop of the banner.
 * Banner will have the background image chosen by the admin as well as the
 * theme color as background color.
 */
const BannerWithPaperLayoutDumb = ({
    children,
    paperAppearance,
    isAnonymous,
    headerless,
    hideSearchButton,
    helpCenterBanner,
    path,
    searchShown,
    isBrandingLoaded,
    hideBanner,
}: Props) => {
    di(
        isAdvancedHelpCenterUtil,
        isKoshEnabledForDefaultHelpCenter,
        isAdvancedCustomizationEnabled,
        isMultiHcKoshIntegrationEnabled
    );
    // do not show banner on advanced help center
    const isAdvanceHelpCenter = isAdvancedHelpCenterUtil();

    const showBannerWithLoader = () => {
        if (isBrandingLoaded) {
            return (
                !hideBanner && (
                    <Banner
                        imageSrc={
                            isAdvanceHelpCenter && isAdvancedCustomizationEnabled() ? undefined : helpCenterBanner
                        }
                    />
                )
            );
        }
        return (
            !hideBanner && (
                <UFOLoadHold name="banner-loader">
                    <LoadingContainer>
                        <LoadingSkeleton
                            data-testid="banner-skeleton"
                            height={BANNER_HEIGHT.unitless}
                            backgroundColor={token('color.skeleton.subtle', colors.N20)}
                        />
                    </LoadingContainer>
                </UFOLoadHold>
            )
        );
    };

    return (
        <PageLayoutContentWrapper>
            <SidebarPusherRoot>
                <FlipperWithFlexbox flipKey={searchShown} portalKey="search">
                    <Container>
                        {/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Disabled to rollout go/ui-styling-standard tooling, please resolve */}
                        <BodyClassName className="px-paper-layout" />
                        {isMultiHcKoshIntegrationEnabled() && isKoshEnabledForDefaultHelpCenter()
                            ? showBannerWithLoader()
                            : !hideBanner && <Banner imageSrc={isAdvanceHelpCenter ? undefined : helpCenterBanner} />}
                        {!headerless && (
                            <DefaultHeader
                                isAnonymous={isAnonymous}
                                logoTextColor="banner"
                                hideSearchButton={hideSearchButton}
                                isAdvancedHelpCenter={
                                    (isAdvanceHelpCenter || isDefaultHelpCenter()) && isAdvancedCustomizationEnabled()
                                }
                            />
                        )}
                        {headerless && <EmptyHeader />}
                        <PaperContainer>
                            <Paper appearance={paperAppearance} pageKey={path}>
                                {children}
                            </Paper>
                        </PaperContainer>

                        <AutoHeightBody />
                    </Container>
                </FlipperWithFlexbox>
            </SidebarPusherRoot>
        </PageLayoutContentWrapper>
    );
};

export default BannerWithPaperLayoutDumb;

const CONTAINER_MIN_HEIGHT = '100vh';

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const SidebarPusherRoot = styled(SidebarPusher)`
    display: flex;
    flex-direction: column;
    ${isJsmSkipToMainA11yFixEnabled() ? `width: 100%;` : ''}
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const Container = styled.div`
    position: relative;
    /* Important for the body to not expand past the viewport. */
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    min-height: ${CONTAINER_MIN_HEIGHT};
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const PaperContainer = styled.div`
    position: relative;
    display: flex;
    flex: 1 1 auto;
    overflow: hidden;
`;

interface BannerProps {
    imageSrc?: string;
}

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const Banner = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: ${BANNER_HEIGHT.px};
    z-index: ${zIndex.banner};
    background-color: ${(props) => token('color.background.brand.bold', props.theme.color)};
    background-image: ${(props: BannerProps) => (!!props.imageSrc ? `url(${props.imageSrc})` : 'none')};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const LoadingContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: ${BANNER_HEIGHT.px};
    z-index: ${zIndex.banner};
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const EmptyHeader = styled.div`
    height: ${HEADER_HEIGHT}px;
    width: 100%;
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const FlipperWithFlexbox = styled(Flipper)`
    display: flex;
    flex-direction: column;
`;
