import type { GraphQLResponseWithoutData } from 'relay-runtime';
import type { TFetchError } from './types';

const NO_CONTENT = 204;
const NETWORK_ERROR_MESSAGE = 'Failed to fetch';
const NETWORK_ERROR_CODE = 0;
interface GraphqlExtensionGateway {
    request_id: string;
}
interface PayloadExtensions {
    [key: string]: unknown;
}
interface GraphqlErrorsWithExtensions {
    message: string;
    extensions?: PayloadExtensions;
}
export class FetchError extends Error {
    status: number;
    constructor(message: string, status: number) {
        super(message);
        this.status = status;
    }
}

export const fetchJson = <TResponse,>(url: string, params: RequestInit) => {
    return fetch(url, params)
        .then(handleErrors)
        .then<TResponse>(processResponseFromServer)
        .then(handleGraphQlErrors)
        .catch(handleNetworkErrors);
};

const handleNetworkErrors = (err: Error) => {
    if (err instanceof TypeError) {
        throw new FetchError(NETWORK_ERROR_MESSAGE, NETWORK_ERROR_CODE);
    }
    throw err;
};

const handleErrors = (response: Response) => {
    if (!response.ok) {
        const traceId = response.headers.get('atl-traceid') || 'null';
        throw new FetchError(`ErrorCode: ${response.status}, traceId: ${traceId}`, response.status);
    }
    return response;
};

const processResponseFromServer = (response: Response) => {
    if (response.status === NO_CONTENT) {
        return Promise.resolve(null);
    }
    return response.json();
};

const handleGraphQlErrors = <TResponse,>(response: TResponse) => {
    if (
        response &&
        typeof response === 'object' &&
        // @ts-ignore TS(2339) TypeScript upgrade 5.1.6, please fix this violation when you revisit this code.: Property 'hasOwnProperty' does not exist on type '... Remove this comment to see the full error message
        response.hasOwnProperty('errors') &&
        // @ts-ignore TS(2339) TypeScript upgrade 5.1.6, please fix this violation when you revisit this code.: Property 'hasOwnProperty' does not exist on type '... Remove this comment to see the full error message
        response.hasOwnProperty('extensions')
    ) {
        // throw error from graphql API
        const graphQlResponse = response as unknown as Omit<GraphQLResponseWithoutData, 'errors'> & {
            errors: GraphqlErrorsWithExtensions[];
        };
        throw new FetchError(
            `Error: ${graphQlResponse.errors?.[0]?.message}, traceId: ${
                (graphQlResponse.extensions?.gateway as GraphqlExtensionGateway)?.request_id
            }`,
            Number(graphQlResponse.errors?.[0]?.extensions?.statusCode) || 500
        );
    }
    return response;
};

export const isFetchNetworkError = (err: TFetchError) => {
    return err.status === NETWORK_ERROR_CODE;
};

export const isFetchClientError = (err: TFetchError) => {
    return err.status >= 400 && err.status < 500;
};

export const isFetchNetworkOrClientError = (err: TFetchError) => {
    return isFetchNetworkError(err) || isFetchClientError(err);
};
