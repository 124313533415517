import type { ComponentType } from 'react';
import React from 'react';
import { getCloudId } from '@atlassian/help-center-common-util/meta';
import { useIntentInferenceProvider } from '../../services/intent-inference-service';
import { RoutingStateContainer } from './container';

export const withRoutingStateContainer = <TProps extends object>(WrappedComponent: ComponentType<TProps>) => {
    const WithRoutingStateContainer: React.FC<TProps & { contextId: string }> = (props) => {
        const { contextId } = props;
        const cloudId = getCloudId();
        const intentInferenceProvider = useIntentInferenceProvider({
            cloudId,
            contextId,
        });
        return (
            <RoutingStateContainer intentInferenceProvider={intentInferenceProvider} contextId={contextId} isGlobal>
                <WrappedComponent {...props} />
            </RoutingStateContainer>
        );
    };

    return WithRoutingStateContainer;
};
