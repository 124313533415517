import { useCallback } from 'react';
import { di } from 'react-magnetic-di';
import type { DocNode } from '@atlaskit/adf-schema';
import type { Message, AgentMessage } from '@atlassian/help-center-virtual-agent/src/common/types';
import { ConversationProvider } from '../../../common/types';
import { ASSISTANT_MESSAGE_STATUS } from '../../../rest/converse';
import { useActions as useAIAnswersActions } from '../../../store/converse';
import type { ChatMessage } from '../../../store/converse/types';
import { useActions } from '../../../store/routing';

export const useHandOffConversation = () => {
    di(useActions, useAIAnswersActions);

    const { setProvider } = useActions();
    const { takeOverConversationFromVA } = useAIAnswersActions();

    const handleHandOff = useCallback(
        async (vaMessages: Message[]) => {
            if (!vaMessages.length || vaMessages[0].message.authorType !== 'HELPSEEKER') {
                throw Error('Cannot hand off conversation without a helpseeker message');
            }

            const history = vaMessages
                .map(transformVAMessageToAIAnswersMessage)
                .filter((message): message is ChatMessage => message !== null);
            await takeOverConversationFromVA(history);
            setProvider(ConversationProvider.AI_ANSWERS);
        },
        [setProvider, takeOverConversationFromVA]
    );

    return { handleHandOff };
};

const transformVAMessageToAIAnswersMessage = (message: Message, index: number): ChatMessage | null => {
    switch (message.message.authorType) {
        case 'HELPSEEKER':
            return {
                userType: 'helpseeker',
                messageContentAdf: message.message.content,
                messageMetadata: {},
            };
        case 'AGENT':
            return {
                userType: 'assistant',
                aiMateConversationResponse: {
                    message: {
                        content: parseMessageContent(message.message),
                        role: 'assistant',
                        id: index,
                        time_created: message.timeCreated,
                        user_ari: '',
                        plugin_invocations: [],
                    },
                },
                messageMetadata: {
                    status: ASSISTANT_MESSAGE_STATUS.SYNCED_RESPONSE,
                },
            };
        default:
            return null;
    }
};

const parseMessageContent = (message: AgentMessage['message']): DocNode | string => {
    if (message.contentMimetype === 'application/json+adf') {
        return JSON.parse(message.content) as DocNode;
    }
    return message.content;
};
