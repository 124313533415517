export const BANNER_PREVIEW_WIDTH = '170px';
export const CARD_HEIGHT = '176px';

export const SIDEBAR_WIDTH = 240;

export const NUMBER_OF_HELP_CENTERS_INITIAL_FETCH = 20;
export const NUMBER_OF_HELP_CENTERS_PER_FETCH = 10;
export const SORT_ORDER = 'CREATED_DATE_DESCENDING';

export const HARD_LIMIT_HELP_CENTERS_MESSAGE = 'This operation is not supported : Help Center limit exceeded';
export const RESERVED_TERM_HELP_CENTERS_MESSAGE = `The slug you've entered contains a reserved term. Try again with a different slug.`;
export const ALREADY_EXISTED_HELP_CENTERS_MESSAGE = `A help center with this slug already exists on your site. Try again with a different slug.`;
