import React, { useMemo } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { LoadingSkeleton } from '@atlassian/help-center-common-component/loading';
import { LinkContainer, ResultTitle } from '../common';

export interface SearchResultsLoaderProps {
    numberOfSections: number;
}

export const SearchResultsLoader = ({ numberOfSections }: SearchResultsLoaderProps) => {
    const resultTitle = useMemo(
        () => (
            <ResultTitle>
                <LoadingSkeleton height={20} backgroundColor={token('color.skeleton.subtle', colors.N20)} />
            </ResultTitle>
        ),
        []
    );
    const linkContainer = useMemo(() => {
        return (
            <LinkContainer actionSubjectId="loading-link-container">
                <Box
                    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
                    xcss={xcss({
                        width: '100%',
                        overflow: 'hidden',
                        pointerEvents: 'none',
                    })}
                >
                    <LoadingSkeleton height={65} backgroundColor={token('color.skeleton.subtle', colors.N20)} />
                </Box>
            </LinkContainer>
        );
    }, []);

    // Generate sections based on numberOfSections
    const sections = useMemo(() => {
        const sectionsArray = [];
        for (let i = 0; i < numberOfSections; i++) {
            sectionsArray.push(
                <React.Fragment key={i}>
                    {resultTitle}
                    {linkContainer}
                    {linkContainer}
                </React.Fragment>
            );
        }
        return sectionsArray;
    }, [linkContainer, numberOfSections, resultTitle]);

    return <Box testId="search-results-loader">{sections}</Box>;
};
