/**
 * DO NOT EDIT.
 *
 * This is Relay generated artifact.
 *
 * Please reach out to #jsm-cloud-megatron for any questions.
 *
 * @generated SignedSource<<3a3541f65dc59da18b968853a90e3142>>
 * @relayHash 8751ba3c95f34f93e1a46048563cf2e1
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 9095aa11dcbca516c1a7fe90f4cc87987ef5c3e05cf18a479a9090c42fe9ad4b

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type editHomepageQuery$variables = {
  cloudId: string;
  helpCenterAri: string;
  layoutAri: string;
};
export type editHomepageQuery$data = {
  readonly helpCenter: {
    readonly helpCenterById: {
      readonly __typename: "QueryError";
      readonly " $fragmentSpreads": FragmentRefs<"globalBrandingSettingsPanelFragment">;
    } | null | undefined;
    readonly mediaConfig: {
      readonly " $fragmentSpreads": FragmentRefs<"globalBrandingSettingsPanelMediaConfigFragment">;
    };
  } | null | undefined;
  readonly helpLayout: {
    readonly elementTypes: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"elementsCatalogueFragment">;
    }> | null | undefined;
    readonly layout: {
      readonly " $fragmentSpreads": FragmentRefs<"layoutBuilderFragment" | "useLayoutServiceFragment">;
    };
    readonly mediaConfig: {
      readonly asapIssuer: string | null | undefined;
      readonly mediaCollectionName: string | null | undefined;
      readonly mediaToken: string | null | undefined;
      readonly mediaUrl: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type editHomepageQuery = {
  response: editHomepageQuery$data;
  variables: editHomepageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "helpCenterAri"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "layoutAri"
},
v3 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
],
v4 = [
  {
    "kind": "Variable",
    "name": "helpCenterAri",
    "variableName": "helpCenterAri"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "layoutAri"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "HelpLayoutSubsectionConfig",
  "kind": "LinkedField",
  "name": "config",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "span",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "headingType",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "horizontalAlignment",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "verticalAlignment",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "HelpLayoutAlignmentSettings",
  "kind": "LinkedField",
  "name": "alignment",
  "plural": false,
  "selections": [
    (v10/*: any*/),
    (v11/*: any*/)
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fileId",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "HelpLayoutBackgroundImage",
  "kind": "LinkedField",
  "name": "backgroundImage",
  "plural": false,
  "selections": [
    (v13/*: any*/),
    (v14/*: any*/)
  ],
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "backgroundColor",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hidden",
  "storageKey": null
},
v18 = [
  (v12/*: any*/),
  (v15/*: any*/),
  (v16/*: any*/),
  (v17/*: any*/)
],
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "HelpLayoutVisualConfig",
  "kind": "LinkedField",
  "name": "visualConfig",
  "plural": false,
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useLayoutServiceVisualConfigData",
      "selections": (v18/*: any*/),
      "args": null,
      "argumentDefinitions": ([]/*: any*/)
    }
  ],
  "storageKey": null
},
v20 = {
  "kind": "InlineDataFragmentSpread",
  "name": "useLayoutServiceHeadingElementData",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        (v8/*: any*/),
        (v9/*: any*/),
        (v19/*: any*/)
      ],
      "type": "HelpLayoutHeadingAtomicElement",
      "abstractKey": null
    }
  ],
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "adf",
  "storageKey": null
},
v22 = [
  (v21/*: any*/),
  (v19/*: any*/)
],
v23 = {
  "kind": "InlineDataFragmentSpread",
  "name": "useLayoutServiceParagraphElementData",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": (v22/*: any*/),
      "type": "HelpLayoutParagraphAtomicElement",
      "abstractKey": null
    }
  ],
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "placeHolderText",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "altText",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "size",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageUrl",
  "storageKey": null
},
v28 = {
  "kind": "InlineDataFragmentSpread",
  "name": "useLayoutServiceImageElementData",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        (v5/*: any*/),
        (v25/*: any*/),
        (v13/*: any*/),
        (v26/*: any*/),
        (v27/*: any*/),
        (v19/*: any*/)
      ],
      "type": "HelpLayoutImageAtomicElement",
      "abstractKey": null
    }
  ],
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
},
v29 = {
  "alias": "linkCardConfig",
  "args": null,
  "kind": "ScalarField",
  "name": "config",
  "storageKey": null
},
v30 = [
  (v19/*: any*/)
],
v31 = [
  {
    "kind": "InlineFragment",
    "selections": (v30/*: any*/),
    "type": "HelpLayoutPortalsListElement",
    "abstractKey": null
  }
],
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__id",
  "storageKey": null
},
v33 = {
  "kind": "ClientExtension",
  "selections": [
    (v32/*: any*/)
  ]
},
v34 = [
  {
    "kind": "Variable",
    "name": "parentAri",
    "variableName": "helpCenterAri"
  }
],
v35 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mediaUrl",
  "storageKey": null
},
v36 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mediaToken",
  "storageKey": null
},
v37 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mediaCollectionName",
  "storageKey": null
},
v38 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "asapIssuer",
  "storageKey": null
},
v39 = [
  (v35/*: any*/),
  (v36/*: any*/),
  (v37/*: any*/),
  (v38/*: any*/)
],
v40 = {
  "alias": null,
  "args": (v34/*: any*/),
  "concreteType": "HelpLayoutMediaConfig",
  "kind": "LinkedField",
  "name": "mediaConfig",
  "plural": false,
  "selections": (v39/*: any*/),
  "storageKey": null
},
v41 = [
  (v14/*: any*/),
  (v13/*: any*/)
],
v42 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v43 = {
  "kind": "InlineFragment",
  "selections": [
    (v42/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
},
v44 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v45 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v46 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "logo",
  "storageKey": null
},
v47 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "portalId",
  "storageKey": null
},
v48 = {
  "alias": null,
  "args": null,
  "concreteType": "HelpLayoutAtomicElementType",
  "kind": "LinkedField",
  "name": "elementType",
  "plural": false,
  "selections": [
    (v44/*: any*/)
  ],
  "storageKey": null
},
v49 = {
  "alias": null,
  "args": null,
  "concreteType": "HelpLayoutVisualConfig",
  "kind": "LinkedField",
  "name": "visualConfig",
  "plural": false,
  "selections": (v18/*: any*/),
  "storageKey": null
},
v50 = {
  "kind": "InlineFragment",
  "selections": [
    (v8/*: any*/),
    (v9/*: any*/),
    (v49/*: any*/)
  ],
  "type": "HelpLayoutHeadingAtomicElement",
  "abstractKey": null
},
v51 = {
  "kind": "InlineFragment",
  "selections": [
    (v21/*: any*/),
    (v49/*: any*/)
  ],
  "type": "HelpLayoutParagraphAtomicElement",
  "abstractKey": null
},
v52 = {
  "kind": "InlineFragment",
  "selections": [
    (v25/*: any*/),
    (v13/*: any*/),
    (v26/*: any*/),
    (v27/*: any*/),
    (v49/*: any*/)
  ],
  "type": "HelpLayoutImageAtomicElement",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "editHomepageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "HelpCenterQueryApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "helpCenterById",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "globalBrandingSettingsPanelFragment"
                  }
                ],
                "type": "HelpCenter",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v5/*: any*/)
                ],
                "type": "QueryError",
                "abstractKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "RequiredField",
            "field": {
              "alias": null,
              "args": (v4/*: any*/),
              "concreteType": "HelpCenterMediaConfig",
              "kind": "LinkedField",
              "name": "mediaConfig",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "globalBrandingSettingsPanelMediaConfigFragment"
                }
              ],
              "storageKey": null
            },
            "action": "THROW",
            "path": "helpCenter.mediaConfig"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "HelpLayoutQueryApi",
        "kind": "LinkedField",
        "name": "helpLayout",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "alias": null,
              "args": (v6/*: any*/),
              "concreteType": null,
              "kind": "LinkedField",
              "name": "layout",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "layoutBuilderFragment"
                    },
                    {
                      "kind": "InlineDataFragmentSpread",
                      "name": "useLayoutServiceFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "HelpLayoutSectionConnection",
                          "kind": "LinkedField",
                          "name": "sections",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "HelpLayoutSectionEdge",
                              "kind": "LinkedField",
                              "name": "edges",
                              "plural": true,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "HelpLayoutSection",
                                  "kind": "LinkedField",
                                  "name": "node",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "HelpLayoutSubsection",
                                      "kind": "LinkedField",
                                      "name": "subsections",
                                      "plural": true,
                                      "selections": [
                                        (v7/*: any*/),
                                        {
                                          "alias": null,
                                          "args": null,
                                          "concreteType": null,
                                          "kind": "LinkedField",
                                          "name": "elements",
                                          "plural": true,
                                          "selections": [
                                            (v5/*: any*/),
                                            (v20/*: any*/),
                                            (v23/*: any*/),
                                            {
                                              "kind": "InlineDataFragmentSpread",
                                              "name": "useLayoutServiceEditorElementData",
                                              "selections": [
                                                {
                                                  "kind": "InlineFragment",
                                                  "selections": (v22/*: any*/),
                                                  "type": "HelpLayoutEditorElement",
                                                  "abstractKey": null
                                                }
                                              ],
                                              "args": null,
                                              "argumentDefinitions": []
                                            },
                                            {
                                              "kind": "InlineDataFragmentSpread",
                                              "name": "useLayoutServiceSearchElementData",
                                              "selections": [
                                                {
                                                  "kind": "InlineFragment",
                                                  "selections": [
                                                    (v5/*: any*/),
                                                    (v24/*: any*/)
                                                  ],
                                                  "type": "HelpLayoutSearchAtomicElement",
                                                  "abstractKey": null
                                                }
                                              ],
                                              "args": null,
                                              "argumentDefinitions": []
                                            },
                                            (v28/*: any*/),
                                            {
                                              "kind": "InlineDataFragmentSpread",
                                              "name": "useLayoutServiceLinkCardElementData",
                                              "selections": [
                                                {
                                                  "kind": "InlineFragment",
                                                  "selections": [
                                                    (v29/*: any*/),
                                                    {
                                                      "alias": null,
                                                      "args": null,
                                                      "concreteType": null,
                                                      "kind": "LinkedField",
                                                      "name": "children",
                                                      "plural": true,
                                                      "selections": [
                                                        (v5/*: any*/),
                                                        (v20/*: any*/),
                                                        (v28/*: any*/),
                                                        (v23/*: any*/)
                                                      ],
                                                      "storageKey": null
                                                    }
                                                  ],
                                                  "type": "HelpLayoutLinkCardCompositeElement",
                                                  "abstractKey": null
                                                }
                                              ],
                                              "args": null,
                                              "argumentDefinitions": []
                                            },
                                            {
                                              "kind": "InlineDataFragmentSpread",
                                              "name": "useLayoutServiceHeroElementData",
                                              "selections": [
                                                {
                                                  "kind": "InlineFragment",
                                                  "selections": (v30/*: any*/),
                                                  "type": "HelpLayoutHeroElement",
                                                  "abstractKey": null
                                                }
                                              ],
                                              "args": null,
                                              "argumentDefinitions": []
                                            },
                                            {
                                              "kind": "InlineDataFragmentSpread",
                                              "name": "useLayoutServiceAnnouncementElementData",
                                              "selections": (v31/*: any*/),
                                              "args": null,
                                              "argumentDefinitions": []
                                            },
                                            {
                                              "kind": "InlineDataFragmentSpread",
                                              "name": "useLayoutServiceTopicsElementData",
                                              "selections": (v31/*: any*/),
                                              "args": null,
                                              "argumentDefinitions": []
                                            },
                                            {
                                              "kind": "InlineDataFragmentSpread",
                                              "name": "useLayoutServiceListOfPortalsElementData",
                                              "selections": (v31/*: any*/),
                                              "args": null,
                                              "argumentDefinitions": []
                                            },
                                            {
                                              "kind": "InlineDataFragmentSpread",
                                              "name": "useLayoutServiceSuggestedRequestFormsElementData",
                                              "selections": (v31/*: any*/),
                                              "args": null,
                                              "argumentDefinitions": []
                                            },
                                            {
                                              "kind": "InlineDataFragmentSpread",
                                              "name": "useLayoutServiceForgeElementData",
                                              "selections": [
                                                {
                                                  "kind": "InlineFragment",
                                                  "selections": (v30/*: any*/),
                                                  "type": "HelpLayoutForgeElement",
                                                  "abstractKey": null
                                                }
                                              ],
                                              "args": null,
                                              "argumentDefinitions": []
                                            },
                                            {
                                              "kind": "InlineDataFragmentSpread",
                                              "name": "useLayoutServiceConnectElementData",
                                              "selections": [
                                                {
                                                  "kind": "InlineFragment",
                                                  "selections": (v30/*: any*/),
                                                  "type": "HelpLayoutConnectElement",
                                                  "abstractKey": null
                                                }
                                              ],
                                              "args": null,
                                              "argumentDefinitions": []
                                            }
                                          ],
                                          "storageKey": null
                                        },
                                        (v19/*: any*/)
                                      ],
                                      "storageKey": null
                                    },
                                    (v19/*: any*/)
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        (v33/*: any*/)
                      ],
                      "args": null,
                      "argumentDefinitions": []
                    }
                  ],
                  "type": "HelpLayout",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            },
            "action": "THROW",
            "path": "helpLayout.layout"
          },
          (v40/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "elementTypes",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "elementsCatalogueFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "editHomepageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "HelpCenterQueryApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "helpCenterById",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "HelpCenterBranding",
                    "kind": "LinkedField",
                    "name": "helpCenterBranding",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "HelpCenterBrandingColors",
                        "kind": "LinkedField",
                        "name": "colors",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "primary",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "HelpCenterLogo",
                        "kind": "LinkedField",
                        "name": "logo",
                        "plural": false,
                        "selections": (v41/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "HelpCenter",
                "abstractKey": null
              },
              (v43/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "HelpCenterMediaConfig",
            "kind": "LinkedField",
            "name": "mediaConfig",
            "plural": false,
            "selections": (v39/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "HelpLayoutQueryApi",
        "kind": "LinkedField",
        "name": "helpLayout",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "layout",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v42/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "HelpLayoutSectionConnection",
                    "kind": "LinkedField",
                    "name": "sections",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "HelpLayoutSectionEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "HelpLayoutSection",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "HelpLayoutSubsection",
                                "kind": "LinkedField",
                                "name": "subsections",
                                "plural": true,
                                "selections": [
                                  (v7/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "elements",
                                    "plural": true,
                                    "selections": [
                                      (v5/*: any*/),
                                      {
                                        "kind": "TypeDiscriminator",
                                        "abstractKey": "__isHelpLayoutElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          (v21/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "HelpLayoutAtomicElementType",
                                            "kind": "LinkedField",
                                            "name": "elementType",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "alias": null,
                                                "args": (v34/*: any*/),
                                                "concreteType": "HelpLayoutMediaConfig",
                                                "kind": "LinkedField",
                                                "name": "mediaConfig",
                                                "plural": false,
                                                "selections": [
                                                  (v37/*: any*/),
                                                  (v36/*: any*/),
                                                  (v35/*: any*/),
                                                  (v38/*: any*/)
                                                ],
                                                "storageKey": null
                                              },
                                              (v44/*: any*/)
                                            ],
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "HelpLayoutVisualConfig",
                                            "kind": "LinkedField",
                                            "name": "visualConfig",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "HelpLayoutAlignmentSettings",
                                                "kind": "LinkedField",
                                                "name": "alignment",
                                                "plural": false,
                                                "selections": [
                                                  (v10/*: any*/),
                                                  (v11/*: any*/),
                                                  (v33/*: any*/)
                                                ],
                                                "storageKey": null
                                              },
                                              (v15/*: any*/),
                                              (v16/*: any*/),
                                              (v17/*: any*/)
                                            ],
                                            "storageKey": null
                                          },
                                          (v33/*: any*/)
                                        ],
                                        "type": "HelpLayoutEditorElement",
                                        "abstractKey": null
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "HelpLayoutPortalCard",
                                            "kind": "LinkedField",
                                            "name": "portals",
                                            "plural": true,
                                            "selections": [
                                              (v45/*: any*/),
                                              {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "description",
                                                "storageKey": null
                                              },
                                              (v46/*: any*/),
                                              (v47/*: any*/),
                                              {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "isFeatured",
                                                "storageKey": null
                                              }
                                            ],
                                            "storageKey": null
                                          },
                                          (v48/*: any*/),
                                          (v49/*: any*/)
                                        ],
                                        "type": "HelpLayoutPortalsListElement",
                                        "abstractKey": null
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "HelpLayoutRequestForm",
                                            "kind": "LinkedField",
                                            "name": "suggestedRequestTypes",
                                            "plural": true,
                                            "selections": [
                                              {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "descriptionHtml",
                                                "storageKey": null
                                              },
                                              {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "portalName",
                                                "storageKey": null
                                              },
                                              (v42/*: any*/),
                                              (v47/*: any*/),
                                              (v45/*: any*/),
                                              {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "iconUrl",
                                                "storageKey": null
                                              }
                                            ],
                                            "storageKey": null
                                          },
                                          (v48/*: any*/)
                                        ],
                                        "type": "HelpLayoutSuggestedRequestFormsListElement",
                                        "abstractKey": null
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "header",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "message",
                                            "storageKey": null
                                          },
                                          (v48/*: any*/)
                                        ],
                                        "type": "HelpLayoutAnnouncementElement",
                                        "abstractKey": null
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "HelpLayoutTopic",
                                            "kind": "LinkedField",
                                            "name": "topics",
                                            "plural": true,
                                            "selections": [
                                              {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "topicId",
                                                "storageKey": null
                                              },
                                              (v17/*: any*/),
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "HelpLayoutTopicItem",
                                                "kind": "LinkedField",
                                                "name": "items",
                                                "plural": true,
                                                "selections": [
                                                  (v5/*: any*/),
                                                  {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "title",
                                                    "storageKey": null
                                                  },
                                                  (v46/*: any*/),
                                                  {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "displayLink",
                                                    "storageKey": null
                                                  },
                                                  {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "entityKey",
                                                    "storageKey": null
                                                  },
                                                  {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "helpObjectType",
                                                    "storageKey": null
                                                  },
                                                  (v33/*: any*/)
                                                ],
                                                "storageKey": null
                                              },
                                              {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "topicName",
                                                "storageKey": null
                                              }
                                            ],
                                            "storageKey": null
                                          },
                                          (v48/*: any*/)
                                        ],
                                        "type": "HelpLayoutTopicsListElement",
                                        "abstractKey": null
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "homePageTitle",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "userLanguageTag",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "HelpLayoutVisualConfig",
                                            "kind": "LinkedField",
                                            "name": "visualConfig",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "foregroundColor",
                                                "storageKey": null
                                              },
                                              (v12/*: any*/),
                                              (v15/*: any*/),
                                              (v16/*: any*/),
                                              (v17/*: any*/)
                                            ],
                                            "storageKey": null
                                          },
                                          (v48/*: any*/)
                                        ],
                                        "type": "HelpLayoutHeroElement",
                                        "abstractKey": null
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "forgeElementType",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "forgeElementPage",
                                            "storageKey": null
                                          },
                                          (v48/*: any*/),
                                          (v49/*: any*/)
                                        ],
                                        "type": "HelpLayoutForgeElement",
                                        "abstractKey": null
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "connectElementPage",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "connectElementType",
                                            "storageKey": null
                                          },
                                          (v48/*: any*/),
                                          (v49/*: any*/)
                                        ],
                                        "type": "HelpLayoutConnectElement",
                                        "abstractKey": null
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "identifier",
                                            "storageKey": null
                                          }
                                        ],
                                        "type": "QueryError",
                                        "abstractKey": null
                                      },
                                      (v33/*: any*/),
                                      (v43/*: any*/),
                                      (v50/*: any*/),
                                      (v51/*: any*/),
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          (v24/*: any*/)
                                        ],
                                        "type": "HelpLayoutSearchAtomicElement",
                                        "abstractKey": null
                                      },
                                      (v52/*: any*/),
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          (v29/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": null,
                                            "kind": "LinkedField",
                                            "name": "children",
                                            "plural": true,
                                            "selections": [
                                              (v5/*: any*/),
                                              {
                                                "kind": "TypeDiscriminator",
                                                "abstractKey": "__isHelpLayoutAtomicElement"
                                              },
                                              (v50/*: any*/),
                                              (v52/*: any*/),
                                              (v51/*: any*/),
                                              (v43/*: any*/)
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "type": "HelpLayoutLinkCardCompositeElement",
                                        "abstractKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  (v42/*: any*/),
                                  (v49/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "HelpLayoutVisualConfig",
                                        "kind": "LinkedField",
                                        "name": "visualConfig",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "HelpLayoutAlignmentSettings",
                                            "kind": "LinkedField",
                                            "name": "alignment",
                                            "plural": false,
                                            "selections": [
                                              (v33/*: any*/)
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "type": "HelpLayoutVisualEntity",
                                    "abstractKey": "__isHelpLayoutVisualEntity"
                                  },
                                  (v33/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "HelpLayoutVisualConfig",
                                "kind": "LinkedField",
                                "name": "visualConfig",
                                "plural": false,
                                "selections": [
                                  (v16/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "HelpLayoutBackgroundImage",
                                    "kind": "LinkedField",
                                    "name": "backgroundImage",
                                    "plural": false,
                                    "selections": (v41/*: any*/),
                                    "storageKey": null
                                  },
                                  (v17/*: any*/),
                                  (v12/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v42/*: any*/),
                              {
                                "kind": "ClientExtension",
                                "selections": [
                                  {
                                    "alias": "dataId",
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "__id",
                                    "storageKey": null
                                  },
                                  (v32/*: any*/)
                                ]
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v33/*: any*/)
                ],
                "type": "HelpLayout",
                "abstractKey": null
              },
              (v43/*: any*/)
            ],
            "storageKey": null
          },
          (v40/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "elementTypes",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              {
                "kind": "TypeDiscriminator",
                "abstractKey": "__isHelpLayoutElementType"
              },
              (v44/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": "atomicKey",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "key",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v34/*: any*/),
                    "concreteType": "HelpLayoutMediaConfig",
                    "kind": "LinkedField",
                    "name": "mediaConfig",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v37/*: any*/),
                      (v36/*: any*/),
                      (v35/*: any*/),
                      (v38/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "HelpLayoutAtomicElementType",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": "compositeKey",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "key",
                    "storageKey": null
                  }
                ],
                "type": "HelpLayoutCompositeElementType",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "9095aa11dcbca516c1a7fe90f4cc87987ef5c3e05cf18a479a9090c42fe9ad4b",
    "metadata": {},
    "name": "editHomepageQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "826e1f151f2c931c855bc4dd14afdcd5";

export default node;
