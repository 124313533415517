/**
 * DO NOT EDIT.
 *
 * This is Relay generated artifact.
 *
 * Please reach out to #jsm-cloud-megatron for any questions.
 *
 * @generated SignedSource<<5807f565cc3616e77a499c6de7db6010>>
 * @relayHash a8a171826f2fdaf1903a63b079e503a7
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID dd00f96dda042b7d9c13b2053ddbbcd6836dcb5b4a0b935973fadf5f0926a291

import type { ConcreteRequest, Query } from 'relay-runtime';
export type logoQuery$variables = {
  cloudId: string;
  helpCenterAri: string;
  skip: boolean;
};
export type logoQuery$data = {
  readonly helpCenter?: {
    readonly helpCenterById: {
      readonly helpCenterBranding?: {
        readonly logo: {
          readonly url: string | null | undefined;
        };
      };
      readonly name?: {
        readonly default: string;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type logoQuery = {
  response: logoQuery$data;
  variables: logoQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "helpCenterAri"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "helpCenterAri",
    "variableName": "helpCenterAri"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "HelpCenterName",
  "kind": "LinkedField",
  "name": "name",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "default",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "HelpCenterLogo",
  "kind": "LinkedField",
  "name": "logo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "logoQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "HelpCenterQueryApi",
            "kind": "LinkedField",
            "name": "helpCenter",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": null,
                "kind": "LinkedField",
                "name": "helpCenterById",
                "plural": false,
                "selections": [
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v3/*: any*/),
                      {
                        "kind": "RequiredField",
                        "field": {
                          "alias": null,
                          "args": null,
                          "concreteType": "HelpCenterBranding",
                          "kind": "LinkedField",
                          "name": "helpCenterBranding",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "RequiredField",
                              "field": (v4/*: any*/),
                              "action": "THROW",
                              "path": "helpCenter.helpCenterById.helpCenterBranding.logo"
                            }
                          ],
                          "storageKey": null
                        },
                        "action": "THROW",
                        "path": "helpCenter.helpCenterById.helpCenterBranding"
                      }
                    ],
                    "type": "HelpCenter",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "logoQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "HelpCenterQueryApi",
            "kind": "LinkedField",
            "name": "helpCenter",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": null,
                "kind": "LinkedField",
                "name": "helpCenterById",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "HelpCenterBranding",
                        "kind": "LinkedField",
                        "name": "helpCenterBranding",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "HelpCenter",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      }
                    ],
                    "type": "Node",
                    "abstractKey": "__isNode"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "id": "dd00f96dda042b7d9c13b2053ddbbcd6836dcb5b4a0b935973fadf5f0926a291",
    "metadata": {},
    "name": "logoQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "7a857f894a8b329e6ca429659e76b00d";

export default node;
