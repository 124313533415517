import type { PaperAppearance } from 'view/layout/paper';
import { PortalHome } from 'view/portal-home';
import { createRouteWithLayout } from '@atlassian/help-center-common-util/resource-router';

const route = {
    name: 'portal-home',
    path: `/portal/:portalId/:group?/:requestGroupId?/:create?/:requestTypeId?`,
    exact: true,
    component: PortalHome,
};

const paperAppearance: PaperAppearance | undefined = 'xxwide';

const layoutProps = {
    paperAppearance,
    fadeInSearchModal: true,
};

export const portalHomeRoute = createRouteWithLayout(route, undefined, layoutProps);
