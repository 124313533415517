import type { Reducer } from 'react';
import { useEffect, useReducer } from 'react';
import { isJsmVirtualAgentOnHelpcenterEnabled } from '@helpCenter/feature-flags';
import { di } from 'react-magnetic-di';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { OPERATIONAL_EVENT_TYPE } from '@atlassian/analytics-web-react';
import { fetchJson } from '@atlassian/help-center-common-util/fetch';
import type { Action, State } from './types';

const initialState: State = {
    isVAOnHCAvailable: false,
    isLoading: true,
};

const reducer: Reducer<State, Action> = (state, action) => {
    switch (action.type) {
        case 'RESET': {
            return {
                isVAOnHCAvailable: false,
                isLoading: false,
            };
        }
        case 'SET_AVAILABILITY': {
            return {
                isVAOnHCAvailable: action.isVAOnHCAvailable,
                isLoading: false,
            };
        }
        default: {
            return state;
        }
    }
};

interface VirtualAgentFeaturesResponse {
    isVirtualAgentAvailable: boolean;
    isAiEnabledInAdminHub: boolean;
}

export const useVirtualAgentOnHelpCenterAvailability = ({ cloudId }: { cloudId: string }) => {
    di(fetchJson, useAnalyticsEvents);
    const [{ isVAOnHCAvailable, isLoading }, dispatch] = useReducer(reducer, initialState);
    const { createAnalyticsEvent } = useAnalyticsEvents();

    useEffect(() => {
        if (!isJsmVirtualAgentOnHelpcenterEnabled()) {
            dispatch({ type: 'RESET' });
            return;
        }

        fetchJson<VirtualAgentFeaturesResponse>(
            `/gateway/api/jsm/virtual-agent/cloudId/${encodeURIComponent(cloudId)}/public/api/v1/va-features`,
            { headers: { 'Content-Type': 'application/json' } }
        )
            .then((response: VirtualAgentFeaturesResponse) => {
                dispatch({
                    type: 'SET_AVAILABILITY',
                    isVAOnHCAvailable: response.isVirtualAgentAvailable && response.isAiEnabledInAdminHub,
                });
            })
            .catch((err: Error) => {
                dispatch({
                    type: 'SET_AVAILABILITY',
                    isVAOnHCAvailable: false,
                });

                if ('status' in err && typeof err.status === 'number' && err.status === 403) {
                    return;
                }

                createAnalyticsEvent({
                    analyticsType: OPERATIONAL_EVENT_TYPE,
                    action: 'failed',
                    actionSubject: 'virtualAgentOnHelpCenterAvailability',
                    attributes: {
                        error: err.message,
                    },
                }).fire();
            });
    }, [createAnalyticsEvent, cloudId]);

    return {
        isVAOnHCAvailable,
        isLoading,
    };
};
