import type { ComponentType } from 'react';
import React, { useLayoutEffect } from 'react';
import { usePathParam, useQueryParam } from 'react-resource-router';
import { useHandOffConversation } from 'unified-help-center-alpha/src/controllers/routing';
import { useActions as useRoutingActions } from 'unified-help-center-alpha/src/store/routing';
import { withRoutingStateContainer } from 'unified-help-center-alpha/src/store/routing/container-wrapper';
import { getCloudId } from '@atlassian/help-center-common-util/meta';
import { useVirtualAgentConversationV2Provider } from '../../services/conversation-service';
import { ExternalSource } from '../../services/conversation-service/types';
import { ConversationStateContainer } from './container';
import { useActions as useVirtualAgentActions } from '.';

export const withHelpCenterConversationStateContainer = <TProps extends object>(
    WrappedComponent: ComponentType<TProps>
) => {
    const WithConversationStateContainer: React.FC<TProps & { contextId: string }> = (props) => {
        const { contextId } = props;
        const [isTestConversationParam] = useQueryParam('isTestConversation');
        const [portalId] = usePathParam('portalId');
        const isTestConversation = isTestConversationParam === 'true';

        const { handleHandOff } = useHandOffConversation();
        const conversationProvider = useVirtualAgentConversationV2Provider({
            isTestConversation,
            contextId,
            cloudId: getCloudId(),
            externalSource: portalId ? ExternalSource.Portal : ExternalSource.Orchestrator,
        });

        return (
            <ConversationStateContainer
                conversationProvider={conversationProvider}
                contextId={contextId}
                handleHandOff={handleHandOff}
                isTestConversation={isTestConversation}
                isGlobal
            >
                <GlobalConversationStateManager contextId={contextId} />
                <WrappedComponent {...props} />
            </ConversationStateContainer>
        );
    };

    return withRoutingStateContainer(WithConversationStateContainer);
};

/*
 * With react-sweet-state, we can not persistence the state of the store when there is no consumer, in this case, we have to
 * use a global state to preserve the state of the store when the consumer is unmounted, and use this component to reset
 * the state of the store when the contextId is changed.
 */
const GlobalConversationStateManager = ({ contextId }: { contextId: string }) => {
    const { resetStateOnContextIdChanged } = useRoutingActions();
    const { resetStateOnContextIdChanged: resetVAStateOnContextIdChanged } = useVirtualAgentActions();

    useLayoutEffect(() => {
        resetStateOnContextIdChanged();
        resetVAStateOnContextIdChanged();
    }, [contextId, resetStateOnContextIdChanged, resetVAStateOnContextIdChanged]);

    return null;
};
