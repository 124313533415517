import { getBaseName } from '@atlassian/help-center-common-util/history';
import { createURIObject } from '../urijs';

const hasPushState = (): boolean => {
    try {
        return localStorage.getItem('cv-hash-mode') === 'true' ? false : !!window.history.pushState;
    } catch (err) {
        return !!window.history.pushState;
    }
};

export const correctedHashModeReplaceUrl = (url?: string): string => {
    if (!url) {
        return '';
    }

    const pushState = hasPushState();
    const uri = createURIObject(url);
    const origFragment = uri.fragment();
    if (pushState && origFragment) {
        return `${getBaseName()}/${origFragment}`;
    }
    if (!pushState && !origFragment) {
        const frag = url.replace(`${getBaseName()}/`, '');
        const newUrl = createURIObject(getBaseName());

        // add the hash
        newUrl.hash(frag);

        // The headless query param needs to be passed through to the server, so if present we need to attach it
        // to the url rather than the hash.
        if (frag.includes('tour=true')) {
            newUrl.addQuery('tour', 'true');
        }
        if (frag.includes('headless=true')) {
            newUrl.addQuery('headless', 'true');
        }

        return newUrl.href();
    }
    // Return falsey response, indicating nothing to replace
    return '';
};
