import { getIsPageServerSideRendered } from 'util/env';
import { Environment, Network, RecordSource, Store } from 'relay-runtime';
import type { Variables, RequestParameters, CacheConfig, UploadableMap } from 'relay-runtime';
import fetchGraphQL from '../fetch';
import fetchGraphQLWithObservable from '../fetch-v2';

const fetchRelay = (
    params: RequestParameters,
    variables: Variables,
    cacheConfig: CacheConfig,
    uploadables: UploadableMap
) => {
    const fetcher = __SERVER__ || getIsPageServerSideRendered() ? fetchGraphQLWithObservable : fetchGraphQL;

    return fetcher(params, variables, cacheConfig, uploadables);
};

// Export a singleton instance of Relay Environment configured with our network function:
export default new Environment({
    network: Network.create(fetchRelay),
    store: new Store(new RecordSource()),
});
