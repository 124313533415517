import * as React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import * as fonts from 'view/styles/fonts';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { ExternalThemedLink } from '@atlassian/help-center-common-component/external-themed-link';
import { ThemedLink } from '@atlassian/help-center-common-component/themed-link';

interface Props {
    to: string;
    name: string | undefined; // If the name is not available then the crumb (and all its children) will not render
    isFirstCrumb?: boolean;
    actionSubjectId: string;
    testId?: string;
}

/**
 * This is a nestable bread crumb component, it will render the first component with a valid name.
 * It itself will also not render if the name is not valid.
 */
const BreadCrumbDumb: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
    name,
    to,
    children,
    isFirstCrumb,
    actionSubjectId,
    testId,
}) => {
    if (!name) {
        return null;
    }

    let child: React.ReactElement<Props> | undefined;
    React.Children.forEach(children, (element: React.ReactElement<Props>) => {
        // Short circuit if there is already a match
        if (child) {
            return;
        }

        if (element && React.isValidElement(element)) {
            const { name: childName } = element.props;

            if (childName) {
                child = element;
            }
        }
    });
    let linkComponent;
    if (to.startsWith('/')) {
        linkComponent = (
            <RootLink
                to={to}
                actionSubjectId={actionSubjectId}
                data-test-id={testId}
                aria-current={child ? 'false' : 'page'}
            >
                {name}
            </RootLink>
        );
    } else {
        linkComponent = (
            <ExternalLink href={to} data-test-id={testId} actionSubjectId={actionSubjectId}>
                {name}
            </ExternalLink>
        );
    }

    return (
        <>
            {!isFirstCrumb && <LinkSeperator>{'/'}</LinkSeperator>}
            {linkComponent}
            {child}
        </>
    );
};

export default BreadCrumbDumb;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const RootLink = styled(ThemedLink)`
    ${fonts.h200};
    margin: 0;
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const ExternalLink = styled(ExternalThemedLink)`
    ${fonts.h200};
    margin: 0;
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const LinkSeperator = styled.span`
    color: ${token('color.text', colors.N800)};
    padding: 0 ${token('space.100', '8px')};
`;
