/**
 * DO NOT EDIT.
 *
 * This is Relay generated artifact.
 *
 * Please reach out to #jsm-cloud-megatron for any questions.
 *
 * @generated SignedSource<<38efba15a7e1a6f63090f1d80ba83e7f>>
 * @relayHash 9ce2ee19b116d174bce16b376ad29d76
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID f7dca8883a5b9433afaf6c3452417e393272da9b7a917bf35d16a5d29db782c6

import type { ConcreteRequest, Query } from 'relay-runtime';
export type providerAuthStatusQuery$variables = {
  cloudId: string;
};
export type providerAuthStatusQuery$data = {
  readonly helpObjectStore: {
    readonly providerAuthStatuses: {
      readonly __typename: "HelpObjectStoreProviderAuthStatuses";
      readonly providerAuthStatuses: ReadonlyArray<{
        readonly authUrl: string | null | undefined;
        readonly isAuthRequired: boolean;
        readonly provider: string;
      } | null | undefined>;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    } | null | undefined;
  } | null | undefined;
};
export type providerAuthStatusQuery = {
  response: providerAuthStatusQuery$data;
  variables: providerAuthStatusQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "HelpObjectStoreProviderAuthStatus",
  "kind": "LinkedField",
  "name": "providerAuthStatuses",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "provider",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "authUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isAuthRequired",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "providerAuthStatusQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "HelpObjectStoreQueryApi",
        "kind": "LinkedField",
        "name": "helpObjectStore",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "providerAuthStatuses",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "type": "HelpObjectStoreProviderAuthStatuses",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "providerAuthStatusQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "HelpObjectStoreQueryApi",
        "kind": "LinkedField",
        "name": "helpObjectStore",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "providerAuthStatuses",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/)
                ],
                "type": "HelpObjectStoreProviderAuthStatuses",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "f7dca8883a5b9433afaf6c3452417e393272da9b7a917bf35d16a5d29db782c6",
    "metadata": {},
    "name": "providerAuthStatusQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "82b064d1e77ab9e91d6d44adce215165";

export default node;
