import { createRelayResource } from '@atlassian/help-center-common-util/resource-router';
import { NUMBER_OF_HELP_CENTERS_INITIAL_FETCH, SORT_ORDER } from '../../constants';
import type { helpCentersManagementPageQuery } from './__generated__/helpCentersManagementPageQuery.graphql';
import HelpCentersManagementPageQuery from './__generated__/helpCentersManagementPageQuery.graphql';

export const HelpCentersManagementResource = createRelayResource<helpCentersManagementPageQuery>({
    type: 'HELP_CENTERS_MANAGEMENT',
    getQuery: (_, { cloudId, workspaceAri }) => {
        return {
            parameters: HelpCentersManagementPageQuery,
            variables: {
                // Suppressing existing violation. Please fix this.
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                cloudId,
                // Suppressing existing violation. Please fix this.
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                workspaceAri,
                SORT_ORDER,
                NUMBER_OF_HELP_CENTERS_INITIAL_FETCH,
            },
            options: {
                fetchPolicy: 'store-or-network',
            },
        };
    },
});
