import * as React from 'react';
import type { InjectedIntlProps } from 'react-intl';
import { injectIntl } from 'react-intl';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import type { InjectedProps as PathParamProps } from '@atlassian/help-center-common-component/with-path-params';
import { getHelpCenterUrl } from '@atlassian/help-center-common-util/url';

import { BreadCrumb } from '../bread-crumb-dumb';
import messages from './messages';

interface SelectProps {
    sharedPortalName: string;
    portalName?: string | undefined;
    categoryName?: string | undefined;
    articleTitle?: string | undefined;
    requestKey?: string | undefined;
    requestUrl?: string | undefined;
    showRequests?: boolean;
}

interface AdditionalBreadCrumbsProps {
    additionalBreadCrumbs?: React.ReactElement[];
}

export type ExternalProps = PathParamProps;

export const BreadCrumbsDumb: React.FunctionComponent<
    SelectProps & ExternalProps & AdditionalBreadCrumbsProps & InjectedIntlProps
> = ({
    sharedPortalName,
    portalName,
    categoryName,
    articleTitle,
    portalId,
    categoryId,
    articleId,
    showRequests,
    intl,
    requestKey,
    requestUrl,
    additionalBreadCrumbs,
}) => {
    const requestCreateBreadcrumb = showRequests ? intl.formatMessage(messages.raiseRequestBreadcrumb) : undefined;
    return (
        <BreadCrumbsRoot aria-label={intl.formatMessage(messages.breadcrumbsLabel)}>
            <BreadCrumb
                to={getHelpCenterUrl()}
                name={sharedPortalName}
                isFirstCrumb
                actionSubjectId="helpCenterBreadcrumb"
            >
                {/*TypeScript upgrade (v4.4.3). Please correct when you revisit this code. Please correct when this code is revisited.*/}
                {/*eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
                <BreadCrumb to={`/portal/${portalId}`} name={portalName} actionSubjectId="portalBreadcrumb">
                    <BreadCrumb
                        to={requestUrl || ''}
                        name={requestKey}
                        actionSubjectId="requestBreadcrumb"
                        testId="requestKey"
                    />
                    <BreadCrumb
                        // TypeScript upgrade (v4.4.3). Please correct when you revisit this code. Please correct when this code is revisited.
                        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                        to={`/portal/${portalId}/group/-1`}
                        name={requestCreateBreadcrumb}
                        actionSubjectId="requestCreateBreadcrumb"
                    />
                    <BreadCrumb
                        // TypeScript upgrade (v4.4.3). Please correct when you revisit this code. Please correct when this code is revisited.
                        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                        to={`/portal/${portalId}/topic/${categoryId}`}
                        name={categoryName}
                        actionSubjectId="categoryBreadcrumb"
                    >
                        <BreadCrumb
                            // TypeScript upgrade (v4.4.3). Please correct when you revisit this code. Please correct when this code is revisited.
                            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                            to={`/portal/${portalId}/topic/${categoryId}/article/${articleId}`}
                            name={articleTitle}
                            actionSubjectId="articleBreadcrumb"
                        />
                    </BreadCrumb>
                    <BreadCrumb
                        // TypeScript upgrade (v4.4.3). Please correct when you revisit this code. Please correct when this code is revisited.
                        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                        to={`/portal/${portalId}/article/${articleId}`}
                        name={articleTitle}
                        actionSubjectId="articleBreadcrumb"
                    />
                </BreadCrumb>
                {/*TypeScript upgrade (v4.4.3). Please correct when you revisit this code. Please correct when this code is revisited.*/}
                {/*eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
                <BreadCrumb to={`/article/${articleId}`} name={articleTitle} actionSubjectId="articleBreadcrumb" />
                {additionalBreadCrumbs?.map((breadCrumb) => breadCrumb)}
            </BreadCrumb>
        </BreadCrumbsRoot>
    );
};

export default injectIntl(BreadCrumbsDumb);

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const BreadCrumbsRoot = styled.nav`
    word-wrap: break-word;
`;
