import * as React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import { noop } from 'lodash';

import { useIntl } from 'react-intl-next';
import Avatar from '@atlaskit/avatar';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import messages from './messages';

export interface UserAvatarProps {
    avatarSrc?: string;
    isExpanded?: boolean;
    disableAvatarMenu?: boolean;
}

const UserAvatar: React.FunctionComponent<UserAvatarProps> = ({ avatarSrc, isExpanded, disableAvatarMenu }) => {
    const { formatMessage } = useIntl();
    const avatarRef = React.useRef<HTMLDivElement>(null);
    // This is because the current avatar is not accepting aria props and also label does not change dynamically
    React.useEffect(() => {
        if (!avatarRef.current) {
            return;
        }
        avatarRef.current.setAttribute('aria-expanded', isExpanded ? 'true' : 'false');
    }, [isExpanded]);

    return (
        <UserAvatarContainer data-test-id="user-avatar-container">
            <Avatar
                onClick={noop}
                size="medium"
                isDisabled={disableAvatarMenu}
                appearance="circle"
                src={avatarSrc}
                borderColor={token('color.border.inverse', colors.N0)}
                name={formatMessage(messages.yourProfileAndSettings)}
                ref={avatarRef}
            />
        </UserAvatarContainer>
    );
};
export default UserAvatar;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const UserAvatarContainer = styled.span`
    border-radius: 50%;
    display: flex;
    overflow: visible;
    position: relative;
`;
