import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import type { RouteContext } from 'react-resource-router';
import type { State } from 'state';
import { fetchPortalAction } from 'state/actions/portal';

import { fetchRequestDetailsModel, changeSubscription } from 'state/actions/request-details';
import { dismissRequestDetailsShowHideAnnouncement } from 'state/actions/user';
import { getRequestSpecificConnectAddonPanels } from 'state/selectors/connect-addon';
import { getName, getPortal } from 'state/selectors/portal';
import { getRequestDetails, getError } from 'state/selectors/request-details';
import { isAtlassianAccountManaged, isRequestDetailsShowHideAnnouncementVisible } from 'state/selectors/user';
import { withPathParams } from '@atlassian/help-center-common-component/with-path-params';
import { getIssueAnalyticsContext } from '@atlassian/help-center-common-util/analytics/issue-context';
import { toNumber } from '@atlassian/help-center-common-util/number';
import RequestDetailsComponent from './request-details';

type ExternalProps = Omit<RouteContext, 'action'>;

const selector = createSelector(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    (state: State, props: ExternalProps) => getPortal(state, toNumber(props.match.params.portalId!)),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    (state: State, props: ExternalProps) => getRequestDetails(state, props.match.params.requestKey!),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    (state: State, props: ExternalProps) => getError(state, props.match.params.requestKey!),
    (state: State, props: ExternalProps) => getName(state, Number(props.match.params.portalId)),
    (state: State, props: ExternalProps) => getIssueAnalyticsContext(state, Number(props.match.params.portalId)),
    (state: State) => isRequestDetailsShowHideAnnouncementVisible(state),
    (state: State) => isAtlassianAccountManaged(state),
    (state: State, props: ExternalProps) =>
        getRequestSpecificConnectAddonPanels({
            state,
            location: 'detailsPanels',
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            requestKey: props.match.params.requestKey!,
        }),
    (
        portal,
        requestDetails,
        persistedError,
        portalName,
        issueAnalyticsContext,
        showHideAnnouncementVisible,
        isManagedAccount,
        connectPanels
    ) => ({
        portal,
        requestDetails,
        issueAnalyticsContext,
        portalName,
        showHideAnnouncementVisible,
        isAtlassianManagedAccount: isManagedAccount,
        ...persistedError,
        hasConnectPanels: connectPanels && connectPanels.length > 0,
        isLoading: requestDetails === undefined && persistedError === undefined,
    })
);

export default connect(selector, {
    fetchPortal: fetchPortalAction,
    fetchRequestDetails: fetchRequestDetailsModel,
    changeSubscriptionAction: changeSubscription,
    dismissShowHideAnnouncementAction: dismissRequestDetailsShowHideAnnouncement,
})(withPathParams(RequestDetailsComponent));
