import fetchModel from 'epics/model/fetcher';
import type { Epic } from 'epics/rxjs';
import { Observable } from 'epics/rxjs';

import type {
    FetchRequestDetailsAction,
    FetchRequestDetailsSuccess,
    FetchRequestDetailsFailure,
} from 'state/actions/request-details';
import {
    FETCH_REQUEST_DETAILS_MODEL,
    fetchRequestDetailsSuccess,
    fetchRequestDetailsFailure,
} from 'state/actions/request-details';
import type { ErrorResponse } from 'epics/model/types';

export const requestDetailsEpic: Epic<
    FetchRequestDetailsAction,
    FetchRequestDetailsSuccess | FetchRequestDetailsFailure
> = (action$) => {
    return action$.ofType(FETCH_REQUEST_DETAILS_MODEL).mergeMap((action) =>
        fetchModel(action.payload)
            .filter((response) => !!response.reqDetails && !!response.reqDetails.issue)
            .map((response) => {
                return fetchRequestDetailsSuccess(response.reqDetails!);
            })
            .catch((error: ErrorResponse) => {
                const key = action.payload.params.key;
                return Observable.of(fetchRequestDetailsFailure(key, error));
            })
    );
};
