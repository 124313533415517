import React, { useCallback } from 'react';
import type { ReactNode, KeyboardEvent, MouseEvent } from 'react';

type InteractiveElementProps = {
    tagName?: keyof HTMLElementTagNameMap;
    onClick?: (event?: MouseEvent) => void;
    children?: ReactNode;
} & React.HTMLAttributes<HTMLElement>;

const InteractiveElement: React.FC<InteractiveElementProps> = ({
    onClick,
    children,
    tagName: TagName = 'div',
    ...props
}) => {
    const handleKeyDown = useCallback(
        (event: KeyboardEvent) => {
            // Runs onClick on space and enter key press for accessibility
            if (event.key === ' ' || event.key === 'Enter') {
                event.preventDefault();
                onClick && onClick();
            }
        },
        [onClick]
    );

    return (
        <TagName tabIndex={0} role="button" onClick={onClick} onKeyDown={handleKeyDown} {...props}>
            {children}
        </TagName>
    );
};

export default InteractiveElement;
