import { useCallback, useMemo } from 'react';
import { fetchJson } from '@helpCenter/util/fetch/fetch';
import { di } from 'react-magnetic-di';
import { sendOperationalEvent, sendErrorEvent } from '@atlassian/help-center-virtual-agent/src/common/utils';
import type { GlobalInterenceApiResponse, IntentInferenceProvider } from '../../common/types';

export const useIntentInferenceProvider = ({
    cloudId,
    contextId,
}: {
    cloudId: string;
    contextId: string;
}): IntentInferenceProvider => {
    di(fetchJson, sendOperationalEvent, sendErrorEvent);

    const inferIntent = useCallback(
        async (message: string): Promise<GlobalInterenceApiResponse> => {
            try {
                const response = await fetchJson<GlobalInterenceApiResponse>(
                    `/gateway/api/jsm/virtual-agent/cloudId/${cloudId}/public/api/v1/global-inference/inferIntentFast`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            contextId,
                            message,
                        }),
                        method: 'POST',
                    }
                );

                // https://data-portal.internal.atlassian.com/analytics/registry/69954
                sendOperationalEvent({
                    actionSubject: 'inferIntent',
                    actionSubjectId: 'globalInferIntentFast',
                    attributes: {
                        contextId,
                    },
                });

                return response;
            } catch (error) {
                // https://data-portal.internal.atlassian.com/analytics/registry/69953
                sendErrorEvent({
                    error: Error(error),
                    actionSubject: 'inferIntent',
                    actionSubjectId: 'globalInferIntentFast',
                    attributes: {
                        contextId,
                    },
                });

                throw error;
            }
        },
        [contextId, cloudId]
    );

    return useMemo(() => ({ inferIntent }), [inferIntent]);
};
