import { defineMessages } from 'react-intl';

export default defineMessages({
    requests: {
        defaultMessage: 'Requests',
        description: 'Requests header in avatar drop-down menu',
        id: 'sd.customerview.dropdown.menu.header.title',
    },
    createdByMe: {
        defaultMessage: 'Created by me',
        description: 'Created by me link in avatar drop-down menu',
        id: 'sd.customerview.profile.dropdown.requests.menu.group.created.by.me',
    },
    all: {
        defaultMessage: 'All',
        description: 'All requests link in avatar drop-down menu',
        id: 'sd.customerview.profile.dropdown.requests.menu.all',
    },
    approvals: {
        defaultMessage: 'Approvals',
        description: 'Approvals link in avatar drop-down menu',
        id: 'sd.customerview.profile.dropdown.requests.menu.approvals',
    },
});
