import { useMemo } from 'react';
import isArray from 'lodash/isArray';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import toSafeInteger from 'lodash/toSafeInteger';
import qs from 'query-string';
import { useRouter } from 'react-resource-router';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { emphasizeAndEscapeText } from '@atlassian/help-center-common-component/result-base';

const getSelectedPortalsFromQueryParams = (queryStringParams: { portalIds: string }) => {
    const { portalIds: selectedPortalIds } = queryStringParams;

    if (!selectedPortalIds) {
        return [];
    }

    return Array.from(selectedPortalIds);
};

export const formatText = (text: string = '', term: string = '') =>
    emphasizeAndEscapeText(text, term)
        .replace(/@@@hl@@@/g, '<strong>')
        .replace(/@@@endhl@@@/g, '</strong>');

export const formatToCleanText = (text: string = '', term: string = '') =>
    emphasizeAndEscapeText(text, term)
        .replace(/@@@hl@@@/g, '')
        .replace(/@@@endhl@@@/g, '');

export const useQueryParams = () => {
    const [{ location }, _] = useRouter();
    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const queryStringParams = useMemo(() => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return qs.parse(location.search);
    }, [location.search]);
    const portalIds = useMemo(() => getSelectedPortalsFromQueryParams(queryStringParams), [queryStringParams]);
    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return { ...queryStringParams, portalIds };
};

export const isSinglePortalSearch = (portalId: string | number | undefined) =>
    portalId !== undefined && getPortalIdAsNumber(portalId) !== 0;

export const isValidSearchTerm = (term: string | undefined) => Boolean(term && term.trim() !== '');

export const getPortalIdAsNumber = (portalId: string | number | null | undefined) => {
    return toSafeInteger(portalId);
};

export const getIdsArray = (ids: string[] | string | number | null | undefined) => {
    if (ids) {
        if (isNumber(ids) || isString(ids)) {
            return [ids.toString()];
        }

        return isArray(ids) ? ids : [];
    }
    return [];
};

export const sendSearchUIAnalyticEvents = (
    eventProperties: {
        action: string;
        actionSubjectId?: string;
    } & Record<string, unknown>,
    createAnalyticsEvent?: CreateUIAnalyticsEvent,
    portalId?: string
) => {
    createAnalyticsEvent &&
        createAnalyticsEvent({
            analyticsType: 'UI',
            actionSubjectId: portalId ? 'singlePortal' : 'portalHomePage',
            ...eventProperties,
        }).fire();
};

export const sendSearchOperationalAnalyticEvents = (
    eventProperties: { action: string; actionSubjectId?: string } & Record<string, unknown>,
    createAnalyticsEvent?: CreateUIAnalyticsEvent,
    portalId?: string
) => {
    createAnalyticsEvent &&
        createAnalyticsEvent({
            analyticsType: 'OPERATIONAL',
            actionSubjectId: portalId ? 'singlePortal' : 'portalHomePage',
            ...eventProperties,
        }).fire();
};
