import * as React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled, { keyframes } from 'styled-components';
import { range } from 'lodash';
import * as grid from 'view/styles/grid';
import * as mixins from 'view/styles/mixins';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

interface LoadingSkeletonProps {
    height: number;
    backgroundColor: string;
}

// eslint-disable-next-line rulesdir/no-styled-export, rulesdir/styled-component-order, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const Container = styled.div`
    ${mixins.flexCenter};
    padding: ${token('space.100', '8px')};
    flex-direction: column;
    pointer-events: none;
    flex: 1 1 auto;
`;

export const AbsoluteFillContainer = Container.extend`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;

const fromPositionX = -400;
const toPositionX = 800;

// eslint-disable-next-line @atlaskit/design-system/no-keyframes-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const shimmer = keyframes`
    0% {
        background-position: ${fromPositionX}px 0;
    }
    100% {
        background-position: ${toPositionX}px 0;
    }
`;

/** Simple loading skeleton block with subtle animated white shimmering effect */
// eslint-disable-next-line rulesdir/no-styled-export, rulesdir/styled-component-order, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const LoadingSkeleton = styled.div<LoadingSkeletonProps>`
    ${mixins.borderRadius};

    height: ${(props) => `${props.height}px`};

    background-color: ${(props) => props.backgroundColor};
    background-image: linear-gradient(
        to right,
        ${(props) => props.backgroundColor} 10%,
        ${token('elevation.surface', colors.N0)} 40%,
        ${(props) => props.backgroundColor} 50%
    );
    background-repeat: no-repeat;

    animation-duration: 0.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${shimmer};
    animation-timing-function: ease-in;
`;

// eslint-disable-next-line rulesdir/styled-component-order, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const FieldSkeletonWrapper = styled.div`
    ${mixins.borderRadius};
    border: 2px solid;
    border-color: ${token('color.border', colors.N40)};
`;

export const FieldLoadingSkeleton = () => (
    <FieldSkeletonWrapper>
        <LoadingSkeleton height={150} backgroundColor={token('color.skeleton.subtle', colors.N10)} />
    </FieldSkeletonWrapper>
);

// eslint-disable-next-line rulesdir/styled-component-order, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const AvatarSkeletonWrapper = styled.div`
    min-width: ${grid.multiple(4.5).px};
    border-radius: 50%;
    overflow: hidden;
`;

export const AvatarLoadingSkeleton = () => (
    <AvatarSkeletonWrapper>
        <LoadingSkeleton height={36} backgroundColor={token('color.skeleton', colors.N30)} />
    </AvatarSkeletonWrapper>
);

// eslint-disable-next-line rulesdir/styled-component-order, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const TextFieldLoadingSkeletonWrapper = styled.div`
    margin-top: ${token('space.500', '40px')};
    margin-bottom: ${token('space.500', '40px')};
`;

// eslint-disable-next-line rulesdir/no-styled-export, rulesdir/styled-component-order, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const SkeletonContainer = styled.div`
    width: 100%;
`;

export const TextFieldLoadingSkeleton = ({ shortText }: { shortText: boolean }) => {
    return (
        <TextFieldLoadingSkeletonWrapper>
            <LoadingSkeleton
                height={shortText ? grid.multiple(5).unitless : grid.multiple(22).unitless}
                backgroundColor={token('color.skeleton.subtle', colors.N20)}
            />
        </TextFieldLoadingSkeletonWrapper>
    );
};

// 3 short text fields, 1 long text field, then another short text field
export const LoadingSkeletonFields = () => {
    return (
        <>
            <SkeletonContainer>
                {range(3).map((ndx) => (
                    <TextFieldLoadingSkeleton key={ndx} shortText />
                ))}
                <TextFieldLoadingSkeleton shortText={false} />
                <TextFieldLoadingSkeleton shortText />
            </SkeletonContainer>
        </>
    );
};
