import { defineMessages } from 'react-intl-next';

export default defineMessages({
    virtualAgentBannerHeading: {
        defaultMessage: 'Spend less time hunting for the right request form',
        description:
            'Heading in section message displayed when user is on the portal and virtual agent search is turned on.',
        id: 'virtual-agent.virtual-agent-banner-heading',
    },
    virtualAgentEAPBannerDescription: {
        defaultMessage: 'Ask any question and give our AI-powered virtual agent a try.',
        description:
            'Desription in section message displayed when user is on the portal and virtual agent search is turned on for EAP.',
        id: 'virtual-agent.virtual-agent-EAP-banner-description',
    },
    iconAlt: {
        defaultMessage: 'Atlassian Intelligence',
        description: 'Alternative text for Atlassian Intelligence logo.',
        id: 'virtual-agent.virtual-agent-banner-icon-alt',
    },
    buttonText: {
        defaultMessage: 'Chat with virtual agent',
        description: 'Text for button which will take the user to the virtual agent conversation page.',
        id: 'virtual-agent.virtual-agent-banner-button-text',
    },
});
