import type { DocNode } from '@atlaskit/adf-schema';
import { type Sources } from '@atlassian/conversation-assistant';

// eslint-disable-next-line no-shadow
export enum FeedbackType {
    POSITIVE = 'positive',
    NEGATIVE = 'negative',
}

export interface LoadingState {
    readonly keywords?: string[];
    readonly sources?: Sources;
}

// eslint-disable-next-line no-shadow
export enum ASSISTANT_MESSAGE_STATUS {
    IDLE = 'idle',
    LOADING_RESPONSE = 'loadingResponse',
    AWAITING_RESPONSE = 'awaitingResponse',
    TYPING_RESPONSE = 'typingResponse',
    ABORTED_RESPONSE = 'abortedResponse',
    SYNCED_RESPONSE = 'syncedResponse',
}

export interface MessageMetadata {
    messageLatency?: number;
    messageId?: string;
    messageFeedback?: {
        feedbackLabel: string;
        detailedFeedback?: string;
        feedbackType: FeedbackType;
    };
    [key: string]: unknown;
    providerAuthDetail?: ProviderAuthDetail;
    isFallbackMessage?: boolean;
    loadingState?: LoadingState;
    status?: ASSISTANT_MESSAGE_STATUS;
    showFeedback?: boolean;
    isFeedbackSkipped?: boolean;
    originalQuery?: string;
}

export interface HelpSeekerMessage {
    readonly userType: 'helpseeker';
    readonly messageContentAdf: DocNode;
    readonly userAccountId?: string | null;
    readonly messageMetadata: MessageMetadata;
}

export interface ProviderAuthDetail {
    readonly unauthorizedProvider: string;
    readonly authUrl: string;
}
