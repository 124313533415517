import type { Appendix } from '../../common/types';

export interface CreateVirtualAgentConversationResponse {
    id: string;
    timeCreated: string;
}

export interface VirtualAgentConversationMessageResponse {
    id: string;
    conversationState: 'open' | 'closed' | 'resolved' | 'escalated';
    message: {
        contentMimetype: 'text/markdown' | 'application/json+adf';
        appendices: Appendix[];
        content: string;
        authorType: 'AGENT';
    };
    conversationChannelId: string;
    timeCreated: string;
}

export const ExternalSource = {
    Portal: 'JSM_PORTAL',
    Orchestrator: 'JSM_ORCHESTRATOR',
} as const;

export type ExternalSource = (typeof ExternalSource)[keyof typeof ExternalSource];
