import { createActionsHook, createStateHook, createStore } from 'react-sweet-state';
import { decideProvider, setProvider, resetStateOnContextIdChanged } from './actions';
import { RoutingStateContainer } from './container';
import type { State } from './types';

const initialState: State = {
    contextId: '',
    isRouting: false,
};

const store = createStore({
    initialState,
    actions: {
        decideProvider,
        setProvider,
        resetStateOnContextIdChanged,
    },
    name: 'VIRTUAL_AGENT_ROUTING',
    containedBy: RoutingStateContainer,
    handlers: {
        onInit:
            () =>
            ({ setState }, { contextId }) => {
                setState({ contextId });
            },
    },
});

export const useProvider = createStateHook(store, {
    selector: (state) => state.provider,
});

export const useIsRouting = createStateHook(store, {
    selector: (state) => state.isRouting,
});

export const useActions = createActionsHook(store);
