import * as React from 'react';
import { FormattedMessage } from 'react-intl';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import * as grid from 'view/styles/grid';
import * as mixins from 'view/styles/mixins';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import { HtmlButton } from '@atlassian/help-center-common-component/analytics/html-button';
import { ThemedBadge } from '@atlassian/help-center-common-component/badges';
import messages from './messages';

export interface RequestsMenuButtonProps {
    totalRequests: number;
    isMenuOpen: boolean;
}

const RequestsMenuButton: React.FunctionComponent<RequestsMenuButtonProps> = ({ totalRequests, isMenuOpen }) => {
    return (
        <Button isMenuOpen={isMenuOpen} actionSubjectId="navbarRequests" aria-expanded={isMenuOpen}>
            {totalRequests > 0 && <ButtonBadge>{totalRequests}</ButtonBadge>}
            <FormattedMessage {...messages.requestsMenuTitle} />
        </Button>
    );
};

export default RequestsMenuButton;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const ButtonBadge = styled(ThemedBadge)`
    float: left;
    margin-right: ${token('space.100', '8px')};
`;

const BUTTON_OPACITY_TRANSITION_MS = 50;

const BORDER_SIZE = 2;
const BUTTON_HEIGHT = grid.unitless * 4.5;
const MENU_FONT_SIZE = 14;
interface RequestsMenuButtonDisplayProps {
    isMenuOpen: boolean;
}

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const Button = styled<React.ComponentProps<typeof HtmlButton> & RequestsMenuButtonDisplayProps>(
    ({ isMenuOpen, ...props }) => <HtmlButton {...props} />
)`
    ${mixins.focusRing.default};
    cursor: pointer;

    background-color: ${(props) => props.theme.color};
    padding: 0 ${token('space.150', '12px')};
    border: ${BORDER_SIZE}px ${token('color.border.inverse', colors.N0)} solid;
    border-radius: 20px;
    font-size: ${MENU_FONT_SIZE}px;
    height: ${BUTTON_HEIGHT}px;
    background-image: linear-gradient(
            to right,
            rgba(255, 255, 255, ${(props) => (props.isMenuOpen ? '0.6' : '0.8')}),
            rgba(255, 255, 255, ${(props) => (props.isMenuOpen ? '0.6' : '0.8')})
        ),
        linear-gradient(to right, ${(props) => props.theme.color}, ${(props) => props.theme.color});

    transition:
        background-color ${BUTTON_OPACITY_TRANSITION_MS}ms,
        transform ${BUTTON_OPACITY_TRANSITION_MS}ms;

    position: relative;

    display: flex;
    align-items: center;

    /* This is emulating the hover behaviour of the @atlaskit/avatar component. If it changes, change this too. */
    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 20px;
        background-color: ${token('color.interaction.hovered', colors.N200A)};
        opacity: 0;
        transition: opacity 200ms;
        pointer-events: none;
        box-sizing: border-box;
    }

    /* todo deduplicate */
    &:active {
        transform: scale(0.97);
        &::after {
            opacity: 0.6;
        }
    }

    &:hover {
        &::after {
            opacity: 0.6;
        }
    }
`;
