/**
 * DO NOT EDIT.
 *
 * This is Relay generated artifact.
 *
 * Please reach out to #jsm-cloud-megatron for any questions.
 *
 * @generated SignedSource<<a3286ba6c83751efde52f349a1c46dd2>>
 * @relayHash 91f2407517ac59611c7475a5d6f414f9
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID cc1da05aa437b7fc25b2b79333cce7d67bb82dcbf959e32c71a0fb56fd9e20e5

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type searchResultArticlesAndPortalsQuery$variables = {
  categoryIds?: ReadonlyArray<string> | null | undefined;
  cloudId: string;
  portalIds?: ReadonlyArray<string> | null | undefined;
  queryTerm: string;
  resultLimit?: number | null | undefined;
};
export type searchResultArticlesAndPortalsQuery$data = {
  readonly helpObjectStore: {
    readonly __typename: "HelpObjectStoreQueryApi";
    readonly " $fragmentSpreads": FragmentRefs<"articlesFragment" | "portalsFragment">;
  };
};
export type searchResultArticlesAndPortalsQuery = {
  response: searchResultArticlesAndPortalsQuery$data;
  variables: searchResultArticlesAndPortalsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "categoryIds"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "portalIds"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "queryTerm"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "resultLimit"
},
v5 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v6 = [
  (v5/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v8 = {
  "kind": "Variable",
  "name": "portalIds",
  "variableName": "portalIds"
},
v9 = {
  "kind": "Variable",
  "name": "queryTerm",
  "variableName": "queryTerm"
},
v10 = {
  "kind": "Variable",
  "name": "resultLimit",
  "variableName": "resultLimit"
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v12 = [
  (v7/*: any*/),
  {
    "kind": "InlineFragment",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isExternal",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "searchBackend",
        "storageKey": null
      },
      (v11/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "displayLink",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "searchAlgorithm",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "entityType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "iconUrl",
        "storageKey": null
      }
    ],
    "type": "HelpObjectStoreSearchResult",
    "abstractKey": null
  },
  {
    "kind": "InlineFragment",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "type": "HelpObjectStoreQueryError",
    "abstractKey": null
  },
  {
    "kind": "InlineFragment",
    "selections": [
      (v11/*: any*/)
    ],
    "type": "Node",
    "abstractKey": "__isNode"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "searchResultArticlesAndPortalsQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v6/*: any*/),
          "concreteType": "HelpObjectStoreQueryApi",
          "kind": "LinkedField",
          "name": "helpObjectStore",
          "plural": false,
          "selections": [
            (v7/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "articlesFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "portalsFragment"
            }
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "helpObjectStore"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "searchResultArticlesAndPortalsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "HelpObjectStoreQueryApi",
        "kind": "LinkedField",
        "name": "helpObjectStore",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          {
            "alias": "articles",
            "args": [
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "categoryIds",
                    "variableName": "categoryIds"
                  },
                  (v5/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "entityType",
                    "value": "ARTICLE"
                  },
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/)
                ],
                "kind": "ObjectValue",
                "name": "searchInput"
              }
            ],
            "concreteType": null,
            "kind": "LinkedField",
            "name": "searchHelpObjects",
            "plural": true,
            "selections": (v12/*: any*/),
            "storageKey": null
          },
          {
            "alias": "portals",
            "args": [
              {
                "fields": [
                  (v5/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "entityType",
                    "value": "PORTAL"
                  },
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/)
                ],
                "kind": "ObjectValue",
                "name": "searchInput"
              }
            ],
            "concreteType": null,
            "kind": "LinkedField",
            "name": "searchHelpObjects",
            "plural": true,
            "selections": (v12/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "cc1da05aa437b7fc25b2b79333cce7d67bb82dcbf959e32c71a0fb56fd9e20e5",
    "metadata": {},
    "name": "searchResultArticlesAndPortalsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "f4d3de0fdf5036d3041ae0729e2301d5";

export default node;
