import { defineMessages } from 'react-intl-next';

export default defineMessages({
    feedbackWidget: {
        defaultMessage: 'Give feedback',
        description: 'The text for give feedback button for help centers management screen',
        id: 'sidebar.feedback.widget',
    },
    backButton: {
        defaultMessage: 'Back to help center',
        description: 'The text for the back button in the help center management screen sidebar',
        id: 'sidebar.back.button',
    },
    backToDefaultHelpCenterButton: {
        defaultMessage: 'Back to default help center',
        description: 'The text for the back to default help center button in the help center management screen sidebar',
        id: 'sidebar.back.to.default.hc.button',
    },
    backIconLabel: {
        defaultMessage: 'Go back to help center',
        description: 'Label for back button icon',
        id: 'sidebar.back.icon',
    },
    sidebarNavigationLabel: {
        defaultMessage: 'Sidebar navigation',
        description: 'Label for sidebar navigation',
        id: 'sidebar.navigation.label',
    },
});
