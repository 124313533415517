import React from 'react';
import { styled } from '@compiled/react';
import { useIntl } from 'react-intl-next';
import { di } from 'react-magnetic-di';
import { BrandingPreviewBanner } from 'view/advanced-help-center-branding/branding-preview-banner';
import * as zIndex from 'view/styles/z-index';
import InlineMessage from '@atlaskit/inline-message';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { Button, LoadingButton } from '@atlassian/help-center-common-component/analytics';
import type { ButtonProps, LoadingButtonProps } from '@atlassian/help-center-common-component/analytics';
import FeedbackCollectorButton from '@atlassian/help-center-common-component/feedback-collector/feedback-collector-button';
import messages from './messages';

export interface ActionBarStatelessProps {
    isPageInBrandingPreviewMode?: boolean;
    isPublishing?: boolean;
    onPublish?: () => void;
    onClose?: () => void;
    isDisabled?: boolean;
    children?: React.ReactNode;
    isPageInPreviewMode?: boolean;
    togglePreviewMode?: () => void;
}

export const HELP_CENTERS_CUSTOMIZE_PAGE_LAYOUT = 'Help Centers customize page layout';

export const ActionBarStateless: React.FC<ActionBarStatelessProps> = ({
    isPageInBrandingPreviewMode,
    isPublishing,
    onPublish,
    onClose,
    isDisabled,
    children,
    isPageInPreviewMode,
    togglePreviewMode,
}) => {
    di(BrandingPreviewBanner);
    const { formatMessage } = useIntl();

    if (isPageInBrandingPreviewMode) {
        return (
            <ActionsWrapper isPageInBrandingPreviewMode={isPageInBrandingPreviewMode}>
                <BrandingPreviewBanner />
            </ActionsWrapper>
        );
    }

    return (
        <ActionsWrapper isPageInBrandingPreviewMode={!!isPageInBrandingPreviewMode}>
            <ButtonContainer>
                {!isPageInPreviewMode && (
                    <Box xcss={buttonGroup}>
                        <ButtonWrapper actionSubjectId="backToHomePage" appearance="subtle" onClick={onClose}>
                            {formatMessage(messages.backToSettings)}
                        </ButtonWrapper>
                        <FeedbackCollectorButton
                            showIcon
                            showTypeField
                            featureName={HELP_CENTERS_CUSTOMIZE_PAGE_LAYOUT}
                            buttonLabel={messages.giveFeedback}
                            analyticsSubjectId="customizePageLayoutFeedbackButton"
                        />
                    </Box>
                )}

                {isPageInPreviewMode && (
                    <PreviewButtonWrapper>
                        <InlineMessage appearance="info" title={formatMessage(messages.previewMessage)} />
                    </PreviewButtonWrapper>
                )}
                <ButtonGroup>
                    <Button
                        isDisabled={isDisabled}
                        actionSubjectId="preview"
                        appearance="default"
                        onClick={togglePreviewMode}
                    >
                        {isPageInPreviewMode ? formatMessage(messages.exitPreview) : formatMessage(messages.preview)}
                    </Button>
                    <ActionButton
                        appearance="primary"
                        actionSubjectId="publish"
                        isDisabled={isDisabled}
                        onClick={onPublish}
                        isLoading={isPublishing}
                    >
                        {formatMessage(messages.publish)}
                    </ActionButton>
                </ButtonGroup>
            </ButtonContainer>
            {children}
        </ActionsWrapper>
    );
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const ButtonWrapper = styled<Omit<ButtonProps, 'theme'>>(Button)({
    marginLeft: token('space.negative.150', '-12px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const ButtonGroup = styled.div({
    position: 'relative',
});

const buttonGroup = xcss({
    display: 'flex',
    flexDirection: 'row',
    gap: 'space.050',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const ActionButton = styled<Omit<LoadingButtonProps, 'theme'>>(LoadingButton)({
    marginLeft: token('space.050', '4px'),
});

interface ActionsWrapperProps {
    isPageInBrandingPreviewMode: boolean;
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const ActionsWrapper = styled.div<ActionsWrapperProps>({
    padding: `${token('space.150', '12px')} ${token('space.300', '24px')}`,
    top: 0,
    left: 0,
    width: '100%',
    position: 'sticky',
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Disabled to rollout go/ui-styling-standard tooling, please resolve
    backgroundColor: (props) =>
        props.isPageInBrandingPreviewMode
            ? token('color.background.accent.gray.bolder', '#44546F')
            : // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
              token('elevation.surface', colors.N0),
    boxShadow: token(
        'elevation.shadow.overlay',
        ' 0px 0px 8px 0px rgba(9, 30, 66, 0.16), 0px 0px 1px 0px rgba(9, 30, 66, 0.12)'
    ),
    boxSizing: 'border-box',
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
    zIndex: zIndex.modal + 1, // plus 1 because toolbar of rich text editor comes on top
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const ButtonContainer = styled.div({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const PreviewButtonWrapper = styled.div({
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    pointerEvents: 'none',
});
