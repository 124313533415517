import { buildModelsActionCreator } from 'epics/model/actions';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { ErrorResponse } from 'epics/model/types';
import type { ArticleResponse, ModelTypeOptions } from 'rest/models/types';

export const FETCH_ARTICLE = 'epic.model.FETCH_ARTICLE';
export const FETCH_ARTICLE_SUCCESS = 'state.persisted.article.FETCH_ARTICLE_SUCCESS';
export const FETCH_ARTICLE_FAILURE = 'state.persisted.article.FETCH_ARTICLE_FAILURE';

interface AnalyticsMetaData {
    analyticsSuccessEvent?: UIAnalyticsEvent;
    analyticsFailureEvent?: UIAnalyticsEvent;
}

export type FetchArticleAction = ReturnType<typeof fetchArticle>;

export const fetchArticle = (payload: ModelTypeOptions['kbPage'], meta?: AnalyticsMetaData) => ({
    meta,
    ...buildModelsActionCreator(FETCH_ARTICLE, 'kbPage')(payload),
});

export interface FetchArticleSuccessAction {
    type: typeof FETCH_ARTICLE_SUCCESS;
    payload: ArticleResponse;
}

export const fetchArticleSuccess = (article: ArticleResponse): FetchArticleSuccessAction => ({
    type: FETCH_ARTICLE_SUCCESS,
    payload: article,
});

export interface FetchArticleFailureAction {
    type: typeof FETCH_ARTICLE_FAILURE;
    payload: {
        id: number | string;
        error: ErrorResponse;
    };
}

export const fetchArticleFailure = (id: number | string, error: ErrorResponse): FetchArticleFailureAction => ({
    type: FETCH_ARTICLE_FAILURE,
    payload: { id, error },
});
