import type { topicQueryType } from 'topics/src';
import TopicQuery from 'view/topic/__generated__/topicQuery.graphql';
import { createRelayResource } from '@atlassian/help-center-common-util/resource-router';

export const viewTopicResource = createRelayResource<topicQueryType>({
    type: 'VIEW_TOPIC',
    getQuery: (
        {
            match: {
                params: { id },
            },
        },
        { cloudId, helpCenterAri }
    ) => {
        return {
            parameters: TopicQuery,
            variables: {
                // Suppressing existing violation. Please fix this.
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                cloudId,
                // Suppressing existing violation. Please fix this.
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                helpCenterAri,
                topicId: String(id),
            },
            options: {
                fetchPolicy: 'store-or-network',
            },
        };
    },
});
