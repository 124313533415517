import type { Sources } from '@atlassian/conversation-assistant';
import { ASSISTANT_MESSAGE_STATUS } from '../../rest/converse';
import { ASSISTANT_USER_TYPE } from '../../store/converse/constants';
import type {
    AssistantMessage,
    AuthenticationMessage,
    Conversation,
    ConversationMessage,
} from '../../store/converse/types';

export const updateKeywords = (assistantMessage: AssistantMessage, keywords: string = ''): AssistantMessage => ({
    ...assistantMessage,
    messageMetadata: {
        loadingState: {
            ...assistantMessage.messageMetadata.loadingState,
            keywords: keywords.split('|'),
        },
    },
});

export const updateSources = (assistantMessage: AssistantMessage, sources: Sources): AssistantMessage => ({
    ...assistantMessage,
    messageMetadata: {
        loadingState: {
            ...assistantMessage.messageMetadata.loadingState,
            sources,
        },
    },
});

export const updateMessage = (assistantMessage: AssistantMessage, message: ConversationMessage): AssistantMessage => ({
    ...assistantMessage,
    aiMateConversationResponse: {
        message,
    },
});

export const updateFinalMessage = (
    assistantMessage: AssistantMessage,
    message: ConversationMessage,
    isFallbackMessage: boolean
): AssistantMessage => ({
    ...assistantMessage,
    aiMateConversationResponse: {
        message,
    },
    messageMetadata: {
        ...assistantMessage.messageMetadata,
        isFallbackMessage,
        messageId: message.id.toString(),
        status: ASSISTANT_MESSAGE_STATUS.IDLE,
        showFeedback: !isFallbackMessage,
    },
});

export const addAssistantMessage = (assistantMessage: AssistantMessage, conversation: Conversation): Conversation => ({
    ...conversation,
    history: [...conversation.history, assistantMessage],
});

export const replaceLastAssistantMessage = (
    assistantMessage: AssistantMessage,
    conversation: Conversation
): Conversation => {
    const updatedConversationHistory =
        conversation.history[conversation.history.length - 1].userType === ASSISTANT_USER_TYPE
            ? conversation.history.slice(0, -1)
            : conversation.history;
    return {
        ...conversation,
        history: [...updatedConversationHistory, assistantMessage],
    };
};

export const updateFollowUpMessage = (conversation: Conversation, queries: string[]): Conversation => ({
    ...conversation,
    history: [
        ...conversation.history,
        {
            queries,
            userType: 'followUp',
        },
    ],
});

export const addAuthMessage = (authMessages: AuthenticationMessage[], conversation: Conversation): Conversation => ({
    ...conversation,
    history: [...conversation.history, ...(authMessages ?? [])],
});

export const updateAssistantMessageStatusToTypingResponse = (assistantMessage: AssistantMessage): AssistantMessage => ({
    ...assistantMessage,
    messageMetadata: {
        ...assistantMessage.messageMetadata,
        status: ASSISTANT_MESSAGE_STATUS.TYPING_RESPONSE,
    },
});

export const updateAssistantMessageStatusToLoadingResponse = (
    assistantMessage: AssistantMessage
): AssistantMessage => ({
    ...assistantMessage,
    messageMetadata: {
        ...assistantMessage.messageMetadata,
        status: ASSISTANT_MESSAGE_STATUS.LOADING_RESPONSE,
    },
});

export const updateAbortedAssistantMessage = (assistantMessage: AssistantMessage): AssistantMessage => ({
    ...assistantMessage,
    messageMetadata: {
        ...assistantMessage.messageMetadata,
        status: ASSISTANT_MESSAGE_STATUS.ABORTED_RESPONSE,
    },
});
