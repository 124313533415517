import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import { useIntl } from 'react-intl-next';

import { useAnalyticsEvents, withAnalyticsContext } from '@atlaskit/analytics-next';
import ArrowLeftCircleIcon from '@atlaskit/icon/glyph/arrow-left-circle';
import { LeftSidebarWithoutResize } from '@atlaskit/page-layout';
import {
    ButtonItem,
    Section,
    SideNavigation,
    NestableNavigationContent,
    NavigationHeader,
} from '@atlaskit/side-navigation';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { UI_EVENT_TYPE } from '@atlassian/analytics-web-react';
import FeedbackCollectorButton from '@atlassian/help-center-common-component/feedback-collector/feedback-collector-button';
import { getEnv } from '@atlassian/help-center-common-util/env';

import messages from './messages';
export interface NavigationContentItem {
    onClick: () => void;
    iconBefore: React.ReactElement;
    text: string;
}

const Sidebar: React.FC<{
    width: number;
    navigationContentItems: NavigationContentItem[];
    featureName: string;
}> = ({ width, navigationContentItems, featureName }) => {
    const { formatMessage } = useIntl();
    const { createAnalyticsEvent } = useAnalyticsEvents();
    const onBackButtonClick = useCallback(() => {
        createAnalyticsEvent({
            analyticsType: UI_EVENT_TYPE,
            action: 'click',
            actionSubjectId: `SidebarBackButton`,
        }).fire();

        window.location.href = `${getEnv().baseUrl}`;
    }, [createAnalyticsEvent]);

    return (
        <LeftSidebarWithoutResize width={width}>
            <NavContainer>
                <SideNavigation label={formatMessage(messages.sidebarNavigationLabel)}>
                    <NavigationHeader>
                        <ButtonItem
                            onClick={onBackButtonClick}
                            iconBefore={<ArrowLeftCircleIcon label={formatMessage(messages.backIconLabel)} />}
                        >
                            {formatMessage(messages.backButton)}
                        </ButtonItem>
                    </NavigationHeader>
                    <NestableNavigationContent showTopScrollIndicator>
                        <Section isList>
                            {navigationContentItems.map(({ onClick, iconBefore, text }) => (
                                <ButtonItem onClick={onClick} iconBefore={iconBefore} key={text}>
                                    {text}
                                </ButtonItem>
                            ))}
                        </Section>
                        <FeedbackCollectorButton
                            showIcon
                            showTypeField
                            featureName={featureName}
                            buttonLabel={messages.feedbackWidget}
                            analyticsSubjectId="SidebarFeedbackButton"
                            useSideNavButton
                        />
                    </NestableNavigationContent>
                </SideNavigation>
            </NavContainer>
        </LeftSidebarWithoutResize>
    );
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const NavContainer = styled.div({
    width: '100%',
    height: 'calc(100% - 1px)',
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
    borderRight: `1px solid ${token('color.border', colors.N40)}`,
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
    borderTop: `1px solid ${token('color.border', colors.N40)}`,
});
export default withAnalyticsContext({ component: 'SideBar' })(Sidebar);
