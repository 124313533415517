import type { StoreActionApi } from 'react-sweet-state';
import type { State } from '../types';
import { constructHelpseekerMessage } from './construct-helpseeker-message';
import { sendHelpseekerMessage } from './send-helpseeker-message';
import { startConversation } from './start-conversation';

export const createNewMessageAndStartConversation =
    (message: string) =>
    async ({ getState, setState, dispatch }: StoreActionApi<State>) => {
        const { conversationId } = getState();
        try {
            const helpseekerMessage = dispatch(
                constructHelpseekerMessage({
                    authorType: 'HELPSEEKER',
                    content: message,
                })
            );

            // start new conversation if no conversation id
            if (!conversationId) {
                const newConversationId = await dispatch(startConversation());

                if (!newConversationId) {
                    throw new Error('A conversation id for this conversation already exists.');
                }
                helpseekerMessage.conversationChannelId = newConversationId;
            }

            return dispatch(sendHelpseekerMessage(helpseekerMessage));
        } catch (error) {
            setState({ isLoading: false });
            return undefined;
        }
    };

export const addMessage =
    (message: string) =>
    ({ dispatch }: StoreActionApi<State>) => {
        dispatch(createNewMessageAndStartConversation(message)).catch(() => {
            return undefined;
        });
    };
