import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { isCSMHelpCenter } from 'util/advanced-help-center';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import atlassianIcon from 'assets/images/atlassian.svg';
import jsdIcon from 'assets/images/jsd-icon.svg';
import { usePathParam, useRouter } from 'react-resource-router';
import * as fonts from 'view/styles/fonts';
import * as transitions from 'view/styles/transitions';
import { AnalyticsContext } from '@atlaskit/analytics-next';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { HtmlAnchor } from '@atlassian/help-center-common-component/analytics/html-anchor';
import { CTAContainer } from '@atlassian/help-center-common-component/cta-container';
import { getEnv } from '@atlassian/help-center-common-util/env';
import { shouldShowCTA } from './internal/utils';
import i18n from './messages';
import { footerPadding, footerJsdIconHeight } from './styled';

interface Props {
    linkTo: string;
    className?: string;
}

const Footer = ({ linkTo, className }: Props) => {
    const [{ route }] = useRouter();
    const [requestTypeId] = usePathParam('requestTypeId');

    return (
        <AnalyticsContext data={{ source: 'footer' }}>
            {shouldShowCTA(route.name, requestTypeId) && <CTAContainer />}
            {/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Disabled to rollout go/ui-styling-standard tooling, please resolve */}
            <FooterContainer className={className}>
                <FooterLink
                    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Disabled to rollout go/ui-styling-standard tooling, please resolve
                    className="cv-exclude-from-customisation"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={linkTo}
                    actionSubjectId="waclink"
                >
                    {isCSMHelpCenter(getEnv().helpCenterType) ? (
                        <FormattedMessage
                            {...i18n.csmFooter}
                            values={{
                                iconHtml: <FooterAtlassianIcon src={atlassianIcon} alt="" />,
                            }}
                        />
                    ) : (
                        <FormattedMessage
                            {...i18n.footer}
                            values={{
                                iconHtml: <FooterJsdIcon src={jsdIcon} alt="" />,
                            }}
                        />
                    )}
                </FooterLink>
            </FooterContainer>
        </AnalyticsContext>
    );
};

export default Footer;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const FooterContainer = styled.footer`
    padding: ${token('space.600', `${footerPadding}px`)} 0;
    text-align: center;
    white-space: nowrap;
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const FooterJsdIcon = styled.img`
    object-fit: contain;
    height: ${token('space.250', `${footerJsdIconHeight}px`)};
    vertical-align: middle;
    opacity: 0.5;
    transition: opacity ${transitions.timingFunctions.easeInOut} ${transitions.speedMs.fast}ms;
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const FooterAtlassianIcon = styled.img`
    object-fit: contain;
    height: ${token('space.250', `${footerJsdIconHeight}px`)};
    vertical-align: bottom;
    opacity: 0.5;
    transition: opacity ${transitions.timingFunctions.easeInOut} ${transitions.speedMs.fast}ms;
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const FooterLink = styled(HtmlAnchor)`
    ${fonts.small};
    color: ${token('color.text.subtlest', colors.N300)};
    cursor: pointer;

    :hover,
    :active {
        color: ${token('color.text.subtlest', colors.N300)};
        text-decoration: none;

        ${FooterJsdIcon} {
            opacity: 1;

            stop:first-child {
                stop-color: ${token('color.background.brand.bold.hovered', colors.B400)};
            }

            stop:last-child {
                stop-color: ${token('color.background.brand.bold', colors.B200)};
            }

            > g > g > path:last-child {
                fill: ${token('color.background.brand.bold', colors.B200)};
            }
        }
    }
`;
