import * as React from 'react';
import type { InjectedIntl, InjectedIntlProps } from 'react-intl';
import { injectIntl } from 'react-intl';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import ErrorIcon from 'assets/images/error.react.svg';
import LockIcon from 'assets/images/lock.react.svg';
import { di } from 'react-magnetic-di';
import { PaperContent } from 'view/layout/paper';
import * as fonts from 'view/styles/fonts';
import * as grid from 'view/styles/grid';
import { token } from '@atlaskit/tokens';
import { ExperienceFailure } from '@atlassian/help-center-common-component/analytics';
import { HTTP } from '@atlassian/help-center-common-component/constants';
import { DocumentTitle } from '@atlassian/help-center-common-component/document-title';
import { ErrorLink } from './error-link';
import messages from './messages';
import type { PersistedError } from 'state/persisted/types';

interface ErrorPageProps extends Partial<PersistedError> {
    children?: React.ReactNode;
    config?: ErrorPageConfig;
}

const isUnhandledError = (statusCode: number) => {
    // We want to report on any 5xx errors for the experience tracker.
    return `${statusCode}`.startsWith('5');
};

export const handleHttpCode = ({ error, intl }: { error: PersistedError['error']; intl: InjectedIntl }) => {
    let Icon: React.ComponentType;
    let heading: string = '';
    let description: string = '';

    switch (error.status) {
        case HTTP.FORBIDDEN: {
            heading = intl.formatMessage(messages.permissionDenied);
            description = intl.formatMessage(messages.permissionDeniedMessage);
            Icon = LockIcon;
            break;
        }

        case HTTP.NOT_FOUND: {
            heading = intl.formatMessage(messages.notFound);
            description = intl.formatMessage(messages.notFoundMessage);
            Icon = ErrorIcon;
            break;
        }

        case HTTP.OFFLINE: {
            heading = intl.formatMessage(messages.offlineHeading);
            description = intl.formatMessage(messages.offlineMessage);
            Icon = ErrorIcon;
            break;
        }

        case HTTP.SERVER:
        default: {
            heading = intl.formatMessage(messages.genericHeading);
            description = intl.formatMessage(messages.genericMessage);
            Icon = ErrorIcon;
            break;
        }
    }

    if (error.message) {
        // If there is a message with the error we override it.
        description = typeof error.message === 'string' ? error.message : intl.formatMessage(error.message);
    }
    if (error.heading) {
        heading = typeof error.heading === 'string' ? error.heading : intl.formatMessage(error.heading);
    }

    return { heading, description, Icon };
};

export const ErrorPage = ({ error, children, intl, config }: ErrorPageProps & InjectedIntlProps) => {
    di(DocumentTitle, handleHttpCode, ExperienceFailure);

    if (!!error) {
        const { Icon, heading, description } = handleHttpCode({
            error,
            intl,
        });
        // failure logging to be avoided only if the traceId is absent and the config is set to exclude empty traceId logs
        const shouldLogFailure = error.traceId ? true : !config?.isEmptyTraceIdLogExcluded;
        const shouldRenderExperienceFailure = isUnhandledError(error.status) && shouldLogFailure;

        return (
            <DocumentTitle title={description}>
                <PaperContent>
                    <Container>
                        <IconContainer>
                            <Icon />
                        </IconContainer>

                        <Message>{heading}</Message>

                        <Description>{description}</Description>

                        {error.status !== HTTP.OFFLINE && (
                            <ErrorLink callToActionUrl={error.callToActionUrl}>{error.callToActionText}</ErrorLink>
                        )}

                        {shouldRenderExperienceFailure && (
                            <ExperienceFailure
                                location="error-page"
                                errorMessage={description}
                                traceId={error.traceId}
                                responseHeaders={error.responseHeaders}
                                status={error.status}
                            />
                        )}
                    </Container>
                </PaperContent>
            </DocumentTitle>
        );
    }

    return React.Children.only(children) as JSX.Element;
};

const IntlErrorPage = injectIntl(ErrorPage);
interface ErrorPageConfig {
    isEmptyTraceIdLogExcluded?: boolean;
}

export const withErrorPage =
    <TProps extends object>(WrappedComponent: React.ComponentType<TProps>, config?: ErrorPageConfig) =>
    // upgrading eslint-plugin-react to 7.31.11. Please correct when this code is revisited.
    // eslint-disable-next-line react/display-name
    (props: TProps & Partial<PersistedError>) => (
        <IntlErrorPage {...props} error={props.error} config={config}>
            <WrappedComponent {...props} />
        </IntlErrorPage>
    );

export const defaultErrorPayload = {
    status: 500,
    callToActionUrl: '',
    callToActionText: '',
    message: '',
};

export default IntlErrorPage;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    margin-top: ${token('space.600', '48px')};
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const IconContainer = styled.div`
    width: ${grid.multiple(14).px};
    margin-bottom: ${token('space.200', '16px')};
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const Description = styled.p`
    ${fonts.regular};
    /* setting max-width 100% because on IE, text flows out of the container */
    max-width: 100%;
    margin: 0 0 ${token('space.100', '8px')} 0;
    text-align: center;
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const Message = styled.div`
    ${fonts.h600};
    /* setting max-width 100% because on IE, text flows out of the container */
    max-width: 100%;
    margin: 0 0 ${token('space.100', '8px')} 0;
`;
