import React from 'react';
import { isCSMHelpCenter } from 'util/advanced-help-center';
import { isValidLayoutAriPresent } from 'util/meta';
import { compose } from 'redux';
import { getIsHCCustomizationEnabled } from '@helpCenter/util/env';
import { isAdvancedCustomizationEditPageEnabled, isAdvancedCustomizationEnabled } from 'feature-flags';
import { useIntl } from 'react-intl-next';
import { lazyForPaint } from 'react-loosely-lazy';
import type { Route } from 'react-resource-router';
import { FlexibleHomepageLoader } from 'view/advanced-help-center/flexible-homepage-loader';
import { NotFoundPage } from 'view/app/routes';
import { HelpCenter } from 'view/help-center';
import { SplashLayoutRoute } from 'view/layout';
import { Header } from 'view/layout/header';
import type { PaperAppearance } from 'view/layout/paper';
import { authPageMessage, callToActionUrlText } from 'view/portal-reorder-experience';
import { withAnalyticsEvents } from '@atlaskit/analytics-next';
import { withAnalyticsContext } from '@atlassian/analytics-web-react';
import { ScreenName } from '@atlassian/help-center-common-component/constants';
import { withAsync } from '@atlassian/help-center-common-component/with-async';
import { withOnlyJiraAdmin } from '@atlassian/help-center-common-component/with-only-jira-admin';
import { withOnlyLoggedIn } from '@atlassian/help-center-common-component/with-only-logged-in';
import { getEnv } from '@atlassian/help-center-common-util/env';
import { createRouteWithLayout } from '@atlassian/help-center-common-util/resource-router';
import EditHomepageResource from './resources/edit-homepage';
import {
    brandingResource,
    featuredAndSortedPortalsResource,
    helpDesksResource,
    topicsResource,
} from './resources/multi-help-centers';
import ViewHomepageResource from './resources/view-homepage';

const VIEW_HOMEPAGE_PATH = '/';
const EDIT_HOMEPAGE_PATH = '/edit';

const AsyncViewHomepage = withAsync({
    loader: lazyForPaint(
        () => import(/* webpackChunkName: "async-view-homepage" */ '../../view/advanced-help-center/view-homepage')
    ),
    LoadingSkeleton: () => <FlexibleHomepageLoader />,
});

const paperAppearance: PaperAppearance = 'none';

const getCsmHelpCenterRoute = () =>
    createRouteWithLayout(
        {
            name: 'help-center',
            path: '/:requestId([0-9]+)?',
            exact: true,
            component: HelpCenter,
            resources: __SERVER__
                ? [brandingResource, featuredAndSortedPortalsResource, topicsResource, helpDesksResource]
                : [],
        },
        ScreenName.HELP_CENTER,
        {
            paperAppearance: 'none',
            hideSearchButton: true,
            fadeInSearchModal: false,
        }
    );

const getMultiHelpCenterRoute = () =>
    createRouteWithLayout(
        {
            name: 'help-center',
            path: '/',
            exact: true,
            component: HelpCenter,
            resources: __SERVER__
                ? [brandingResource, featuredAndSortedPortalsResource, topicsResource, helpDesksResource]
                : [],
        },
        ScreenName.HELP_CENTER,
        {
            paperAppearance: 'none',
            hideSearchButton: true,
            fadeInSearchModal: false,
        }
    );

export const getFlexiHelpCenterRoute = (
    name: string = 'help-center',
    path: string = VIEW_HOMEPAGE_PATH,
    screenName: ScreenName = ScreenName.VIEW_HOMEPAGE
) => {
    return createRouteWithLayout(
        {
            name,
            path,
            exact: true,
            component: AsyncViewHomepage,
            /**
             * These resources can be removed once the individual elements data starts coming in the layout response
             */
            resources: __SERVER__
                ? [brandingResource, featuredAndSortedPortalsResource, topicsResource, ViewHomepageResource]
                : [ViewHomepageResource],
        },
        screenName,
        {
            paperAppearance,
            fadeInSearchModal: true,
            hideSearchButton: true,
            hideBanner: true,
        }
    );
};

export const getViewHomePageRoute = () => {
    if (isAdvancedCustomizationEnabled() && isValidLayoutAriPresent()) return getFlexiHelpCenterRoute();
    if (isCSMHelpCenter(getEnv().helpCenterType)) return getCsmHelpCenterRoute();
    return getMultiHelpCenterRoute();
};

interface Path {
    location: {
        pathname: string;
    };
}

export const AsyncEditHomepage = withAsync({
    loader: lazyForPaint(
        () => import(/* webpackChunkName: "async-edit-homepage" */ '../../view/advanced-help-center/edit-homepage')
    ),
    LoadingSkeleton: () => (
        <FlexibleHomepageLoader
            showActionBar
            header={
                <Header
                    isAnonymous={false}
                    logoTextColor="banner"
                    hideSearchButton
                    isAdvancedHelpCenter
                    disableAllHeaderLinks
                />
            }
        />
    ),
});

export const EditHomePageLayout = (props: Path) => {
    const { formatMessage } = useIntl();
    const WrappedComponent = compose(
        withOnlyLoggedIn({
            redirectUrl: props.location.pathname,
        }),
        withOnlyJiraAdmin({
            message: formatMessage(authPageMessage),
            callToActionText: formatMessage(callToActionUrlText),
            callToActionUrl: '/',
        })
    )(() => (
        <SplashLayoutRoute
            path={EDIT_HOMEPAGE_PATH}
            component={AsyncEditHomepage}
            screenName={ScreenName.EDIT_HOMEPAGE}
        />
    ));

    return <WrappedComponent {...props} />;
};

export const getEditHomePageRoute = (name: string = 'edit-homepage', path: string = '/edit') => {
    const editHomePageRoute: Route = {
        name,
        path,
        exact: true,
        component:
            isAdvancedCustomizationEnabled() &&
            getIsHCCustomizationEnabled() &&
            isValidLayoutAriPresent() &&
            isAdvancedCustomizationEditPageEnabled()
                ? React.memo(EditHomePageLayout)
                : withAnalyticsContext<React.ComponentProps<typeof NotFoundPage>>({ componentName: 'notFoundPage' })(
                      withAnalyticsEvents({})(NotFoundPage)
                  ),
        resources: [EditHomepageResource],
    };
    return editHomePageRoute;
};
