/**
 * DO NOT EDIT.
 *
 * This is Relay generated artifact.
 *
 * Please reach out to #jsm-cloud-megatron for any questions.
 *
 * @generated SignedSource<<6fa56c7dd437fd339671d4324b6efe84>>
 * @relayHash 3687144e8f4ba5336e300122c8f9f7d7
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 0295d9eeb2e5f594770d41dea0f1a7eb2fdc07deab816c43542e8500feab0595

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type HelpCenterDescriptionType = "PLAIN_TEXT" | "RICH_TEXT" | "WIKI_MARKUP" | "%future added value";
export type HelpCenterAnnouncementInput = {
  descriptionTranslations?: ReadonlyArray<HelpCenterTranslationInput> | null | undefined;
  descriptionType: HelpCenterDescriptionType;
  helpCenterAri: string;
  nameTranslations?: ReadonlyArray<HelpCenterTranslationInput> | null | undefined;
};
export type HelpCenterTranslationInput = {
  locale: string;
  localeDisplayName?: string | null | undefined;
  value: string;
};
export type loginAnnouncementsUpdateMutation$variables = {
  cloudId: string;
  input: HelpCenterAnnouncementInput;
};
export type loginAnnouncementsUpdateMutation$data = {
  readonly helpCenter: {
    readonly updateLoginAnnouncement: {
      readonly errors: ReadonlyArray<{
        readonly extensions: {
          readonly statusCode: number | null | undefined;
        } | null | undefined;
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type loginAnnouncementsUpdateMutation = {
  response: loginAnnouncementsUpdateMutation$data;
  variables: loginAnnouncementsUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "statusCode",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "loginAnnouncementsUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "HelpCenterMutationApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "HelpCenterAnnouncementUpdatePayload",
            "kind": "LinkedField",
            "name": "updateLoginAnnouncement",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "MutationError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "loginAnnouncementsUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "HelpCenterMutationApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "HelpCenterAnnouncementUpdatePayload",
            "kind": "LinkedField",
            "name": "updateLoginAnnouncement",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "MutationError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "0295d9eeb2e5f594770d41dea0f1a7eb2fdc07deab816c43542e8500feab0595",
    "metadata": {},
    "name": "loginAnnouncementsUpdateMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "a5dd7f4861ac93d9b41cc3104b2a0e6a";

export default node;
