import type { UpdateContextAction, ClearContextAction } from 'state/actions/context';
import { UPDATE_CONTEXT, CLEAR_CONTEXT } from 'state/actions/context';
import { toNumber } from '@atlassian/help-center-common-util/number';

export interface ContextState {
    portalId?: number;
    pathname?: string;
    routeName?: string;
}

type HandledActions = UpdateContextAction | ClearContextAction;

export default function reducer(state: ContextState = {}, action: HandledActions) {
    switch (action.type) {
        case UPDATE_CONTEXT: {
            const { pathname, pathParams, routeName } = action.payload;

            let portalId;

            if (pathParams) {
                portalId = toNumber(pathParams.portalId);
            }

            return { portalId, pathname, routeName };
        }
        case CLEAR_CONTEXT:
            return {};

        default:
            return state;
    }
}
