import type { StoreActionApi } from 'react-sweet-state';
import type { State, ConversationStateContainerProps } from '../types';

export const resetStateOnContextIdChanged =
    () =>
    (
        { setState, getState }: StoreActionApi<State>,
        {
            conversationProvider,
            portalId,
            projectId,
            contextId,
            baseUrl,
            isTestConversation,
            handleEscalateFromVirtualAgent,
        }: ConversationStateContainerProps
    ) => {
        const { contextId: storedContextId } = getState();
        if (storedContextId !== contextId) {
            setState({
                conversationProvider,
                portalId,
                projectId,
                contextId,
                baseUrl,
                isTestConversation,
                handleEscalateFromVirtualAgent,
            });
        }
    };
