/**
 * DO NOT EDIT.
 *
 * This is Relay generated artifact.
 *
 * Please reach out to #jsm-cloud-megatron for any questions.
 *
 * @generated SignedSource<<e8b50ea9bd063a723c237d3a824ee577>>
 * @relayHash 518852e57bbb600ae7fee25211d46880
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID d39d5a4bebb6c5e91a173fe5ee3a43bb63f3b58ea19c207084f6f693d1120891

import type { ConcreteRequest, Query } from 'relay-runtime';
export type helpReportingPageQuery$variables = {
  cloudId: string;
  helpCenterAri: string;
};
export type helpReportingPageQuery$data = {
  readonly helpCenter: {
    readonly helpCenterReportingById: {
      readonly __typename: "HelpCenterReporting";
      readonly performanceIndicatorsWithMetaData: {
        readonly performanceIndicators: ReadonlyArray<{
          readonly currentValue: string;
          readonly name: string;
          readonly previousValue: string | null | undefined;
          readonly timeWindow: string | null | undefined;
        }> | null | undefined;
      } | null | undefined;
    } | {
      readonly __typename: "QueryError";
      readonly message: string | null | undefined;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    } | null | undefined;
  } | null | undefined;
};
export type helpReportingPageQuery = {
  response: helpReportingPageQuery$data;
  variables: helpReportingPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "helpCenterAri"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "cloudId",
        "variableName": "cloudId"
      }
    ],
    "concreteType": "HelpCenterQueryApi",
    "kind": "LinkedField",
    "name": "helpCenter",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "helpCenterAri",
            "variableName": "helpCenterAri"
          }
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "helpCenterReportingById",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "HelpCenterReportingPerformanceIndicatorsWithMetaData",
                "kind": "LinkedField",
                "name": "performanceIndicatorsWithMetaData",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "HelpCenterReportingPerformanceIndicator",
                    "kind": "LinkedField",
                    "name": "performanceIndicators",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "currentValue",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "previousValue",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "timeWindow",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "HelpCenterReporting",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              }
            ],
            "type": "QueryError",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "helpReportingPageQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "helpReportingPageQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "d39d5a4bebb6c5e91a173fe5ee3a43bb63f3b58ea19c207084f6f693d1120891",
    "metadata": {},
    "name": "helpReportingPageQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "17114276f51d5a710919c3c8f4f04785";

export default node;
