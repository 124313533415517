import * as React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import { isNil } from 'lodash';
import { token } from '@atlaskit/tokens';

const DEFAULT_RESULT_PADDING = token('space.200', '16px');
const DEFAULT_ICON_SIZE = token('space.400', '32px');
const DEFAULT_ICON_MARGIN = token('space.200', '16px');

export interface Props {
    leftIcon?: React.ReactNode;
    rightIcon?: React.ReactNode;
    /** @deprecated Use `customStyling.iconSize` property instead. */
    iconSize?: number;
    /** @deprecated Use `customStyling.iconMargin` property instead. */
    iconMargin?: number;
    /** @deprecated Use `customStyling.padding` property instead. */
    padding?: number;
    alignItems?: 'center' | 'normal';
    content?: React.ReactNode;
    className?: string;
    centerAlignLeftIcon?: boolean;
    centerAlignRightIcon?: boolean;
    dataTestId?: string;
    // Tokens are strings and are variable names, hence cannot extract the value. So, passing numbers as props like before is not possible, hence to provide backward compatibility, keeping those properties as is for now, once the entire codebase is migrated those can be removed.
    customStyling?: {
        padding?: string;
        iconSize?: string;
        iconMargin?: string;
    };
}

const getSpaceValueWithDefault = (defaultSpacing: string, value?: number, customSpacing?: string) => {
    if (!isNil(value)) {
        return `${value}px`;
    }
    return customSpacing || defaultSpacing;
};

const IconCardLayout: React.FunctionComponent<Props> = ({
    leftIcon,
    rightIcon,
    content,
    iconSize,
    iconMargin,
    padding,
    alignItems,
    className,
    centerAlignLeftIcon,
    centerAlignRightIcon,
    dataTestId,
    customStyling,
}) => {
    const { iconSize: customIconSize, iconMargin: customIconMargin, padding: customPadding } = customStyling || {};
    const iconSizeWithDefault = getSpaceValueWithDefault(DEFAULT_ICON_SIZE, iconSize, customIconSize);
    const iconMarginWithDefault = getSpaceValueWithDefault(DEFAULT_ICON_MARGIN, iconMargin, customIconMargin);
    const paddingWithDefault = getSpaceValueWithDefault(DEFAULT_RESULT_PADDING, padding, customPadding);

    return (
        <ResultContainer
            data-test-id={dataTestId}
            // eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Disabled to rollout go/ui-styling-standard tooling, please resolve
            className={className}
            padding={paddingWithDefault}
            align={alignItems || 'center'}
        >
            {leftIcon && (
                <IconContainer
                    iconMargin={iconMarginWithDefault}
                    iconSize={iconSizeWithDefault}
                    isCenterAlignIcon={centerAlignLeftIcon}
                >
                    {leftIcon}
                </IconContainer>
            )}
            <ResultContentContainer>{content}</ResultContentContainer>
            {rightIcon && (
                <IconContainer
                    iconMargin={DEFAULT_ICON_MARGIN}
                    iconSize={iconSizeWithDefault}
                    isCenterAlignIcon={centerAlignRightIcon}
                    right
                >
                    {rightIcon}
                </IconContainer>
            )}
        </ResultContainer>
    );
};

export default IconCardLayout;

interface ResultContainerProps {
    padding: string;
    align: 'center' | 'normal';
    className?: string;
}

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const ResultContainer = styled.div`
    display: flex;
    width: 100%;
    box-sizing: border-box;
    align-items: ${(props: ResultContainerProps) => props.align};
    justify-content: space-between;
    padding: 0 ${(props: ResultContainerProps) => props.padding};
`;

interface IconContainerProps {
    iconSize: string;
    iconMargin: string;
    right?: boolean;
    isCenterAlignIcon?: boolean;
}

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const IconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${(props: IconContainerProps) => props.iconSize};
    align-self: flex-start;
    margin-top: ${token('space.050', '4px')};
    flex-shrink: 0;
    ${(props: IconContainerProps) => props.isCenterAlignIcon && `margin: auto`};
    ${(props: IconContainerProps) =>
        props.right ? `margin-left: ${props.iconMargin}` : `margin-right: ${props.iconMargin}`};
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const ResultContentContainer = styled.div`
    width: 100%;
    overflow: hidden;
`;
