import * as React from 'react';
import { Loading } from '@atlassian/help-center-common-component/loading';

export interface LoadingContainerProps {
    loadingPosition?: 'static' | 'fill-container';
    isLoading: boolean;
    children: React.ReactNode;
}

/**
 * Displays children when isLoading is false, otherwise shows loading spinner.
 */
export default class LoadingContainer extends React.Component<LoadingContainerProps> {
    static defaultProps = {
        loadingPosition: 'fill-container',
    };

    render() {
        const { children, loadingPosition, isLoading } = this.props;

        return isLoading ? <Loading isLoading loadingPosition={loadingPosition} /> : children;
    }
}
