import * as React from 'react';
import FakeButtonContainer from './styled';

interface Props {
    onClick?: (e?: React.SyntheticEvent<HTMLDivElement>) => void;
    className?: string;
    disabled?: boolean;
    isExpanded?: boolean;
    isCursorGrab?: boolean;
}

export default class FakeButton extends React.Component<React.PropsWithChildren<Props>> {
    stopPropagationForLinks = (e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>): boolean => {
        const target = e.target as HTMLElement;
        if (target.tagName === 'A') {
            e.stopPropagation();
            return true;
        }

        return false;
    };

    handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        const { onClick } = this.props;

        if (this.stopPropagationForLinks(e)) {
            return;
        }

        if (e.key === 'Enter') {
            onClick && onClick(e);
        } else if (e.key === ' ') {
            // Prevent default so scrolling doesn't happen when we press spacebar.
            e.preventDefault();
        }
    };

    handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (this.stopPropagationForLinks(e)) {
            return;
        }

        this.props.onClick && this.props.onClick(e);
    };

    handleKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
        const { onClick } = this.props;

        if (this.stopPropagationForLinks(e)) {
            return;
        }

        if (e.key === ' ') {
            onClick && onClick(e);
        }
    };

    render() {
        const { className, children, disabled, isExpanded, isCursorGrab } = this.props;
        return (
            <FakeButtonContainer
                data-testid="fake-button"
                // eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Disabled to rollout go/ui-styling-standard tooling, please resolve
                className={className}
                role="button"
                tabIndex={disabled ? -1 : 0}
                onClick={this.handleClick}
                onKeyDown={this.handleKeyDown}
                onKeyUp={this.handleKeyUp}
                aria-disabled={disabled}
                aria-expanded={isExpanded ? true : false}
                isCursorGrab={isCursorGrab}
            >
                {children}
            </FakeButtonContainer>
        );
    }
}
