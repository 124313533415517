import * as React from 'react';
import type { InferableComponentEnhancerWithProps } from 'react-redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import type { State } from '@helpCenter/state';
import { toNumber } from 'lodash';
import { di } from 'react-magnetic-di';
import type { RouteContext } from 'react-resource-router';
import { getCanAdministerProject } from 'state/selectors/portal';
import { BannerWithPaperLayoutRoute } from 'view/layout/banner-with-paper-layout';
import { HTTP } from '@atlassian/help-center-common-component/constants';
import { ErrorPage } from '@atlassian/help-center-common-component/error-page';
import type { PersistedError } from 'state/persisted/types';

type ExternalProps = Pick<RouteContext, 'match'>;

const selector = createSelector(
    (state: State, props: ExternalProps) => {
        di(getCanAdministerProject);
        const portalIdAsString = props.match.params && props.match.params.portalId;
        if (portalIdAsString) {
            return getCanAdministerProject(state, toNumber(portalIdAsString));
        }
        return false;
    },
    (isProjectAdmin) => ({
        isProjectAdmin,
    })
);

type ConnectedProps<T> = T extends InferableComponentEnhancerWithProps<infer Props, infer _> ? Props : never;

const connector = connect(selector, null);

type OnlyAdminOption = Omit<PersistedError['error'], 'status'>;

const withOnlyProjectAdmin =
    (options: OnlyAdminOption) =>
    <TProps extends object>(WrappedComponent: React.ComponentType<TProps>) => {
        const ReturnComponent: React.FC<TProps & ConnectedProps<typeof connector>> = (props) => {
            di(BannerWithPaperLayoutRoute);
            const { isProjectAdmin } = props;
            if (isProjectAdmin) {
                //@ts-ignore TypeScript upgrade 4.7.4
                return <WrappedComponent {...props} />;
            }
            const { message, callToActionUrl, callToActionText } = options;

            return (
                <BannerWithPaperLayoutRoute
                    component={() => (
                        <ErrorPage
                            error={{
                                message,
                                callToActionUrl,
                                callToActionText,
                                status: HTTP.FORBIDDEN,
                            }}
                        />
                    )}
                    layoutProps={{
                        headerless: false,
                        paperAppearance: 'normal',
                        fadeInSearchModal: true,
                    }}
                />
            );
        };
        const ConnectedComponent = connector(ReturnComponent);
        ConnectedComponent.displayName = 'withOnlyProjectAdmin';

        return ConnectedComponent;
    };

export default withOnlyProjectAdmin;
