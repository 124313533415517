import type { AnalyticsEvent, UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fireAnalyticsEvent, EventTypes } from '@atlassian/analytics-web-react';

export type ExperienceAction = 'taskStart' | 'taskSuccess' | 'taskFail';

interface Experience {
    /**
     * The experience name.
     * For example "finding-help".
     */
    name: string;

    /**
     * If the experience has an id, add it here.
     * For example a request id.
     */
    experienceId?: string;

    /**
     * An experience has three possible actions.
     * It will always START, and then it will either be a SUCCESS or FAIL.
     */
    action: ExperienceAction;
}

interface ExperienceStart extends Experience {
    /**
     * An experience has three possible actions.
     * It will always START, and then it will either be a SUCCESS or FAIL.
     */
    action: 'taskStart';
}

interface ExperienceSuccess extends Experience {
    /**
     * An experience has three possible actions.
     * It will always START, and then it will either be a SUCCESS or FAIL.
     */
    action: 'taskSuccess';
}

interface ExperienceFail extends Experience {
    /**
     * An experience has three possible actions.
     * It will always START, and then it will either be a SUCCESS or FAIL.
     */
    action: 'taskFail';

    /**
     * If a experience failure occurred pass through an error object.
     */
    error: ExperienceError;
}

export interface ExperienceError {
    /**
     * Only used for experience failure events.
     * This should be a human readable string for why the error happened.
     */
    errorMessage: string;

    /**
     * Only used for experience failure events.
     * This should be a human readable name for the error.
     */
    errorName: string;

    /**
     * Only used for experience failure events.
     * This should be boolean stating whether the error was handled (expected) or not.
     */
    unhandledError: boolean;

    /**
     * Only used for experience failure events.
     * This should be a human readable string for where the error originated from.
     */
    errorLocation: string;

    /**
     * Optional for experience failure events.
     * This should be xhr traceId for which the error occurred.
     */
    traceId?: string;

    /**
     * Optional for experience failure events.
     * This should contain all the response headers for which the error occurred.
     */
    responseHeaders?: string;

    /**
     * Status code of the error occurred.
     */
    status?: number;
}

export type ExperienceDescription = ExperienceStart | ExperienceSuccess | ExperienceFail;

const createAnalyticsPayload = ({ name, action, experienceId, ...rest }: ExperienceDescription) => {
    const error = 'error' in rest ? rest.error : undefined;

    return {
        action,
        task: name,
        taskId: experienceId,
        reason: error && error.errorMessage,
        ...error,
    };
};

export default (experienceDescription: ExperienceDescription, analyticsEvent: AnalyticsEvent | UIAnalyticsEvent) => {
    const payload = createAnalyticsPayload(experienceDescription);
    analyticsEvent.update(payload);

    fireAnalyticsEvent({
        payload,
        analyticsEvent,
        type: EventTypes.OPERATIONAL,
    });
};
