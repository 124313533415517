import React, { useCallback } from 'react';
import type { KeyboardEvent } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import {
    isUnifiedHelpInJSMEnabled,
    isJsmVirtualAgentOnPortalEnabled,
    isJsmVirtualAgentBannerOnPortalEnabled,
} from 'feature-flags';
import FocusLock from 'react-focus-lock';
import type { State } from 'state';
import type { ShowSearchAction, UpdateSearchAction } from 'state/actions/search';
import { getIsAIAnswersEnabled } from 'state/selectors/env';
import { getProjectId } from 'state/selectors/portal';
import { isLoggedIn as isLoggedInSelector } from 'state/selectors/user';
import type { WithAnalyticsEventsProps } from '@atlaskit/analytics-next';
import Blanket from '@atlaskit/blanket';
import FadeIn from '@atlaskit/motion/fade-in';
import Portal from '@atlaskit/portal';
import { Box, xcss } from '@atlaskit/primitives';
import { media } from '@atlaskit/primitives/responsive';
import { layers } from '@atlaskit/theme/constants';
import { token } from '@atlaskit/tokens';
import { ScrollLock } from '@atlassian/help-center-common-component/scroll-lock';
import { getCloudId } from '@atlassian/help-center-common-util/meta';
import { useVirtualAgentAvailability } from '@atlassian/help-center-virtual-agent/src/services/virtual-agent-availability';
import { VirtualAgentBanner } from '@atlassian/help-center-virtual-agent-banner';
import { getPortalIdAsNumber, isValidSearchTerm, SEARCH_BAR_HEIGHT } from '../common';
import { SearchBar } from '../search-bar';
import { SearchResult } from '../search-result';

interface OwnProps {
    isOpen: boolean;
    term: string;
    portalId?: string;
    onSearch: () => UpdateSearchAction;
    onClose: () => ShowSearchAction;
    onReturnFocus: (_: Element) => boolean;
}

export interface Props extends OwnProps {
    isLoggedIn: boolean;
    projectId: number | undefined;
    isAiAnswersEnabled: boolean;
}

const SearchModal = ({
    isOpen,
    term,
    isAiAnswersEnabled,
    isLoggedIn,
    portalId,
    projectId,
    onSearch,
    onClose,
    onReturnFocus,
}: Props & WithAnalyticsEventsProps) => {
    const showSearchResults = isValidSearchTerm(term);
    const scope = portalId ? 'portal' : 'helpCenter';
    const cloudId = getCloudId();
    const { isVirtualAgentEnabled: isVirtualAgentAvailableForProject } = useVirtualAgentAvailability({
        isLoggedIn,
        cloudId,
        projectId,
    });
    const isVirtualAgentAvailableForPortal =
        scope === 'portal' &&
        isVirtualAgentAvailableForProject &&
        isJsmVirtualAgentOnPortalEnabled() &&
        isJsmVirtualAgentBannerOnPortalEnabled();
    const isVirtualAgentAvailableForHelpCenter =
        scope === 'helpCenter' && isAiAnswersEnabled && isUnifiedHelpInJSMEnabled();

    const onKeyUp = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                onClose();
            }
        },
        [onClose]
    );

    return (
        <FadeIn duration={200} entranceDirection="bottom">
            {(fadeInProps) =>
                isOpen && (
                    <Portal zIndex={layers.modal()}>
                        <div
                            // eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Disabled to rollout go/ui-styling-standard tooling, please resolve
                            style={{ width: '100vw', height: '100vh', position: 'fixed', top: '0px', left: '0px' }}
                            {...fadeInProps}
                        >
                            {/*Disabling existing violations, should be fixed when revisited.*/}
                            {/*eslint-disable-next-line jsx-a11y/no-autofocus*/}
                            <FocusLock autoFocus returnFocus={onReturnFocus}>
                                {/* Ensures scroll events are blocked on the document body and locked */}
                                <ScrollLock />
                                <Blanket shouldAllowClickThrough={false} isTinted>
                                    <Box
                                        // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
                                        xcss={xcss({
                                            // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
                                            height: `calc(100% - ${SEARCH_BAR_HEIGHT})`,
                                        })}
                                        onKeyUp={onKeyUp}
                                    >
                                        <SearchBar term={term} onChange={onSearch} onClose={onClose} />
                                        {showSearchResults && (
                                            <Box
                                                // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
                                                xcss={xcss({
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    position: 'relative',
                                                    height: '100%',
                                                    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
                                                    top: SEARCH_BAR_HEIGHT,
                                                    backgroundColor: 'elevation.surface.overlay',
                                                    paddingTop: token('space.300', '24px'),
                                                    paddingBottom: token('space.200', '16px'),
                                                    overflow: 'auto',
                                                })}
                                            >
                                                <Box
                                                    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
                                                    xcss={xcss({
                                                        width: '90%',
                                                        pointerEvents: 'auto',
                                                        [media.above.sm]: {
                                                            width: '50%',
                                                        },
                                                    })}
                                                >
                                                    {(isVirtualAgentAvailableForPortal ||
                                                        isVirtualAgentAvailableForHelpCenter) && (
                                                        <Box xcss={virtualBannerStyles}>
                                                            <VirtualAgentBanner cloudId={cloudId} portalId={portalId} />
                                                        </Box>
                                                    )}
                                                    <SearchResult term={term} />
                                                </Box>
                                            </Box>
                                        )}
                                    </Box>
                                </Blanket>
                            </FocusLock>
                        </div>
                    </Portal>
                )
            }
        </FadeIn>
    );
};

export default connect(
    createSelector(
        isLoggedInSelector,
        getIsAIAnswersEnabled,
        (state: State, { portalId }: OwnProps) => ({
            portalId,
            projectId: getProjectId(state, getPortalIdAsNumber(portalId)),
        }),
        (isLoggedIn, isAiAnswersEnabled, { portalId, projectId }) => ({
            isLoggedIn,
            isAiAnswersEnabled,
            portalId,
            projectId,
        })
    )
)(SearchModal);

const virtualBannerStyles = xcss({
    marginBottom: token('space.200', '16px'),
});
