import * as React from 'react';
import { FormattedMessage } from 'react-intl';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import { isMultiHcKoshIntegrationEnabled } from 'feature-flags';
import { useIntl } from 'react-intl-next';
import { LazySuspense } from 'react-loosely-lazy';
import { di } from 'react-magnetic-di';
import type { UserRequestsModel } from 'state/persisted/user/reducer';
import type { UserRequestsCounts } from 'state/persisted/user-requests-counts';
import { HEADER_HEIGHT } from 'view/common/styled';
import { Search } from 'view/search';
import * as grid from 'view/styles/grid';
import * as mixins from 'view/styles/mixins';
import { AnalyticsContext } from '@atlaskit/analytics-next';
import { Skeleton } from '@atlaskit/avatar';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { AvatarMenuV2 } from '@atlassian/help-center-avatar-menu';
import { Link } from '@atlassian/help-center-common-component/analytics';
import { Button } from '@atlassian/help-center-common-component/analytics/button';
import type { ButtonProps } from '@atlassian/help-center-common-component/analytics/button';
import { Logo } from '@atlassian/help-center-common-component/logo';
import { getHelpCenterUrl } from '@atlassian/help-center-common-util/url';
import { AvatarMenu } from '../avatar-menu';
import type { Organisations } from '../requests-menu';
import { RequestsMenu } from '../requests-menu';
import i18n from './messages';

export interface Props {
    isAnonymous?: boolean;
    isUserLoaded?: boolean;
    userLoggedIn?: boolean;
    loginUrl?: string;
    logoTextColor: 'banner' | 'theme';
    hideSearchButton?: boolean;
    isCustomizing?: boolean;
    userRequest: UserRequestsModel | UserRequestsCounts; //TODO: remove UserRequestsModel when removing isSplitRequestCountsFromUserModelEnabled
    organisations: Organisations[];
    anonymousEnabled?: boolean;
    isAdvancedHelpCenter?: boolean;
    disableAllHeaderLinks?: boolean;
}

const Header: React.FunctionComponent<Props> = ({
    isAnonymous,
    isUserLoaded,
    userLoggedIn,
    loginUrl,
    logoTextColor,
    hideSearchButton,
    userRequest,
    organisations,
    anonymousEnabled,
    isAdvancedHelpCenter,
    disableAllHeaderLinks,
}) => {
    di(isMultiHcKoshIntegrationEnabled);

    const { formatMessage } = useIntl();
    const defaultLoginUrl = `/user/login?destination=${getHelpCenterUrl().replace(/\//g, '')}`;

    const authenticatedNavLinks = (
        <>
            {!isMultiHcKoshIntegrationEnabled() && (
                <NavItem>
                    <AnalyticsContext data={{ actionSubjectId: 'navbarRequests' }}>
                        <RequestsMenu
                            approvalCount={userRequest.approvalCount || 0}
                            pendingApprovalCount={userRequest.pendingApprovalCount || 0}
                            myRequestCount={userRequest.myRequestCount || 0}
                            organisations={organisations}
                        />
                    </AnalyticsContext>
                </NavItem>
            )}

            <NavItem>
                <AnalyticsContext data={{ actionSubjectId: 'navbarAvatar' }}>
                    {isMultiHcKoshIntegrationEnabled() ? (
                        <AvatarMenuV2 disableAvatarMenu={disableAllHeaderLinks} />
                    ) : (
                        <AvatarMenu disableAvatarMenu={disableAllHeaderLinks} />
                    )}
                </AnalyticsContext>
            </NavItem>
        </>
    );

    const unauthenticatedNavLinks = (
        <NavItem>
            <ExternalNavLink>
                <LoginButton href={loginUrl ? loginUrl : defaultLoginUrl} actionSubjectId="navbarLogin">
                    <FormattedMessage {...i18n.login} />
                </LoginButton>
            </ExternalNavLink>
        </NavItem>
    );

    const anonymousAccessNotEnbledAndUserNotLoggedIn = !(!!anonymousEnabled || !!userLoggedIn);

    let portalLinkUrl;
    if (isAnonymous || anonymousAccessNotEnbledAndUserNotLoggedIn) {
        portalLinkUrl = defaultLoginUrl;
    } else portalLinkUrl = getHelpCenterUrl();

    return (
        <AnalyticsContext data={{ source: 'navbar' }}>
            <Root isAdvancedHelpCenter={Boolean(isAdvancedHelpCenter)}>
                <PortalLink
                    to={portalLinkUrl}
                    logoTextColor={logoTextColor}
                    actionSubjectId="navbarHelpCenter"
                    disabled={Boolean(disableAllHeaderLinks)}
                    tabIndex={disableAllHeaderLinks ? -1 : undefined}
                    aria-label={formatMessage(i18n.login)}
                >
                    <LazySuspense fallback={<Skeleton appearance="square" size="medium" />}>
                        <Logo isAdvancedHelpCenter={Boolean(isAdvancedHelpCenter)} />
                    </LazySuspense>
                </PortalLink>
                <Nav>
                    <NavList>
                        {isUserLoaded && (
                            <>
                                {hideSearchButton || (
                                    <NavItem>
                                        <Search trigger="icon" disableSearch={disableAllHeaderLinks} />
                                    </NavItem>
                                )}

                                {userLoggedIn ? authenticatedNavLinks : unauthenticatedNavLinks}
                            </>
                        )}
                    </NavList>
                </Nav>
            </Root>
        </AnalyticsContext>
    );
};

export default Header;

// Nav line height set to 0 so it is correctly vertically aligned.
const NAV_LINE_HEIGHT = 0;
// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const Root = styled.header<{ isAdvancedHelpCenter: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: ${token('space.100', '8px')};
    padding-right: ${token('space.100', '8px')};
    height: ${HEADER_HEIGHT}px;
    overflow: hidden;
    ${(props) => {
        return props.isAdvancedHelpCenter ? `background-color: ${props.theme.color}` : '';
    }}
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const Nav = styled.nav`
    /* Ensures header icons are vertically aligned consistently accross browsers */
    line-height: ${NAV_LINE_HEIGHT};
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const NavList = styled.ul`
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const NavItem = styled.li`
    display: inline;
    margin: 0;
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const LoginButton = styled((props: Omit<ButtonProps, 'theme'>) => <Button {...props} />)`
    && {
        padding-left: ${token('space.150', '12px')};
        padding-right: ${token('space.150', '12px')};
    }
`;

interface PortalLinkProps {
    logoTextColor: 'banner' | 'theme';
}

// We need to override this because ADG button is transparent and not actually grey.
// This makes sure you can see the button against the banner.
// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const ExternalNavLink = styled.div`
    ${mixins.subtleThemeColorBackground};
    border: 2px ${token('color.border.inverse', colors.N0)} solid;
    border-radius: ${grid.multiple(2).px};
    margin-left: ${token('space.100', '8px')};
`;

interface PortalLinkProps {
    logoTextColor: 'banner' | 'theme';
    disabled?: boolean;
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-dynamic-styles -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const PortalLink = styled<React.ComponentProps<typeof Link> & PortalLinkProps>(({ logoTextColor, ...props }) => (
    <Link {...props} />
)).attrs({
    className: 'cv-exclude-from-customisation',
})`
    /* stylelint-disable scale-unlimited/declaration-strict-value */
    color: ${(props) =>
        props.logoTextColor === 'banner' ? props.theme.titleColor : token('color.link', props.theme.color)};

    &:hover {
        color: ${(props) =>
            props.logoTextColor === 'banner' ? props.theme.titleColor : token('color.link', props.theme.color)};
    }
    pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
`;
