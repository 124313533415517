import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { fetchHelpCenterAction } from 'epics/model/help-center';
import { editingHelpCenter } from 'state/actions/help-center-preview';
import { getIsPortalHidingEnabled } from 'state/selectors/featured-and-sorted-portals';
import {
    getIsLoaded,
    getSharedPortalName,
    getCanCustomize,
    getHelpCenterTitle,
    getHelpCenterTitleColor,
} from 'state/selectors/help-center';
import { isCustomizing } from 'state/selectors/help-center-preview';
import { getShown as getSearchShown } from 'state/selectors/search';
import { getCanAdministerJIRA } from 'state/selectors/user';
import { withExperience } from '@atlassian/help-center-common-component/analytics/experience';
import { ExperienceName } from '@atlassian/help-center-common-component/constants';
import { LazyPushingCustomizeSidebar } from '@atlassian/help-center-common-component/pushing-customize-sidebar';
import HelpCenterComponent from './help-center';

const selector = createSelector(
    getIsLoaded,
    getSharedPortalName,
    getCanCustomize,
    getHelpCenterTitle,
    getHelpCenterTitleColor,
    // Do not remove getSearchShown here, even it it's unused.
    // It affects the render order which the search animation relies upon.
    // No longer required with react-redux 6.0.0: see FSD-3282
    getSearchShown,
    isCustomizing,
    getCanAdministerJIRA,
    getIsPortalHidingEnabled,
    (
        isLoaded,
        sharedPortalName,
        canCustomize,
        helpCenterTitle,
        helpCenterTitleColor,
        searchBarShown,
        customizing,
        canAdministerJira,
        isPortalHidingEnabled
    ) => ({
        isPortalHidingEnabled,
        sharedPortalName,
        helpCenterTitle,
        searchBarShown,
        canAdministerJira,
        PushingCustomizeSidebar: LazyPushingCustomizeSidebar,
        isLoading: !isLoaded,
        titleColor: helpCenterTitleColor,
        showCustomizeSidebar: canCustomize,
        isCustomizing: customizing,
    })
);

export const HelpCenter = connect(selector, {
    fetchHelpCenter: fetchHelpCenterAction,
    onStartEditing: () => editingHelpCenter(true),
    onFinishEditing: () => editingHelpCenter(false),
})(withExperience(ExperienceName.HELP_CENTER)(HelpCenterComponent));
