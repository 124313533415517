/**
 * DO NOT EDIT.
 *
 * This is Relay generated artifact.
 *
 * Please reach out to #jsm-cloud-megatron for any questions.
 *
 * @generated SignedSource<<4e37ce1f1f2aa54904966f370a49356a>>
 * @relayHash 1683779b81d43204719a73f53f23365c
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 999005e43cbeb1c4c64fbe7542e85e88276482ab1906d61fe9b6405f73961c96

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type HelpCenterSortOrder = "CREATED_DATE_ASCENDING" | "CREATED_DATE_DESCENDING" | "%future added value";
export type helpCentersManagementPageQuery$variables = {
  NUMBER_OF_HELP_CENTERS_INITIAL_FETCH: number;
  SORT_ORDER: HelpCenterSortOrder;
  cloudId: string;
  workspaceAri: string;
};
export type helpCentersManagementPageQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"helpCentersManagementPageFragment">;
};
export type helpCentersManagementPageQuery = {
  response: helpCentersManagementPageQuery$data;
  variables: helpCentersManagementPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "NUMBER_OF_HELP_CENTERS_INITIAL_FETCH"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "SORT_ORDER"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workspaceAri"
},
v4 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v5 = {
  "kind": "Variable",
  "name": "workspaceAri",
  "variableName": "workspaceAri"
},
v6 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "NUMBER_OF_HELP_CENTERS_INITIAL_FETCH"
  },
  {
    "kind": "Variable",
    "name": "sortOrder",
    "variableName": "SORT_ORDER"
  },
  (v5/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v11 = {
  "kind": "InlineFragment",
  "selections": [
    (v8/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "helpCentersManagementPageQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "NUMBER_OF_HELP_CENTERS_INITIAL_FETCH",
            "variableName": "NUMBER_OF_HELP_CENTERS_INITIAL_FETCH"
          },
          {
            "kind": "Variable",
            "name": "SORT_ORDER",
            "variableName": "SORT_ORDER"
          },
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "helpCentersManagementPageFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "helpCentersManagementPageQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          (v4/*: any*/)
        ],
        "concreteType": "HelpCenterQueryApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "HelpCenterQueryResultConnection",
            "kind": "LinkedField",
            "name": "helpCenters",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "HelpCenterQueryResultEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "HelpCenterName",
                            "kind": "LinkedField",
                            "name": "name",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "default",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "HelpCenterTranslation",
                                "kind": "LinkedField",
                                "name": "translations",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "locale",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "value",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "HelpCenterBranding",
                            "kind": "LinkedField",
                            "name": "helpCenterBranding",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isBannerAvailable",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "useDefaultBanner",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "HelpCenterBrandingColors",
                                "kind": "LinkedField",
                                "name": "colors",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "primary",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "HelpCenterBanner",
                                "kind": "LinkedField",
                                "name": "banner",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "url",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastUpdated",
                            "storageKey": null
                          }
                        ],
                        "type": "HelpCenter",
                        "abstractKey": null
                      },
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "message",
                        "storageKey": null
                      }
                    ],
                    "type": "QueryError",
                    "abstractKey": null
                  },
                  (v11/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v6/*: any*/),
            "filters": [],
            "handle": "connection",
            "key": "helpCentersManagementPageFragment_helpCenters",
            "kind": "LinkedHandle",
            "name": "helpCenters"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "999005e43cbeb1c4c64fbe7542e85e88276482ab1906d61fe9b6405f73961c96",
    "metadata": {},
    "name": "helpCentersManagementPageQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "41981756243472ea911db08bfdfe91d7";

export default node;
