import React, { useEffect } from 'react';
import { sanitize } from 'dompurify';
import { useIntl } from 'react-intl-next';
import { graphql, useFragment } from 'react-relay';
import { usePathParam } from 'react-resource-router';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { withAnalyticsEvents } from '@atlaskit/analytics-next';
import ShortcutIcon from '@atlaskit/icon/glyph/shortcut';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { urlRefsAndTarget } from '@atlassian/help-center-common-util/is-external-resource';
import articleMessages from '../articles/messages';
import { Title, LinkContainer, Snippet, formatText, sendSearchUIAnalyticEvents } from '../common';
import messages from '../request-forms/messages';
import { ResourceIcon } from '../resource-icon';
import type { requestFormItemFragment$key } from './__generated__/requestFormItemFragment.graphql';

export interface Props {
    itemOrder: number;
    term: string;
    result: requestFormItemFragment$key;
    createAnalyticsEvent?: CreateUIAnalyticsEvent;
}

export const RequestFormItem = ({ itemOrder, result, term, createAnalyticsEvent }: Props) => {
    const { formatMessage } = useIntl();
    const data = useFragment<requestFormItemFragment$key>(
        graphql`
            fragment requestFormItemFragment on HelpObjectStoreSearchResult {
                __typename @required(action: THROW)
                id @required(action: THROW)
                title @required(action: THROW)
                description @required(action: THROW)
                displayLink @required(action: THROW)
                containerDisplayName
                isExternal @required(action: THROW)
                searchBackend
                searchAlgorithm
                ...resourceIconFragment
            }
        `,
        result
    );
    const [portalId] = usePathParam('portalId');
    const { title, description, displayLink, isExternal, id, containerDisplayName, searchBackend, searchAlgorithm } =
        data;
    const shortcutIcon = isExternal ? (
        <ShortcutIcon label={formatMessage(articleMessages.shortcutIcon)} size="small" />
    ) : null;
    const highlightedTitle = title ? formatText(title, term) : '';

    const getDescription = () => {
        if (!containerDisplayName) {
            return formatText(description, term);
        }
        return `${formatMessage(messages.portal)}: ${formatText(containerDisplayName, term)}`;
    };

    const highlightedDescription = getDescription();
    const linkProps = urlRefsAndTarget({ displayLink, isAnExternalResource: isExternal });

    useEffect(() => {
        sendSearchUIAnalyticEvents(
            {
                searchBackend,
                searchAlgorithm,
                action: 'list-item-rendered',
                resourceType: 'request-form',
            },
            createAnalyticsEvent,
            portalId
        );
    }, [createAnalyticsEvent, portalId, searchAlgorithm, searchBackend]);

    return (
        <LinkContainer
            key={id}
            actionSubjectId={isExternal ? 'external-request-form-link' : 'request-form-link'}
            data-test-id="result-link-container"
            attributes={{
                itemOrder,
                searchBackend,
                searchAlgorithm,
            }}
            {...linkProps}
        >
            <ResourceIcon result={data} />
            <Box
                // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
                xcss={xcss({
                    overflow: 'hidden',
                    width: '100%',
                    paddingLeft: token('space.200', '16px'),
                    paddingRight: token('space.200', '16px'),
                })}
            >
                <Title dangerouslySetInnerHTML={{ __html: sanitize(highlightedTitle) }} />
                <Snippet dangerouslySetInnerHTML={{ __html: sanitize(highlightedDescription) }} />
            </Box>
            {shortcutIcon}
        </LinkContainer>
    );
};

export default withAnalyticsEvents()(RequestFormItem);
