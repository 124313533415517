import * as React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';

import * as bp from 'view/styles/breakpoints';
import * as grid from 'view/styles/grid';
import * as mixins from 'view/styles/mixins';
import { token } from '@atlaskit/tokens';

const HelpCenterNoPortal: React.FunctionComponent = () => {
    return <Root id="no-portal-content"></Root>;
};

export default HelpCenterNoPortal;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const Root = styled.div`
    ${mixins.borderBox};
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    margin-top: ${token('space.400', '32px')};
    margin-bottom: ${grid.multiple(7.4).px};
    padding: 0 ${token('space.400', '32px')};
    width: 100%;

    ${bp.fromSmall.css`
        padding: ${token('space.200', '16px')} 0 0 0;
    `};
`;
