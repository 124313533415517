import * as React from 'react';
import type { InjectedIntlProps } from 'react-intl';
import { injectIntl } from 'react-intl';

import { SidebarToggle } from 'util/sidebar-toggle';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import { isMultiHcKoshIntegrationEnabled, isSharedPortalCodeRemoved } from 'feature-flags';
import { LazySuspense } from 'react-loosely-lazy';
import { BANNER_HEIGHT, HEADER_HEIGHT } from 'view/common/styled';
import { HelpCenterCustomizeSidebar } from 'view/help-center-customize-sidebar';
import { Search } from 'view/search';
import * as bp from 'view/styles/breakpoints';
import * as fonts from 'view/styles/fonts';
import { sizes } from 'view/styles/sizes-viewport';
import { token } from '@atlaskit/tokens';
import { EngagementMessages } from '@atlassian/help-center-common-component/constants';
import { DocumentTitle } from '@atlassian/help-center-common-component/document-title';
import { LoadingContainer } from '@atlassian/help-center-common-component/loading-container';
import type { PushingCustomizeSidebarProps } from '@atlassian/help-center-common-component/pushing-customize-sidebar/types';
import type { InjectedCoordinationClientProps } from '@atlassian/help-center-common-util/coordination-client-provider';
import { withCoordinationClient } from '@atlassian/help-center-common-util/coordination-client-provider';
import { clearSidebarExperienceAndCustomize } from '@atlassian/help-center-common-util/sidebar-experience';
import UFOLoadHold from '@atlassian/ufo-load-hold';
import { LazyHelpCenterBucketsRoutes } from '../help-center-customize-sidebar/bucket-routes';
import { HelpCenterContainer } from './container';
import { HelpCenterContent } from './help-center-content';
import messages from './messages';
import type { WithScreenSizeProps } from './use-screen-size';
import { withMatchScreenSize } from './use-screen-size';

export interface HelpCenterProps extends InjectedIntlProps, WithScreenSizeProps {
    helpCenterTitle?: string;
    titleColor?: string;
    showCustomizeSidebar: boolean;
    sharedPortalName: string | undefined;
    onStartEditing: () => void;
    onFinishEditing: () => void;
    fetchHelpCenter: () => void;
    isLoading: boolean;
    isCustomizing: boolean;
    searchBarShown: boolean;
    canAdministerJira: boolean;
    isPortalHidingEnabled: boolean;
    PushingCustomizeSidebar: React.ComponentType<PushingCustomizeSidebarProps>;
}

interface ModifiedHTMLElement extends HTMLElement {
    ref: HTMLElement | null;
}

export interface State {
    showPulse: boolean;
}

const ContitionalLoadingContainer = (props: { children: React.ReactNode; isLoading: boolean }) =>
    isSharedPortalCodeRemoved() ? (
        <>{props.children}</>
    ) : (
        <LoadingContainer isLoading={props.isLoading}>{props.children}</LoadingContainer>
    );
export class HelpCenter extends React.Component<HelpCenterProps & InjectedCoordinationClientProps, State> {
    ref: HTMLElement | null;

    state = {
        showPulse: false,
    };

    handleChangeboarding = async () => {
        const client = this.props.coordinationClient;

        if (client) {
            const showPulse = await client.start(EngagementMessages.TOPICS_FOR_ALL);
            if (showPulse) {
                this.setState({ showPulse });
            }
        }
    };

    componentDidMount() {
        const { canAdministerJira, fetchHelpCenter, isMediaQueryMatch: isSmallScreenSize } = this.props;

        if (!isSharedPortalCodeRemoved()) {
            fetchHelpCenter();
        }
        if (canAdministerJira && !isSmallScreenSize) {
            // Disabling existing violations, should be fixed when revisited.
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            this.handleChangeboarding();
        }
    }

    componentDidUpdate(prevProps: HelpCenterProps & InjectedCoordinationClientProps) {
        const { coordinationClient: prevCoordinationClient } = prevProps;
        const { canAdministerJira, coordinationClient, isMediaQueryMatch: isSmallScreenSize } = this.props;

        if (prevCoordinationClient === undefined && Boolean(coordinationClient)) {
            if (canAdministerJira && !isSmallScreenSize) {
                // Disabling existing violations, should be fixed when revisited.
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                this.handleChangeboarding();
            }
        }
    }

    handleFinishEditing = () => {
        clearSidebarExperienceAndCustomize();
        this.props.onFinishEditing();
        this.setFocus();
    };

    setFocus() {
        this.ref && this.ref.focus();
    }

    onRef = (el: ModifiedHTMLElement | null) => {
        // https://github.com/styled-components/styled-components/issues/618
        this.ref = el && el.ref;
    };

    onOpen = () => {
        this.setState({ showPulse: false });
        this.props.onStartEditing();
    };

    render() {
        const {
            helpCenterTitle,
            titleColor,
            showCustomizeSidebar,
            sharedPortalName,
            intl,
            isCustomizing,
            isLoading,
            PushingCustomizeSidebar,
            searchBarShown,
            isMediaQueryMatch: isSmallScreenSize,
        } = this.props;
        const { showPulse } = this.state;

        const title = intl.formatMessage(messages.helpCenterTitle, { helpCenterTitle: sharedPortalName });

        return (
            <DocumentTitle title={title} ignoreBaseTitle>
                <ContitionalLoadingContainer isLoading={isLoading}>
                    {showCustomizeSidebar && (
                        <LazySuspense fallback={<UFOLoadHold name="customize-sidebar-loader" />}>
                            {!searchBarShown ? (
                                <PushingCustomizeSidebar
                                    isOpen={isCustomizing}
                                    onOpen={this.onOpen}
                                    showPulse={showPulse && !isSmallScreenSize}
                                    onClose={this.handleFinishEditing}
                                    hideSidebarToggleButton={isMultiHcKoshIntegrationEnabled()}
                                >
                                    {!isSmallScreenSize ? (
                                        <HelpCenterBucketsContainer>
                                            <LazyHelpCenterBucketsRoutes
                                                isOpen={isCustomizing}
                                                onClose={this.handleFinishEditing}
                                            />
                                        </HelpCenterBucketsContainer>
                                    ) : (
                                        <HelpCenterCustomizeSidebar
                                            isOpen={isCustomizing}
                                            onClose={this.handleFinishEditing}
                                        />
                                    )}
                                </PushingCustomizeSidebar>
                            ) : null}
                        </LazySuspense>
                    )}
                    {isMultiHcKoshIntegrationEnabled() ? (
                        <SidebarToggle openSidebar={this.onOpen} closeSidebar={this.handleFinishEditing} />
                    ) : null}

                    <SearchContainer>
                        <HelpCenterTitle titleColor={titleColor}>{helpCenterTitle}</HelpCenterTitle>
                        <Search />
                    </SearchContainer>

                    <HelpCenterContent isPortalHidingEnabled={this.props.isPortalHidingEnabled} />
                </ContitionalLoadingContainer>
            </DocumentTitle>
        );
    }
}

export default withCoordinationClient(injectIntl(withMatchScreenSize(sizes.medium)(HelpCenter)));

const SEARCH_CONTAINER_PADDING = token('space.600', '48px');

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const SearchContainer = styled(HelpCenterContainer)`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-top: ${SEARCH_CONTAINER_PADDING};
    padding-bottom: ${SEARCH_CONTAINER_PADDING};
    height: ${BANNER_HEIGHT.unitless - HEADER_HEIGHT}px;
`;

interface HelpCenterTitleProps {
    titleColor?: string;
}

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const HelpCenterTitle = styled.h1<HelpCenterTitleProps>`
    ${fonts.h700};
    margin-bottom: ${token('space.100', '8px')};
    color: ${(props) => (!!props.titleColor ? props.titleColor : props.theme.readableTextColor)};
    text-align: center;

    ${bp.fromXXSmall.css`
        text-align: left;
    `};
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const HelpCenterBucketsContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`;
