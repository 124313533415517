import { Observable } from 'rxjs';

import { put } from 'epics/ajax';

import { contextPath } from '@atlassian/help-center-common-util/history';

import type { LoginAnnouncementsUpdateRequestPayload } from './types';

export const updateLoginAnnouncements = (payload: LoginAnnouncementsUpdateRequestPayload, context = contextPath) => {
    const uri = `${context}/rest/servicedesk/1/servicedesk-data/signInPageAnnouncements`;
    return (
        put(uri, payload, { 'Content-Type': 'application/json' })
            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            .map(({ response }) => response)
            .catch((e) => Observable.throw(e))
    );
};
