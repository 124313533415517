import * as React from 'react';
import type { updateEnvAction } from 'state/actions/env';
import type { updateMetaAction } from 'state/actions/meta';

import { DEFAULT_HELP_CENTER_TYPE } from '@atlassian/help-center-common-component/constants';
import type { Env } from '@atlassian/help-center-common-util/env';
import type { MetaData } from '@atlassian/help-center-common-util/meta';
import type { AtlassianHelpConfig } from 'types';

interface MetaDataProviderProps {
    children: React.ReactNode;
    config: AtlassianHelpConfig;
    updateEnvAction: typeof updateEnvAction;
    updateMetaAction: typeof updateMetaAction;
}

export const getMetaFromConfig = (config: AtlassianHelpConfig): MetaData => ({
    accountId: config.accountId,
    accountType: config.accountType,
    userLocale: config.userLocale,
    sdPluginVersion: config.sdPluginVersion || '',
    contextPath: config.contextPath,
    environment: config.environment,
    tenantId: config.tenantId,
    activationId: config.activationId,
    helpCenterARI: config.helpCenterARI,
});
export const getEnvFromConfig = (config: AtlassianHelpConfig): Env => ({
    environment: config.environment,
    featureFlags: config.featureFlags || {},
    wacLink: config.wacLink,
    contactLink: config.contactLink,
    headless: config.headless || false,
    serviceDeskVersion: config.serviceDeskVersion || '',
    contextPath: config.contextPath,
    xsrfToken: config.xsrfToken || '',
    baseUrl: config.baseUrl,
    relativeBaseUrl: config.relativeBaseUrl,
    anonymousEnabled: config.anonymousEnabled || false,
    requestIntroEnabled: config.requestIntroEnabled || false,
    mediaApiExternalUrl: config.mediaApiExternalUrl || '',
    mediaApiAppClientId: config.mediaApiAppClientId || '',
    fabricEmojiUrl: config.fabricEmojiUrl || '',
    jiraDisplayUrl: config.jiraDisplayUrl,
    featuredValues: config.featuredValues || {},
    siteDefaultLanguageTag: config.siteDefaultLanguageTag || 'en-US',
    helpCenterType: config.helpCenterType || DEFAULT_HELP_CENTER_TYPE,
    hoistedProjectKey: config.hoistedProjectKey || undefined,
});

export const AtlassianHelpConfigContext = React.createContext<AtlassianHelpConfig>({} as AtlassianHelpConfig);

// TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
// eslint-disable-next-line no-shadow
const MetaDataProvider = ({ children, config, updateEnvAction, updateMetaAction }: MetaDataProviderProps) => {
    // We can add React context here in the future once relay is introduced and get rid of reducers altogether
    // TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
    // eslint-disable-next-line no-shadow
    const Env = getEnvFromConfig(config);
    const Meta = getMetaFromConfig(config);

    React.useMemo(() => {
        updateMetaAction(Meta);
        updateEnvAction(Env);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Env, Meta]);
    return <AtlassianHelpConfigContext.Provider value={config}>{children}</AtlassianHelpConfigContext.Provider>;
};

export default MetaDataProvider;
