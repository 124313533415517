import { getMeta } from 'util/meta';
import { setActiveTrace } from '@atlassian/ufo-experience-trace-id-context';

let alreadySet = false;

export const retrieveAndSetPageLoadTrace = () => {
    if (__SERVER__ || alreadySet) return;
    const traceId = getMeta('atl-b3-trace-id') || '';
    const spanId = traceId?.substring(traceId.length - 16) || ''; // set root span ID by convention

    alreadySet = true;

    setActiveTrace(traceId, spanId, 'page_load');
};
