import { isMultiHcKoshIntegrationEnabled } from 'feature-flags';
import { get, set } from '@atlassian/help-center-common-util/history-qs';

export const getSidebarExperienceName = () => {
    if (isMultiHcKoshIntegrationEnabled()) {
        return get('experience');
    }

    return null;
};

export const clearSidebarExperienceAndCustomize = () => {
    if (isMultiHcKoshIntegrationEnabled()) {
        set({ customize: undefined, experience: undefined });
    }
};
