import { isKoshEnabledForDefaultHelpCenter } from 'util/advanced-help-center';
import { createSelector } from 'reselect';
import { isMultiHcKoshIntegrationEnabled } from 'feature-flags';
import type {
    FeaturedPortalSummary,
    PortalSummary,
    SortBy,
    UnFeaturedPortalSummary,
} from 'rest/featured-and-sorted-portals';
import type { State } from 'state';
import type { PortalSummaries } from 'state/persisted/featured-and-sorted-portals/reducer';
import type { PersistedError } from 'state/persisted/types';
const MAX_PORTAL_CARDS = 9;

const getPersistedPortals = (state: State): PortalSummaries => {
    return state.persisted.featuredAndSortedPortals.portals;
};

const nanToZero = (rank: number | undefined) => rank || 0;

export const getFeaturedPortalsInSortedOrderByRank = (portals: PortalSummaries): FeaturedPortalSummary[] => {
    return Object.values(portals)
        .filter((portal) => isFeaturedPortal(portal))
        .sort((portal1, portal2) => nanToZero(portal1.rank) - nanToZero(portal2.rank));
};

export const isFeaturedPortal = (portal: PortalSummary): portal is FeaturedPortalSummary =>
    'isFeatured' in portal && portal.isFeatured;

export const isHiddenPortal = (portal: PortalSummary): portal is FeaturedPortalSummary | UnFeaturedPortalSummary =>
    'hiddenFromHomeScreen' in portal && !!portal.hiddenFromHomeScreen;

export const isUnFeaturedPortal = (portal: PortalSummary): portal is UnFeaturedPortalSummary =>
    'isFeatured' in portal && !portal.isFeatured;

export const getPersistedOrderedPortalIds = (state: State): number[] => {
    return state.persisted.featuredAndSortedPortals.orderedPortalIds;
};

export const getUIFeaturedPortalIds = (state: State): number[] => {
    return state.ui.featuredAndSortedPortals.featuredPortalIds;
};

export const getUIHiddenPortalIds = (state: State): number[] => {
    return state.ui.featuredAndSortedPortals.hiddenPortalIds;
};

export const getUIOrderedPortalIds = (state: State): number[] => {
    return state.ui.featuredAndSortedPortals.orderedPortalIds;
};

export const getUISortBy = (state: State): SortBy => {
    return state.ui.featuredAndSortedPortals.sortBy;
};

export const getPortals = (state: State): PortalSummaries => {
    return state.persisted.featuredAndSortedPortals.portals;
};

export const getSortBy = (state: State): SortBy => {
    return state.persisted.featuredAndSortedPortals.sortBy;
};

export const getLimit = (state: State): number => {
    return state.persisted.featuredAndSortedPortals.limit;
};

export const getIsDirty = (state: State): boolean => {
    return state.ui.featuredAndSortedPortals.isDirty;
};

export const getTotal = (state: State): number => {
    return state.persisted.featuredAndSortedPortals.total;
};

export const getfeaturedAndSortedPortalsError = (state: State): PersistedError['error'] | undefined => {
    return state.persisted.featuredAndSortedPortals.error;
};

export const getIsReorderingExperienceLoading = (state: State): boolean => {
    return state.ui.featuredAndSortedPortals.isReorderingExperienceLoading;
};

export const getIsSortedDataLoading = (state: State): boolean => {
    return state.ui.featuredAndSortedPortals.isSortedDataLoading;
};

export const getIsSaveInProgress = (state: State): boolean => {
    return state.ui.featuredAndSortedPortals.isSaveInProgress;
};

export const getIsPortalReorderingDataLoaded = (state: State): boolean => {
    return state.ui.featuredAndSortedPortals.isPortalReorderingDataLoaded;
};

export const getDefaultViewLoading = (state: State): boolean => {
    return state.ui.featuredAndSortedPortals.isDefaultViewLoading;
};

export const getExpandedViewLoading = (state: State): boolean => {
    return state.ui.featuredAndSortedPortals.isExpandedViewLoading;
};

export const getIsPortalHidingEnabled = (state: State): boolean | undefined => {
    return state.persisted.featuredAndSortedPortals.isPortalHidingEnabled;
};

/**
 * Currently used by request list filters
 */
export const getAllVisiblePortals = createSelector(getPersistedPortals, (persistedPortals) => {
    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
    return Object.values(persistedPortals).filter((portal) => !portal.isPortalNotVisibleOnHelpCentre);
});

export const getAllVisiblePortalsInHelpCenter = createSelector(getAllVisiblePortals, (allVisiblePortals) => {
    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return Object.values(allVisiblePortals).filter((portal) => !isHiddenPortal(portal));
});

/* In case there are no featuredPortals then return at max 6 other portals */
export const getDefaultViewPortals = createSelector(
    getPersistedPortals,
    getPersistedOrderedPortalIds,
    (persistedPortals, persistedOrderedPortalIds) => {
        const featuredPortals = getFeaturedPortalsInSortedOrderByRank(persistedPortals)
            .filter((portal) => !portal.isPortalNotVisibleOnHelpCentre)
            .filter((portal) => !isHiddenPortal(portal));

        if (featuredPortals.length > 0) return featuredPortals;
        return persistedOrderedPortalIds
            .filter((portalId) => !persistedPortals[portalId].isPortalNotVisibleOnHelpCentre)
            .map((portalId) => persistedPortals[portalId])
            .filter((portal) => !isHiddenPortal(portal))
            .slice(0, Math.min(persistedOrderedPortalIds.length, MAX_PORTAL_CARDS));
    }
);

export const getTotalVisiblePortalCount = createSelector(
    getPersistedPortals,
    getPersistedOrderedPortalIds,
    (persistedPortals, persistedOrderedPortalIds) => {
        if (isMultiHcKoshIntegrationEnabled() && isKoshEnabledForDefaultHelpCenter()) {
            return persistedOrderedPortalIds.length;
        }
        return persistedOrderedPortalIds.filter(
            (portalId) => !persistedPortals[portalId].isPortalNotVisibleOnHelpCentre
        ).length;
    }
);

export const getCurrentNonHiddenPortalCount = createSelector(
    getPersistedPortals,
    getPersistedOrderedPortalIds,
    (persistedPortals, persistedOrderedPortalIds) => {
        return persistedOrderedPortalIds
            .filter((portalId) => !persistedPortals[portalId]?.isPortalNotVisibleOnHelpCentre)
            .map((portalId) => persistedPortals[portalId])
            .filter((portal) => !isHiddenPortal(portal)).length;
    }
);

export const getExpandedViewPortals = createSelector(
    getPersistedPortals,
    getDefaultViewPortals,
    getPersistedOrderedPortalIds,
    (persistedPortals, defaultViewPortals, persistedOrderedPortalIds) => {
        return persistedOrderedPortalIds
            .filter(
                (portalId) =>
                    !defaultViewPortals.some((defaultViewPortal) => portalId === defaultViewPortal.id) &&
                    !persistedPortals[portalId].isPortalNotVisibleOnHelpCentre
            )
            .map((portalId) => persistedPortals[portalId])
            .filter((portal) => !isHiddenPortal(portal));
    }
);

export const getFeaturedPortals = createSelector(getPersistedPortals, (persistedPortals) => {
    return getFeaturedPortalsInSortedOrderByRank(persistedPortals);
});

export const getVisibleFeaturedPortalsCount = createSelector(getFeaturedPortals, (featuredPortals) => {
    return featuredPortals.filter((portal) => !portal.isPortalNotVisibleOnHelpCentre).length;
});

export const getUnFeaturedPortals = createSelector(
    getPersistedPortals,
    getPersistedOrderedPortalIds,
    (persistedPortals, persistedOrderedPortalIds) => {
        return persistedOrderedPortalIds.map((portalId) => persistedPortals[portalId]).filter(isUnFeaturedPortal);
    }
);

export const getFeaturedPortalsUI = createSelector(
    getUIFeaturedPortalIds,
    getPersistedPortals,
    getUIHiddenPortalIds,
    (uiFeaturedPortalIds, persistedPortals, uiHiddenPortalIds): FeaturedPortalSummary[] => {
        return uiFeaturedPortalIds.map((portalId) => {
            const portal = persistedPortals[portalId];
            return {
                ...portal,
                isFeatured: true,
                rank: isFeaturedPortal(portal) ? portal.rank : -1,
                hiddenFromHomeScreen: uiHiddenPortalIds.includes(portalId),
            };
        });
    }
);

export const getUnFeaturedPortalsUI = createSelector(
    getUIOrderedPortalIds,
    getUIFeaturedPortalIds,
    getPersistedPortals,
    getUIHiddenPortalIds,
    (uiOrderedPortalIds, uiFeaturedPortalIds, persistedPortals, uiHiddenPortalIds): UnFeaturedPortalSummary[] => {
        return uiOrderedPortalIds
            .filter((portalId) => !uiFeaturedPortalIds.includes(portalId))
            .map((portalId) => ({
                ...persistedPortals[portalId],
                isFeatured: false,
                rank: -1,
                hiddenFromHomeScreen: uiHiddenPortalIds.includes(portalId),
            }));
    }
);
