import * as React from 'react';
import type { InjectedIntlProps } from 'react-intl';
import { injectIntl } from 'react-intl';
import { styled } from '@compiled/react';
import { isWrmOptimizationsEnabled, isUnifiedHelpReportingPageEnabled } from 'feature-flags';
import { ForgeActionModule } from 'view/forge-ui/forge-action';
import * as grid from 'view/styles/grid';
import { AnalyticsContext } from '@atlaskit/analytics-next';
import type { OnOpenChangeArgs } from '@atlaskit/dropdown-menu';
import DropdownMenu, { DropdownItemGroup, DropdownItem as AtlasKitDropdownItem } from '@atlaskit/dropdown-menu';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { DropdownItem } from '@atlassian/help-center-common-component/analytics';
import { initializeConnect } from '@atlassian/help-center-common-component/connect-fragment';
import {
    USER_MENU_ACTION_COMPONENT,
    USER_MENU_ACTION_SECTION_MODULE,
} from '@atlassian/help-center-common-component/forge-ui/constants';
import { ForgeModalContext } from '@atlassian/help-center-common-component/forge-ui/modal-context';
import { ForgeModalContextProvider } from '@atlassian/help-center-common-component/forge-ui/modal-context-provider';
import type { SetExtensionToDisplayType } from '@atlassian/help-center-common-component/forge-ui/types';
import { InteractiveElement } from '@atlassian/help-center-common-component/interactive-element';
import { withDeferredWrmLoaded } from '@atlassian/help-center-common-util/wrm';
import { NoUnderlineLink } from '../styled';
import { UserAvatar } from '../user-avatar';
import i18n from './messages';

export interface CustomMenuLinkProps {
    id: string | undefined;
    key: string | undefined;
    label: string | undefined;
    href: string;
    styleClass: string | undefined;
}

export interface StateProps {
    avatarSrc: string | undefined;
    customMenuLinks: CustomMenuLinkProps[];
    canAdministerJira: boolean | undefined;
    jsmAiConfigEnabled: boolean | undefined;
    disableAvatarMenu?: boolean;
}

export interface DispatchProps {
    onLogoutClick: AnyFunction;
    onOpenAndClose?: (e: { isOpen: boolean }) => void;
}

export type Props = StateProps & DispatchProps & InjectedIntlProps;

const profileLink = (intl: ReactIntl.InjectedIntl) => {
    return (
        <NoUnderlineLink data-test-id="profile-link" to="/user/profile" actionSubjectId="navbarProfile">
            <AtlasKitDropdownItem>
                <Box xcss={themelessStyle}>{intl.formatMessage(i18n.profile)}</Box>
            </AtlasKitDropdownItem>
        </NoUnderlineLink>
    );
};

const reportingMenuLink = (intl: ReactIntl.InjectedIntl) => {
    return (
        <NoUnderlineLink to="/reporting" actionSubjectId="navbarReporting">
            <AtlasKitDropdownItem>
                <Box xcss={themelessStyle}>{intl.formatMessage(i18n.reporting)}</Box>
            </AtlasKitDropdownItem>
        </NoUnderlineLink>
    );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const logoutLink = (onLogoutClick: () => any, intl: ReactIntl.InjectedIntl) => (
    <AnalyticsContext data={{ actionSubjectId: 'navbarLogout' }}>
        <DropdownItem data-test-id="logout-link" onClick={onLogoutClick}>
            <Box xcss={themelessStyle}>{intl.formatMessage(i18n.logout)}</Box>
        </DropdownItem>
    </AnalyticsContext>
);

const buildCustomMenuLinks = (customMenuLinks: CustomMenuLinkProps[]) => {
    return customMenuLinks.map((menuLink) => (
        // eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Disabled to rollout go/ui-styling-standard tooling, please resolve
        <div className={menuLink.styleClass} key={menuLink.key}>
            <DropdownItem data-test-id="custom-menu-link" testId={menuLink.id} href={menuLink.href}>
                <Box xcss={themelessStyle}>{menuLink.label}</Box>
            </DropdownItem>
        </div>
    ));
};

export const CustomMenuLinks = ({ customMenuLinks }: { customMenuLinks: CustomMenuLinkProps[] }) => (
    <span data-test-id="custom-menu-link">
        {customMenuLinks.map((menuLink) => (
            // eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Disabled to rollout go/ui-styling-standard tooling, please resolve
            <div className={menuLink.styleClass} key={menuLink.key}>
                <DropdownItem testId={menuLink.id} href={menuLink.href}>
                    <Box xcss={themelessStyle}>{menuLink.label}</Box>
                </DropdownItem>
            </div>
        ))}
    </span>
);

const UserProfileConnectActionLinks = withDeferredWrmLoaded(CustomMenuLinks, initializeConnect);

const forgeActionLinkOnClick = (id: string, onClick: SetExtensionToDisplayType) => () => onClick(id);

export const ForgeTitleMenuLinks = () => {
    const {
        data: { extensionTitles },
        methods: { setExtensionToDisplay: onClick },
    } = React.useContext(ForgeModalContext);

    return (
        <>
            {extensionTitles.map(({ id, title }) => {
                return (
                    <DropdownItem testId={id} key={id} onClick={forgeActionLinkOnClick(id, onClick)}>
                        <Box xcss={themelessStyle}>{title}</Box>
                    </DropdownItem>
                );
            })}
        </>
    );
};

// TODO: Remove State when ticket AK-4467 is fixed
interface State {
    isOpen: boolean;
}

class AvatarMenu extends React.Component<Props, State> {
    static defaultProps = {
        customMenuLinks: [],
    };

    state: State = {
        isOpen: false,
    };

    onItemClick = () => {
        this.setState({ isOpen: false });
    };

    onOpenChange = ({ isOpen }: OnOpenChangeArgs) => {
        if (!!this.props.onOpenAndClose) {
            this.props.onOpenAndClose({ isOpen });
        }
        this.setState({ isOpen });
    };

    renderDropdownMenu = () => {
        const {
            avatarSrc,
            onLogoutClick,
            customMenuLinks,
            canAdministerJira,
            jsmAiConfigEnabled,
            intl,
            disableAvatarMenu,
        } = this.props;
        return (
            <DropdownMenu<HTMLDivElement>
                shouldFlip={false}
                appearance="tall"
                trigger={({ triggerRef, ...props }) => (
                    <div ref={triggerRef} {...props}>
                        <UserAvatar
                            avatarSrc={avatarSrc}
                            isExpanded={this.state.isOpen}
                            disableAvatarMenu={disableAvatarMenu}
                        />
                    </div>
                )}
                placement="bottom-end"
                onOpenChange={this.onOpenChange}
                isOpen={this.state.isOpen}
            >
                <DropdownItemGroup title={intl.formatMessage(i18n.account)}>
                    <InteractiveElement
                        tagName="span"
                        onClick={this.onItemClick}
                        data-test-id="menu-wrapper"
                        tabIndex={-1}
                    >
                        {profileLink(intl)}
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {customMenuLinks && customMenuLinks.length > 0 ? (
                            isWrmOptimizationsEnabled() ? (
                                <UserProfileConnectActionLinks customMenuLinks={customMenuLinks} />
                            ) : (
                                buildCustomMenuLinks(customMenuLinks)
                            )
                        ) : undefined}
                        <ForgeTitleMenuLinks />
                        {isUnifiedHelpReportingPageEnabled() && jsmAiConfigEnabled && canAdministerJira
                            ? reportingMenuLink(intl)
                            : null}
                        {logoutLink(onLogoutClick, intl)}
                    </InteractiveElement>
                </DropdownItemGroup>
            </DropdownMenu>
        );
    };

    render() {
        return (
            <AvatarMenuContainer>
                <ForgeModalContextProvider type={USER_MENU_ACTION_SECTION_MODULE}>
                    {this.renderDropdownMenu()}
                    <ForgeActionModule
                        type={USER_MENU_ACTION_SECTION_MODULE}
                        componentName={USER_MENU_ACTION_COMPONENT}
                    />
                </ForgeModalContextProvider>
            </AvatarMenuContainer>
        );
    }
}

export default injectIntl(AvatarMenu);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const AvatarMenuContainer = styled.div({
    padding: `0 ${token('space.100', '8px')}`,
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
    height: grid.multiple(4.5).px,
});

const themelessStyle = xcss({
    color: 'color.text',
});
