import React, { useEffect } from 'react';
import { getUpdatedDisplayLink } from 'util/url';
import { sanitize } from 'dompurify';
import { useIntl } from 'react-intl-next';
import { graphql, useFragment } from 'react-relay';
import { usePathParam } from 'react-resource-router';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { withAnalyticsEvents } from '@atlaskit/analytics-next';
import ShortcutIcon from '@atlaskit/icon/glyph/shortcut';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { urlRefsAndTarget } from '@atlassian/help-center-common-util/is-external-resource';
import messages from '../articles/messages';
import {
    Title,
    LinkContainer,
    Snippet,
    formatText,
    isSinglePortalSearch,
    getPortalIdAsNumber,
    sendSearchUIAnalyticEvents,
} from '../common';
import { ResourceIcon } from '../resource-icon';
import type { articleItemFragment$key } from './__generated__/articleItemFragment.graphql';

export interface Props {
    itemOrder: number;
    term: string;
    showAllResults?: boolean;
    result: articleItemFragment$key;
    createAnalyticsEvent?: CreateUIAnalyticsEvent;
}

export const ArticleItem = ({ itemOrder, result, term, showAllResults, createAnalyticsEvent }: Props) => {
    const { formatMessage } = useIntl();
    const data = useFragment<articleItemFragment$key>(
        graphql`
            fragment articleItemFragment on HelpObjectStoreSearchResult {
                __typename @required(action: THROW)
                id @required(action: THROW)
                title @required(action: THROW)
                description @required(action: THROW)
                displayLink @required(action: THROW)
                isExternal @required(action: THROW)
                searchBackend
                searchAlgorithm
                ...resourceIconFragment
            }
        `,
        result
    );
    const [portalId] = usePathParam('portalId');
    const portalIdAsNumber = getPortalIdAsNumber(portalId);
    const { title, description, displayLink, isExternal, id, searchBackend, searchAlgorithm } = data;
    const shortcutIcon = isExternal ? <ShortcutIcon label={formatMessage(messages.shortcutIcon)} size="small" /> : null;
    const highlightedTitle = title ? formatText(title, term) : '';
    const highlightedDescription = description ? formatText(description, term) : '';
    const resourceDisplayLink =
        isSinglePortalSearch(portalIdAsNumber) && showAllResults
            ? `/portal/${portalIdAsNumber}/article/${id}`
            : getUpdatedDisplayLink(displayLink);
    const linkProps = urlRefsAndTarget({
        displayLink: resourceDisplayLink,
        isAnExternalResource: isExternal,
    });

    useEffect(() => {
        sendSearchUIAnalyticEvents(
            {
                searchBackend,
                searchAlgorithm,
                action: 'list-item-rendered',
                resourceType: 'article',
            },
            createAnalyticsEvent,
            portalId
        );
    }, [createAnalyticsEvent, portalId, searchAlgorithm, searchBackend]);

    return (
        <LinkContainer
            key={id}
            actionSubjectId={isExternal ? 'external-article-link' : 'article-link'}
            data-test-id="result-link-container"
            attributes={{
                itemOrder,
                searchBackend,
                searchAlgorithm,
            }}
            {...linkProps}
        >
            <ResourceIcon result={data} />
            <Box
                // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
                xcss={xcss({
                    overflow: 'hidden',
                    width: '100%',
                    paddingLeft: token('space.200', '16px'),
                    paddingRight: token('space.200', '16px'),
                })}
            >
                <Title dangerouslySetInnerHTML={{ __html: sanitize(highlightedTitle) }} />
                <Snippet dangerouslySetInnerHTML={{ __html: sanitize(highlightedDescription) }} />
            </Box>
            {shortcutIcon}
        </LinkContainer>
    );
};

export default withAnalyticsEvents()(ArticleItem);
