import React, { useMemo } from 'react';
import { isFlexiHCEnabledInDefaultHC } from 'util/advanced-help-center';
import { UFOWatcher } from 'util/ufo-watcher';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled
import { ThemeProvider } from 'styled-components';
import {
    isEmailVerifiedSignalEnabled,
    isHelpdeskCreationEnabledOnHelpCenter,
    isJsmVirtualAgentOnPortalEnabled,
    isUnifiedHelpReportingPageEnabled,
} from 'feature-flags';
import type { Route } from 'react-resource-router';
import { RouteComponent, Router, StaticRouter } from 'react-resource-router';
import { getEditHomePageRoute, getViewHomePageRoute } from 'routes/advanced-help-center';
import { aiAnswerRoute } from 'routes/aianswer';
import { approvalsRoute } from 'routes/approvals';
import { PROJECT_NAME } from 'routes/common/constants';
import { consentFormRoute } from 'routes/consent-form';
import { getConversationPageRoute, viewVirtualAgentConversationPageRoute } from 'routes/conversation';
import { addTopicRoute, editTopicRoute } from 'routes/edit-topic';
import { embedCreateRequestRoute, embedKBArticleRoute, embedSinglePortalRoute } from 'routes/embed-smartlinks';
import { getHelpCenterRoute } from 'routes/help-center';
import {
    viewAllSearchResultsRoute,
    viewArticleRoute,
    viewKbPageRoute,
    viewPortalAllSearchResultsRoute,
    viewPortalArticleRoute,
    viewPortalCategoryArticleRoute,
    viewPortalKnowledgeCategoryRoute,
} from 'routes/kb';
import { loginRoute, portalLoginRoute } from 'routes/login';
import { portalAccessForbiddenRoute } from 'routes/portal-access-forbidden';
import { portalHomeRoute } from 'routes/portal-home';
import { requestApprovalRoute } from 'routes/request-approval';
import { requestDetailsRoute } from 'routes/request-details';
import { requestsListRoute } from 'routes/requests';

import {
    portalCompleteSignupRoute,
    portalInvitedSignupRoute,
    portalResetPasswordRoute,
    portalSignupRoute,
    userCheckMailRoute,
    userCompleteSignupRoute,
    userResetPasswordRoute,
    userSignupRoute,
} from 'routes/signup';
import { viewTopicRoute } from 'routes/topics';
import {
    addHelpContentRoute,
    addHelpDeskRoute,
    addHelpResourcesRoute,
    editHelpDeskRoute,
    viewHelpDeskRoute,
} from 'routes/unified-help-center';
import { userVerifyEmailRoute } from 'routes/verify-email';
import { getHeadless } from 'state/selectors/env';
import { getHelpCenterTitleColor, getThemeColor } from 'state/selectors/help-center';
import { lightenColor, readableTextColor } from 'view/styles/colors';
import { GlobalStyled } from 'view/styles/global';
import { isAdvancedHelpCenter } from '@atlassian/help-center-common-util/advanced-help-center';
import { getBaseName, history } from '@atlassian/help-center-common-util/history';
import { getCloudId, getHelpCenterAri } from '@atlassian/help-center-common-util/meta';
import {
    anonymousRequestSuccessRoute,
    helpReportingRoute,
    notFoundErrorRoute,
    portalReorderRoute,
    requestCreateRoute,
    requestFeedbackRoute,
    unsubscribeRoute,
    userProfileRoute,
} from '../routes';

export const getRoutes = (): Route[] => {
    return [
        ...(isJsmVirtualAgentOnPortalEnabled() ? [viewVirtualAgentConversationPageRoute] : []),
        ...(isAdvancedHelpCenter() || isFlexiHCEnabledInDefaultHC()
            ? [getViewHomePageRoute(), getEditHomePageRoute()]
            : []),
        ...(isEmailVerifiedSignalEnabled() ? [userVerifyEmailRoute] : []),
        aiAnswerRoute,
        consentFormRoute,
        loginRoute,
        userResetPasswordRoute,
        userSignupRoute,
        userCompleteSignupRoute,
        userCheckMailRoute,
        portalAccessForbiddenRoute,
        portalLoginRoute,
        portalSignupRoute,
        portalResetPasswordRoute,
        portalCompleteSignupRoute,
        portalInvitedSignupRoute,
        requestFeedbackRoute,
        unsubscribeRoute,
        getHelpCenterRoute(),
        getEditHomePageRoute('edit-homepage', '/edit'),
        requestDetailsRoute,
        viewKbPageRoute,
        viewAllSearchResultsRoute,
        viewPortalAllSearchResultsRoute,
        viewPortalCategoryArticleRoute,
        viewPortalArticleRoute,
        viewArticleRoute,
        requestsListRoute,
        approvalsRoute,
        requestApprovalRoute,
        userProfileRoute,
        ...(isUnifiedHelpReportingPageEnabled() ? [helpReportingRoute] : []),
        viewPortalKnowledgeCategoryRoute,
        anonymousRequestSuccessRoute,
        requestCreateRoute,
        portalHomeRoute,
        embedCreateRequestRoute,
        embedSinglePortalRoute,
        embedKBArticleRoute,
        portalReorderRoute(true),
        editTopicRoute,
        addTopicRoute,
        viewTopicRoute,
        ...(isHelpdeskCreationEnabledOnHelpCenter()
            ? [addHelpDeskRoute, addHelpResourcesRoute, viewHelpDeskRoute, editHelpDeskRoute, addHelpContentRoute]
            : []),
        ...getConversationPageRoute(),
        notFoundErrorRoute,
    ];
};
export const customContext = {
    cloudId: getCloudId(),
    productName: PROJECT_NAME,
    helpCenterAri: getHelpCenterAri(),
};

export interface Props {
    headerless: boolean;
    themeColor: string;
    titleColor: string;
}

export const CSMResourceRouter = (props: Props) => {
    const { themeColor, titleColor } = props;
    const readableTextColorFromTheme = readableTextColor(themeColor);
    const lightColorFromTheme = lightenColor(themeColor);
    const routes = useMemo(() => getRoutes(), []);
    const location = window.location.pathname + window.location.search;

    const theme = {
        titleColor,
        readableTextColor: readableTextColorFromTheme,
        color: themeColor,
        lightColor: lightColorFromTheme,
    };

    if (__SERVER__) {
        return (
            <ThemeProvider theme={theme}>
                <StaticRouter
                    routes={routes}
                    isGlobal={false}
                    location={location}
                    resourceContext={customContext}
                    basePath={getBaseName()}
                    isStatic
                >
                    <GlobalStyled />
                    <RouteComponent />
                </StaticRouter>
            </ThemeProvider>
        );
    }

    //isGlobal=false, to avoid the router conflicting with the sidebar's router.
    return (
        <ThemeProvider theme={theme}>
            <Router routes={routes} history={history} isGlobal={false} resourceContext={customContext}>
                <GlobalStyled />
                {!__SERVER__ ? <UFOWatcher /> : null}
                <RouteComponent />
            </Router>
        </ThemeProvider>
    );
};

const selector = createSelector(
    getHeadless,
    getThemeColor,
    getHelpCenterTitleColor,
    (headerless, themeColor, titleColor) => ({
        headerless,
        themeColor,
        titleColor,
    })
);

export default connect(selector)(CSMResourceRouter);
