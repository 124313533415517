import React from 'react';
import { compose } from 'redux';
import { isNewUHBrowseHelpDesksEnabled } from '@helpCenter/feature-flags';
import type { RouteContext } from 'react-resource-router';
import { ErrorPage } from 'view/unified-help-center/error-page';
import Layout from 'view/unified-help-center/layout';
import { AddHelpDeskPage, AddResourcesPage } from '@atlassian/help-center-add-help-desk';
import {
    ADD_HELP_CONTENT_PATH,
    ADD_HELP_DESK_PATH,
    EDIT_HELP_DESK_PATH,
    ScreenName,
    VIEW_HELP_DESK_PATH,
    ADD_RESOURCES_IN_HELP_DESK_PATH,
    AddContentTypes,
} from '@atlassian/help-center-common-component/constants';
import { withOnlyLoggedIn } from '@atlassian/help-center-common-component/with-only-logged-in';
import {
    AddHelpContentPage,
    ViewHelpDeskPage,
    EditHelpDeskPage,
    BrowseHelpDesksPage,
} from '@atlassian/help-center-help-desk';

const Error = () => <ErrorPage />;

const AddHelpContentPageLayout = (props: RouteContext) => {
    const { contentType } = props.match.params;
    let screenName = ScreenName.ADD_HELP_CONTENT_EXTERNAL_LINKS;
    if (contentType === AddContentTypes.KNOWLEDGE_RESOURCE) {
        screenName = ScreenName.ADD_HELP_CONTENT_KNOWLEDGE_RESOURCES;
    }
    const WrappedComponent = compose(
        withOnlyLoggedIn({
            redirectUrl: props.location.pathname,
        })
    )(() => <Layout component={AddHelpContentPage} screenName={screenName} />);
    return <WrappedComponent {...props} />;
};

const ViewHelpDeskPageLayout = (props: RouteContext) => {
    const WrappedComponent = compose(withOnlyLoggedIn({ redirectUrl: props.location.pathname }))(() => (
        <Layout renderError={Error} component={ViewHelpDeskPage} screenName={ScreenName.VIEW_HELP_DESK} />
    ));
    return <WrappedComponent />;
};

const EditHelpDeskPageLayout = (props: RouteContext) => {
    const WrappedComponent = compose(
        withOnlyLoggedIn({
            redirectUrl: props.location.pathname,
        })
    )(() => <Layout component={EditHelpDeskPage} screenName={ScreenName.EDIT_HELP_DESK} />);
    return <WrappedComponent {...props} />;
};

export const addHelpDeskRoute = {
    name: ScreenName.ADD_HELP_DESK,
    path: ADD_HELP_DESK_PATH,
    exact: true,
    component: AddHelpDeskPage,
};

export const editHelpDeskRoute = {
    name: ScreenName.EDIT_HELP_DESK,
    path: EDIT_HELP_DESK_PATH,
    exact: true,
    component: EditHelpDeskPageLayout,
};

export const viewHelpDeskRoute = {
    name: ScreenName.VIEW_HELP_DESK,
    path: VIEW_HELP_DESK_PATH,
    exact: true,
    component: isNewUHBrowseHelpDesksEnabled() ? BrowseHelpDesksPage : ViewHelpDeskPageLayout,
};

export const addHelpContentRoute = {
    name: ScreenName.ADD_HELP_CONTENT,
    path: ADD_HELP_CONTENT_PATH,
    component: AddHelpContentPageLayout,
};

export const addHelpResourcesRoute = {
    name: ScreenName.ADD_HELP_DESK_KNOWLEDGE_RESOURCES,
    path: ADD_RESOURCES_IN_HELP_DESK_PATH,
    exact: true,
    component: AddResourcesPage,
};
