/**
 * Define feature flag here as constants.
 *
 * export const KB_ENHANCEMENTS = 'jsd.px.kb-enhancements';
 *
 * Use them in react components.
 * import { KB_ENHANCEMENTS } from 'feature-flags';
 * import { FeatureFlag } from '@atlassian/help-center-common-component/feature-flag';
 *
 * <FeatureFlag name={KB_ENHANCEMENTS}>
 *  I will be shown in true :-)
 * </FeatureFlag>
 *
 * ========== NOTE ===================
 * BE CAREFUL ADDING FEATURE FLAGS, IF YOU RENAME THEM IT WILL CHANGE THE ANALYTIC CONTEXT
 * NAME AND NO LONGER SEND THE SAME CONTEXT ATTRIBUTE NAME.
 * ===================================
 */

import FeatureGates from '@atlaskit/feature-gate-js-client';
import { isFeatureEnabled } from '@atlassian/help-center-common-util/feature-manager';
// Be careful when using these constants directly. In most cases, you should use the function.
export const JSD_REQUEST_TYPE_ONBOARDING = 'jsd-request-type-onboarding';
export const RICH_TEXT_AREA_FIELD = 'jsd.use-adf-default-value-for-textarea';
export const PORTAL_WRM_OPTIMIZATIONS = 'jsd-dw-portal-wrm-optimisations';
export const ENHANCED_RENDER_EXPERIENCE_FOR_KNOWLEDGE_ARTICLES =
    'jsm-enhanced-render-experience-for-knowledge-articles';
const JSM_OUTSIDER_COMMENT = 'display-outsider-comment-field_4x7u7';
const JSM_KB_ENABLE_LAYOUT_MACRO_FIX_FOR_EP_VIEW = 'jsm-kb-enable-layout-macro-fix-for-ep-view';
const JSM_ENABLE_ANONYMOUS_CUSTOM_UI = 'fe-jsm-forge-enable-anonymous-custom-ui';
const IS_APOLLO_STARGATE_PATH_ENABLED = 'jsd-apollo-stargate-path_cbi21';

const JSM_KB_ANONYMOUS_ACCOUNT_PROJECT_ID_FIX_ENABLED = 'jsm-kb-anonymous-account-project-id-fix-enabled';

const PROFORMA_HARMONISATION_MAIN = 'proforma-harmonisation-main_ca6ff';

const ENABLE_KB_ARTICLES_OPEN_IN_SAME_TAB = 'make-kb-articles-open-in-same-tab';

const FORGE_UI_KIT_2 = 'jsm-forge-ui-kit-2_pb6hu';

export const JSM_CUSTOMER_SSO_EAP = 'jsm-customer-sso-eap_kdznw';

export const LOOSELY_LAZY_JS_ERRORS_TO_SENTRY = 'loosely-lazy-js-errors-to-sentry';

const JSM_PORTAL_CUSTOM_THEMING = 'jsm-portal-custom-theming';
export const DISABLE_INTERACTION_ON_PARENT_SELECT_LAYOUT_BUILDER =
    'jsm-help-center-disable-interaction-on-parent-select_dza90';

const ADVANCED_HELP_CENTER_IMAGE_RESIZING_OPTION_2 = 'advanced-help-center-image-resizing-option-2_ol17p';

export const UNIFIED_HELP_GOOGLE_DRIVE_INTEGRATION = 'unified-help-google-drive-fe-integration';
export const UNIFIED_HELP_HELP_CENTER_EXPERIENCE = 'help-center-experience-for-unified-help';
export const UNIFIED_HELP_REPORTING_PAGE = 'help_center_reporting_route';
export const UNIFIED_HELP_REPORTING_CONTENT_GAP = 'hc_reporting_content_gap';
export const UNIFIED_HELP_REQUEST_FORM_REORDERING = 'unified-help-request-form-reordering_q8s94';
export const HELP_CENTER_AI_ANSWERS_SHOW_NEW_DISCLAIMER = 'help-center-ai-answers-show-new-disclaimer';
export const UNIFIED_HELP_ADD_FILTER_FOR_CUSTOMER_ACCOUNTS = 'unified-help-add-filter-for-customer-accounts';

const UPDATED_CONTENT_CREATED_BY_ANYONE_FILTER = 'updated-content-for-create-by-anyone-filter';
const UNIFIED_HELP_JSM_REDIRECT_BANNER = 'reroute-jsm-help-center-to-unified-help-center_f9jqr';
const HELPDESK_CREATION_ENABLED_ON_HELPCENTER = 'jsm-helpcenter-helpdesk-creation-enabled_82ce6';
const MULTI_HC_KOSH_INTEGRATION = 'multi_help_center_kosh_integration';
const BASIC_HC_CALLS_JIRA = 'basic_hc_calls_jira';

const JCSM_CS_MASTER_FLAG = 'jcs-master-flag';
const FIX_REDIRECTION_TO_NOT_FOUND_PAGE = 'fix-redirection-bug-on-hash-in-url';
const NAVIGATE_TO_HELP_SANDOOK = 'navigating-calls-to-help-sandook';

export const JSM_VIRTUAL_AGENT_ON_PORTAL = 'jsm-virtual-agent-on-help-centre_zat8i';
export const HC_CONVERSATION_BACKGROUND_COLOR_CHANGES = 'hc-conversation-background-color-changes-enabled';
export const JSM_VIRTUAL_AGENT_ANONYMOUS_ACCESS = 'anonymous-va-access_rn7y3';
export const JSM_VIRTUAL_AGENT_ON_HELPCENTER = 'jsm-virtual-agent-and-ai-answers-on-helpcenter_i4mul';
export const JSM_VIRTUAL_AGENT_ON_HELPCENTER_TEST_CONERSATION = 'jsm-virtual-agent-on-helpcenter-test-conversation';
export const JSM_SEARCH_HINT_ON_PORTAL = 'jsm-search-input-hint-text_to5c5';
export const USE_REACT_18_CREATEROOT = 'use_react-18_createroot';

export const PORTAL_COOKIE_CONSENT_BANNER = 'jsm.fission.portal.cookie-controls-banner_isn28';

export const USE_SHA256_FOR_MEDIA = 'use-sha256-for-media_wfgmf';

export const CMDB_FIELD_ERROR_ENABLED = 'fly-1941-cmdb-field-aql-error_crp0k';
export const CMDB_TOTAL_COUNT_DEPRECATION = 'portal_assets_rearch_total_count_field_deprecation';
const JSM_FIX_SIGN_UP_ROUTING_FOR_MULTI_HC = 'fix-signup-routing-for-multi-hc_bi7d1';
const STOP_SENDING_MISSING_TRANSLATION_ERRORS_TO_SENTRY = 'stop-sending-missing-translation-errors-to-sentry_qlh9m';
export const REQUEST_CREATE_FORM_MANDATORY_INSTRUCTIONS = 'show-instructions-for-mandatory-fields-portal_9vzob';
export const JSM_VIRTUAL_AGENT_BANNER_ON_PORTAL = 'jsm-virtual-agent-banner-on-portal_n8e8z';
export const REMOVE_MANAGE_COLUMNS_FROM_DROPDOWN_TO_SEPERATE_BUTTON =
    'remove-manage-columns-from-drodown-to-seperate-button_oi3ap';
export const SEND_REGION_IN_BROWSER_METRICS = 'send-region-in-browser-metrics_q7j37';

export const REMOVE_UNWANTED_SHARED_PORTAL_CODE_USAGE = 'remove-unwanted-shared-portal-code-in-fe_nyrua';
// <Change Boarding Admins>
export const CHANGE_BOARDING_JSM_ADMINS_SINGLE_PORTAL = 'change-boarding-jsm-admins-in-single-portal-page_2id61';
export const CHANGE_BOARDING_JSM_ADMINS_FREE_AND_STANDARD_EDITION =
    'change-boarding-jsm-admins-free-and-standard-portal-home-page_ry7lo';
export const CHANGE_BOARDING_JSM_ADMINS_PREMIUM_AND_ENTERPRISE_EDITION =
    'change-boarding-jsm-admins-premium-and-enterprise-portal-home-page-_02jxs';
const ENABLE_TOPICS_FROM_AAKAR = 'enable-topics-from-help-aakar_er8dy';
const ADVANCED_HELP_CENTER_CUSTOMIZATION_EDIT_PAGE = 'advanced_help_center_customization_edit_page';
const UPDATE_TEXT_FIELD_PACKAGE_VERSION_FOR_INPUT_FIELD = 'update_text_field_package_version_for_input_field';
const VA_DISABLE_INLINE_CLEAR_CONVERSATION = 'virtual_agent_disable_inline_clear_conversation';
const APPS_IN_FLEXIBLE_HC = 'apps_in_flexible_hc';
const REMOVE_TRANSLATIONS_FALLBACK = 'remove_translations_fallback';
const REPORTING_EXPERIENCE = 'reporting-experience-in-help-center';
const ADD_APPROVAL_FAILED_STATUS_CODES = 'add_statuscode_to_answerapproval_failed_events';
const VIRTUAL_AGENT_IN_SEARCH_DROPDOWN = 'jsm-virtual-agent-search-dropdown_9qz5h';

const CONDITIONALLY_RENDER_SIDEBAR = 'make_sidebar_render_conditionally';

export const isChangeBoardingForJSMAdminsSinglePortalEnabled = () =>
    isFeatureEnabled({
        key: CHANGE_BOARDING_JSM_ADMINS_SINGLE_PORTAL,
        fallback: false,
    });

export const isChangeBoardingForJSMAdminsFreeAndStandardEditionEnabled = () =>
    isFeatureEnabled({
        key: CHANGE_BOARDING_JSM_ADMINS_FREE_AND_STANDARD_EDITION,
        fallback: false,
    });

export const isChangeBoardingForJSMAdminsPremiumAndEnterpriseEditionEnabled = () =>
    isFeatureEnabled({
        key: CHANGE_BOARDING_JSM_ADMINS_PREMIUM_AND_ENTERPRISE_EDITION,
        fallback: false,
    });

export const isChangeBoardingForJSMAdminsIsEnabled = () =>
    isChangeBoardingForJSMAdminsSinglePortalEnabled() ||
    isChangeBoardingForJSMAdminsFreeAndStandardEditionEnabled() ||
    isChangeBoardingForJSMAdminsPremiumAndEnterpriseEditionEnabled();

// </Change Boarding Admins>

export const isRemoveManageColumnsFromDropdownToSeperateButtonEnabled = () =>
    isFeatureEnabled({
        key: REMOVE_MANAGE_COLUMNS_FROM_DROPDOWN_TO_SEPERATE_BUTTON,
        fallback: false,
    });

export const shouldUseSHA256 = () =>
    isFeatureEnabled({
        key: USE_SHA256_FOR_MEDIA,
        fallback: false,
    });
export const JSM_PORTAL_SKIP_TO_MAIN_A11Y_FIX = 'jsm-portal-skip-to-main-a11y-fix_h1blp';

export const isJsmSkipToMainA11yFixEnabled = () =>
    isFeatureEnabled({
        key: JSM_PORTAL_SKIP_TO_MAIN_A11Y_FIX,
        fallback: false,
    });

export const EMAIL_VERIFIED_SIGNAL_ON_CUSTOMERS_PAGE = 'sptn-1697-email-verified-signal-on-customers-page_v2g52';

export const isEmailVerifiedSignalEnabled = () =>
    isFeatureEnabled({
        key: EMAIL_VERIFIED_SIGNAL_ON_CUSTOMERS_PAGE,
        fallback: false,
    });

const UH_CONFLUENCE_SEARCH_AND_PICK_ENABLED = 'uh-confluence-search-and-pick-enabled_omcnz';

export const isWrmOptimizationsEnabled = () =>
    isFeatureEnabled({
        key: PORTAL_WRM_OPTIMIZATIONS,
        fallback: false,
    });

export const isRichTextAreaEnabled = () =>
    isFeatureEnabled({
        key: RICH_TEXT_AREA_FIELD,
        fallback: false,
    });

export const isEnhanceRenderExperienceForKnowledgeArticlesEnabled = () =>
    isFeatureEnabled({
        key: ENHANCED_RENDER_EXPERIENCE_FOR_KNOWLEDGE_ARTICLES,
        fallback: false,
    });

export const isJSMOutsiderCommentEnabled = () =>
    isFeatureEnabled({
        key: JSM_OUTSIDER_COMMENT,
        fallback: false,
    });

export const isLayoutMacroFixEnabled = () =>
    isFeatureEnabled({
        key: JSM_KB_ENABLE_LAYOUT_MACRO_FIX_FOR_EP_VIEW,
        fallback: false,
    });

export const isAnonymousCustomUIEnabled = () =>
    isFeatureEnabled({
        key: JSM_ENABLE_ANONYMOUS_CUSTOM_UI,
        fallback: false,
    });

export const isApolloStargatePathEnabled = () =>
    isFeatureEnabled({
        key: IS_APOLLO_STARGATE_PATH_ENABLED,
        fallback: false,
    });

export const isAnonymousAccountProjectIdFixEnabled = () =>
    isFeatureEnabled({
        key: JSM_KB_ANONYMOUS_ACCOUNT_PROJECT_ID_FIX_ENABLED,
        fallback: false,
    });

export const isProformaHarmonisationFFEnabled = () =>
    isFeatureEnabled({
        key: PROFORMA_HARMONISATION_MAIN,
        fallback: false,
    });

export const isJsmCustomerSsoEapEnabled = () =>
    isFeatureEnabled({
        key: JSM_CUSTOMER_SSO_EAP,
        fallback: false,
    });

export const islooselyLazyJsErrorsToSentryEnabled = () =>
    isFeatureEnabled({
        key: LOOSELY_LAZY_JS_ERRORS_TO_SENTRY,
        fallback: false,
    });

export const isCustomThemingEnabled = () =>
    isFeatureEnabled({
        key: JSM_PORTAL_CUSTOM_THEMING,
        fallback: false,
    });

export const isDisablingInteractionOnParentSelectionEnabled = () => {
    return isFeatureEnabled({
        key: DISABLE_INTERACTION_ON_PARENT_SELECT_LAYOUT_BUILDER,
        fallback: false,
    });
};

export const isImageResizingOptionTwoEnabled = () =>
    isFeatureEnabled({ key: ADVANCED_HELP_CENTER_IMAGE_RESIZING_OPTION_2, fallback: false });

export const isUnifiedHelpGoogleDriveEnabled = () => {
    return isFeatureEnabled({
        key: UNIFIED_HELP_GOOGLE_DRIVE_INTEGRATION,
        fallback: false,
    });
};

export const isUnifiedHelpReportingPageEnabled = () => {
    return FeatureGates.checkGate(UNIFIED_HELP_REPORTING_PAGE);
};

export const isUnifiedHelpReportingContentGapEnabled = () => {
    return isFeatureEnabled({
        key: UNIFIED_HELP_REPORTING_CONTENT_GAP,
        fallback: false,
    });
};

export const isUnifiedHelpRequestFormReorderingEnabled = () => {
    return isFeatureEnabled({
        key: UNIFIED_HELP_REQUEST_FORM_REORDERING,
        fallback: false,
    });
};

export const isUnifiedHelpAddFilterForCustomerAccounts = () => {
    return FeatureGates.checkGate(UNIFIED_HELP_ADD_FILTER_FOR_CUSTOMER_ACCOUNTS);
};

export const isUIKit2Enabled = () => {
    return isFeatureEnabled({
        key: FORGE_UI_KIT_2,
        fallback: false,
    });
};

export const isUpdatedContentForCreatedByAnyoneFilter = () => {
    return isFeatureEnabled({
        key: UPDATED_CONTENT_CREATED_BY_ANYONE_FILTER,
        fallback: false,
    });
};

export const isJSMRedirectBannerEnabled = () => {
    return isFeatureEnabled({
        key: UNIFIED_HELP_JSM_REDIRECT_BANNER,
        fallback: false,
    });
};

export const isUnifiedHelpInJSMEnabled = () => {
    return isFeatureEnabled({
        key: UNIFIED_HELP_HELP_CENTER_EXPERIENCE,
        fallback: false,
    });
};

export const isNewDisclaimerForAIAnswersEnabled = () => {
    return isFeatureEnabled({
        key: HELP_CENTER_AI_ANSWERS_SHOW_NEW_DISCLAIMER,
        fallback: false,
    });
};

export const isConfluenceSearchAndPickEnabled = () => {
    return isFeatureEnabled({
        key: UH_CONFLUENCE_SEARCH_AND_PICK_ENABLED,
        fallback: false,
    });
};

export const isHelpdeskCreationEnabledOnHelpCenter = () => {
    return isFeatureEnabled({
        key: HELPDESK_CREATION_ENABLED_ON_HELPCENTER,
        fallback: false,
    });
};

// Virtual Agent (JSM Flux) Feature flags

export const isJsmVirtualAgentOnPortalEnabled = () =>
    isFeatureEnabled({
        key: JSM_VIRTUAL_AGENT_ON_PORTAL,
        fallback: false,
    });

export const isJsmVirtualAgentOnHelpcenterEnabled = () =>
    isFeatureEnabled({
        key: JSM_VIRTUAL_AGENT_ON_HELPCENTER,
        fallback: false,
    });

export const isJsmSearchHintEnabled = () =>
    isFeatureEnabled({
        key: JSM_SEARCH_HINT_ON_PORTAL,
        fallback: false,
    });

export const isJSMVirtualAgentAnonymousAccessEnabled = () =>
    isFeatureEnabled({
        key: JSM_VIRTUAL_AGENT_ANONYMOUS_ACCESS,
        fallback: false,
    });

export const isJsmVirtualAgentBannerOnPortalEnabled = () =>
    isFeatureEnabled({
        key: JSM_VIRTUAL_AGENT_BANNER_ON_PORTAL,
        fallback: false,
    });

// JSM Boysenberry feature flags
export const isJsmCsMasterFlagEnabled = () => {
    return isFeatureEnabled({
        key: JCSM_CS_MASTER_FLAG,
        fallback: false,
    });
};

export const isMultiHcKoshIntegrationEnabled = () => {
    return isFeatureEnabled({
        key: MULTI_HC_KOSH_INTEGRATION,
        fallback: false,
    });
};

export const isDefaultHCCallsJiraEnabled = () => {
    return FeatureGates.checkGate(BASIC_HC_CALLS_JIRA);
};

export const isRedirectionToNotFoundPageFixEnabled = () => {
    return FeatureGates.checkGate(FIX_REDIRECTION_TO_NOT_FOUND_PAGE);
};

export const isSanitizationOfPortalDescriptionToRemoveTabIndexEnabled = () => {
    return FeatureGates.checkGate('sanitize_portal_card_description_tabi_index');
};

export const isNewUHBrowseHelpDesksEnabled = () => {
    return FeatureGates.checkGate('unified-help-browse-help-desk');
};

export const isNewReportingExperienceEnabled = () => {
    return FeatureGates.checkGate(REPORTING_EXPERIENCE);
};

export const isPortalCookieConsentBannerEnabled = () => {
    return isFeatureEnabled({
        key: PORTAL_COOKIE_CONSENT_BANNER,
        fallback: false,
    });
};
export const isConversationBackgroundColorEnabled = () =>
    isFeatureEnabled({
        key: HC_CONVERSATION_BACKGROUND_COLOR_CHANGES,
        fallback: false,
    }) || isJsmVirtualAgentOnHelpcenterEnabled();

export const isKbArticlesSameTabRedirectionEnabled = () =>
    isFeatureEnabled({
        key: ENABLE_KB_ARTICLES_OPEN_IN_SAME_TAB,
        fallback: false,
    });

export const isCmdbFieldErrorsEnabled = () =>
    isFeatureEnabled({
        key: CMDB_FIELD_ERROR_ENABLED,
        fallback: false,
    });

export const isCmdbTotalCountDeprecated = () => FeatureGates.checkGate(CMDB_TOTAL_COUNT_DEPRECATION);

export const isAdvancedCustomizationEnabled = () => {
    return FeatureGates.checkGate('advanced_help_center_customization');
};

export const isAdvancedCustomizationEditPageEnabled = () => {
    return FeatureGates.checkGate(ADVANCED_HELP_CENTER_CUSTOMIZATION_EDIT_PAGE);
};

export const isFixSignUpRoutingForMultiHCEnabled = () =>
    isFeatureEnabled({
        key: JSM_FIX_SIGN_UP_ROUTING_FOR_MULTI_HC,
        fallback: false,
    });

export const isMandatoryInstructionsForRequestCreateFormEnabled = () =>
    isFeatureEnabled({
        key: REQUEST_CREATE_FORM_MANDATORY_INSTRUCTIONS,
        fallback: false,
    });

export const isStopSendingMissingTranslationErrorsToSentryEnabled = () => {
    return isFeatureEnabled({
        key: STOP_SENDING_MISSING_TRANSLATION_ERRORS_TO_SENTRY,
        fallback: false,
    });
};

export const sendRegionAndPerimeterInBrowserMetrics = () => {
    return isFeatureEnabled({
        key: SEND_REGION_IN_BROWSER_METRICS,
        fallback: false,
    });
};

export const isTopicsFromAakarEnabled = () => {
    return isFeatureEnabled({
        key: ENABLE_TOPICS_FROM_AAKAR,
        fallback: false,
    });
};

export const navigateToSandook = () => {
    return isFeatureEnabled({
        key: NAVIGATE_TO_HELP_SANDOOK,
        fallback: false,
    });
};

export const isNewTextFieldEnabled = () => {
    return FeatureGates.checkGate(UPDATE_TEXT_FIELD_PACKAGE_VERSION_FOR_INPUT_FIELD);
};

export const isSharedPortalCodeRemoved = () => {
    return isFeatureEnabled({
        key: REMOVE_UNWANTED_SHARED_PORTAL_CODE_USAGE,
        fallback: false,
    });
};

export const isUseReact18CreateRootEnabled = () => {
    return FeatureGates.checkGate(USE_REACT_18_CREATEROOT);
};

export const isVAInlineClearConversationDisabled = () => FeatureGates.checkGate(VA_DISABLE_INLINE_CLEAR_CONVERSATION);
export const isAppsInFlexibleHCEnabled = () => FeatureGates.checkGate(APPS_IN_FLEXIBLE_HC);
export const isRemoveTranslationsFallbackEnabled = () => {
    return FeatureGates.checkGate(REMOVE_TRANSLATIONS_FALLBACK);
};

export const isPortalCardMarkupFixEnabled = () => {
    return FeatureGates.checkGate('fix_portal_card_markup');
};

export const isUsingVaEnabledForVAOnPortalBanner = () =>
    FeatureGates.checkGate('use_showbanner_to_control_va_on_portal_banner');

export const isApprovalServiceStatusCodeAttributesEnabled = () => {
    return FeatureGates.checkGate(ADD_APPROVAL_FAILED_STATUS_CODES);
};

export const isConditionallyRenderSidebarEnabled = () => {
    return FeatureGates.checkGate(CONDITIONALLY_RENDER_SIDEBAR);
};
export const isVAOnHelpCenterTestConversationEnabled = () => {
    return FeatureGates.checkGate(JSM_VIRTUAL_AGENT_ON_HELPCENTER_TEST_CONERSATION);
};

export const isVirtualAgentInSearchDropdownEnabled = () =>
    isFeatureEnabled({
        key: VIRTUAL_AGENT_IN_SEARCH_DROPDOWN,
        fallback: false,
    });
