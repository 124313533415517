import { defineMessages } from 'react-intl-next';

export default defineMessages({
    helpCenterReportingPageTitle: {
        defaultMessage: 'Performance',
        description: 'Title message for Help Center Reporting',
        id: 'helpcenter.reporting.title',
    },
    helpCenterReportingPageSubtitle: {
        defaultMessage: 'See the performance of the virtual agent on your help center from the last 7 days. ',
        description: 'Subtitle message for Help Center Reporting',
        id: 'helpcenter.reporting.subtitle',
    },

    IphLinkText: {
        defaultMessage: 'Read more about using the virtual agent on your help center.',
        description: 'Text for Link that should open in in-product help',
        id: 'helpcenter.reporting.iph-link-text',
    },

    reportingUniqueVisits: {
        defaultMessage: 'Customers',
        description: 'Title for Unique Visits count metric card',
        id: 'helpcenter.reporting.unique-visits',
    },
    reportingQuestionsAsked: {
        defaultMessage: 'Conversations',
        description: 'Title for Conversations happened metric card',
        id: 'helpcenter.reporting.ques-asked',
    },
    reportingNoAnswersProvided: {
        defaultMessage: 'Unanswered',
        description: 'Title for No Answers Provided metric card',
        id: 'helpcenter.reporting.no-ans-provided',
    },
    reportingCSAT: {
        defaultMessage: 'AI resolved',
        description: 'Title for CSAT score metric card',
        id: 'helpcenter.reporting.csat',
    },
    helpCenterPageLoadFail: {
        defaultMessage: 'Trouble while loading the page, please refresh and try again',
        description: 'Error message for when the page fails to load',
        id: 'helpcenter.reporting.failure',
    },
    genericHeading: {
        defaultMessage: 'Something bad happened',
        description: 'Generic error when something could not be loaded',
        id: 'helpcenter.reporting.error-page.generic.error.heading',
    },
    genericMessage: {
        defaultMessage: 'Looks like we’re having trouble retrieving some information, please refresh the page.',
        description: 'Generic error when something could not be loaded',
        id: 'helpcenter.reporting.error-page.generic.error.message',
    },
    trendTypeNoChange: {
        defaultMessage: 'No Change',
        description: 'When trend is exact 0',
        id: 'helpcenter.reporting.trend-type-nil',
    },
    tooltipUniqueUsers: {
        defaultMessage: 'The total number of customers who asked at least one question',
        description: 'Tooltip for Unique Users metric card',
        id: 'helpcenter.reporting.tooltip.unique-users',
    },
    tooltipQuestionsAsked: {
        defaultMessage: 'The total number of questions asked by customers',
        description: 'Tooltip for Questions Asked or usage metric card',
        id: 'helpcenter.reporting.tooltip.ques-asked',
    },
    tooltipCSAT: {
        defaultMessage: 'The percentage of answers rated by customers that were rated as helpful',
        description: 'Tooltip for CSAT helpfulness metric card',
        id: 'helpcenter.reporting.tooltip.csat',
    },
    tooltipNoAnswerProvided: {
        defaultMessage:
            'The number of times an Atlassian Intelligence answer couldn’t be provided in response to a customer’s question',
        description: 'Tooltip for no answers generated metric card',
        id: 'helpcenter.reporting.tooltip.no-ans',
    },
    aiConfigNotEnabledError: {
        defaultMessage: 'AI is not enabled for this portal',
        description: 'Error message when AI is not enabled for the tenant',
        id: 'helpcenter.reporting.ai.config.not.enabled.error.non-final',
    },
    conversations: {
        defaultMessage: 'Conversations',
        description: 'Total Conversations initiated by helpseekers',
        id: 'helpcenter.va.reporting.conversations',
    },
    matchedConversations: {
        defaultMessage: 'Matched',
        description: 'The percentage of conversations that were matched to any intent',
        id: 'helpcenter.va.reporting.matched.conversations',
    },
    resolvedConversations: {
        defaultMessage: 'Resolved',
        description: 'The percentage of conversations that were resolved by virtual agent',
        id: 'helpcenter.va.reporting.resolved.conversations',
    },
    csat: {
        defaultMessage: 'CSAT',
        description: 'average customer satisfaction rating',
        id: 'helpcenter.va.reporting.csat',
    },
    conversationsTooltip: {
        defaultMessage: 'The total number of conversations initiated by help seekers',
        description: 'tooltip for conversation tile',
        id: 'helpcenter.va.reporting.conversations.tooltip',
    },
    matchedConversationsTooltip: {
        defaultMessage: 'The percentage of conversations that were matched to any intent',
        description: 'tooltip for matched conversations tile',
        id: 'helpcenter.va.reporting.matched.conversations.tooltip',
    },
    resolvedConversationsTooltip: {
        defaultMessage:
            'The percentage of all conversations that were resolved by the virtual agent without any human intervention',
        description: 'tooltip for resolved conversations tile',
        id: 'helpcenter.va.reporting.resolved.conversations.tooltip',
    },
    csatTooltip: {
        defaultMessage:
            'The average customer satisfaction (CSAT) rating for conversations that were resolved by the virtual agent',
        description: 'tooltip for csat tile',
        id: 'helpcenter.va.reporting.csat.tooltip',
    },
});
