import React, { useEffect } from 'react';
import { useIntl } from 'react-intl-next';
import { graphql, useFragment } from 'react-relay';
import { usePathParam } from 'react-resource-router';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { withAnalyticsEvents } from '@atlaskit/analytics-next';
import {
    INITIAL_REQUEST_FORMS_COUNT,
    ResultList,
    ResultTitle,
    sendSearchOperationalAnalyticEvents,
    sendSearchUIAnalyticEvents,
} from '../common';
import { RequestFormItem } from '../request-form-item';
import type { requestFormsFragment$key } from './__generated__/requestFormsFragment.graphql';
import messages from './messages';

export interface Props {
    term: string;
    result: requestFormsFragment$key;
    updateResultsCount: (count: number) => void;
    createAnalyticsEvent?: CreateUIAnalyticsEvent;
}

export const RequestForms = ({ result, term, updateResultsCount, createAnalyticsEvent }: Props) => {
    const { formatMessage } = useIntl();
    const data = useFragment<requestFormsFragment$key>(
        graphql`
            fragment requestFormsFragment on HelpObjectStoreQueryApi {
                requestForms: searchHelpObjects(
                    searchInput: {
                        cloudId: $cloudId
                        queryTerm: $queryTerm
                        resultLimit: $resultLimit
                        entityType: REQUEST_FORM
                        portalIds: $portalIds
                    }
                ) @required(action: THROW) {
                    ... on HelpObjectStoreSearchResult {
                        __typename @required(action: THROW)
                        isExternal @required(action: THROW)
                        searchBackend
                        ...requestFormItemFragment
                    }

                    ... on HelpObjectStoreQueryError {
                        message
                    }
                }
            }
        `,
        result
    );
    const [portalId] = usePathParam('portalId');

    const slicedRequestForms = data.requestForms.slice(0, INITIAL_REQUEST_FORMS_COUNT);
    const showSection = slicedRequestForms.length > 0;

    useEffect(() => {
        let externalResourcesCount = 0;
        let firstPartySearchPlatformResourcesCount = 0;
        let hasError = false;
        let errorMessage: string | undefined;

        slicedRequestForms.forEach((resource) => {
            if (resource) {
                const { __typename, isExternal, searchBackend, message } = resource;

                if (__typename === 'HelpObjectStoreSearchResult') {
                    if (isExternal) {
                        externalResourcesCount += 1;
                    }
                    if (searchBackend === 'SEARCH_PLATFORM') {
                        firstPartySearchPlatformResourcesCount += 1;
                    }
                    if (message && !errorMessage) {
                        hasError = true;
                        errorMessage = message;
                    }
                }
            }
        });

        if (showSection && hasError) {
            sendSearchOperationalAnalyticEvents(
                { hasError, errorMessage, action: 'requestTypeSearchFailed' },
                createAnalyticsEvent,
                portalId
            );
        }

        if (showSection && !hasError) {
            sendSearchUIAnalyticEvents(
                {
                    externalResourcesCount,
                    firstPartySearchPlatformResourcesCount,
                    action: 'rendered',
                    resourceType: 'request-form',
                    resourceCount: slicedRequestForms.length,
                },
                createAnalyticsEvent,
                portalId
            );

            sendSearchOperationalAnalyticEvents(
                { action: 'requestTypeSearchSucceeded' },
                createAnalyticsEvent,
                portalId
            );
        }
        // adding slicedRequestForms to deps array causes multiple 'rendered' events to be fired
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [term, showSection, slicedRequestForms.length, createAnalyticsEvent, portalId]);

    useEffect(() => {
        updateResultsCount(slicedRequestForms.length);
    }, [term, slicedRequestForms.length, updateResultsCount]);

    return (
        <>
            {showSection && (
                <>
                    <ResultTitle>{formatMessage(messages.requestForms)}</ResultTitle>
                    <ResultList>
                        {slicedRequestForms.map((resource, index) => {
                            if (resource?.__typename === 'HelpObjectStoreSearchResult') {
                                const itemOrder = index + 1;
                                return (
                                    <li key={itemOrder}>
                                        <RequestFormItem itemOrder={itemOrder} term={term} result={resource} />
                                    </li>
                                );
                            }
                            return null;
                        })}
                    </ResultList>
                </>
            )}
        </>
    );
};

export default withAnalyticsEvents()(RequestForms);
