import React, { useCallback, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { fetchHelpCenterAction } from 'epics/model/help-center';
import { isSharedPortalCodeRemoved, isUnifiedHelpInJSMEnabled } from 'feature-flags';
import { usePathParam } from 'react-resource-router';
import { fetchPortalAction } from 'state/actions/portal';
import type { UpdateSearchAction, ShowSearchAction, ResetSearchAction } from 'state/actions/search';
import {
    showSearch as showSearchAction,
    resetSearch as resetSearchAction,
    updateSearch as updateSearchAction,
} from 'state/actions/search';
import { getIsAIAnswersEnabled } from 'state/selectors/env';
import { getShown, getTerm } from 'state/selectors/search';
import { SearchButton } from 'view/layout/search-button';
import type { WithAnalyticsEventsProps } from '@atlaskit/analytics-next';
import { withAnalyticsEvents, withAnalyticsContext } from '@atlaskit/analytics-next';
import { ScreenName } from '@atlassian/help-center-common-component/constants';
import { withPathParams } from '@atlassian/help-center-common-component/with-path-params';
import type { InjectedProps as WithParamsInjectedProps } from '@atlassian/help-center-common-component/with-path-params';
import { PortalAnalyticsModal } from '@atlassian/help-center-common-util/analytics';
import { getPortalIdAsNumber, isSinglePortalSearch } from './common';
import { InlineResultsContextProvider } from './inline-results-context';
import { SearchErrorBoundary } from './search-error-boundary';
import { SearchModal } from './search-modal';
import { SearchTextboxV2 } from './search-textbox-v2';

export interface Props {
    term: string;
    trigger?: 'icon';
    isOpen: boolean;
    isAiAnswersEnabled: boolean;
    onOpen: () => ShowSearchAction;
    onClose: () => ShowSearchAction;
    onSearch: () => UpdateSearchAction;
    resetSearch: () => ResetSearchAction;
    fetchPortals: typeof fetchPortalAction;
    fetchHelpCenter: typeof fetchHelpCenterAction;
    disableSearch?: boolean;
}

type SearchProps = Props & WithAnalyticsEventsProps & WithParamsInjectedProps;

export const Search = ({
    term,
    trigger,
    isOpen,
    isAiAnswersEnabled,
    onSearch,
    onClose,
    onOpen,
    resetSearch,
    fetchHelpCenter,
    fetchPortals,
    disableSearch,
}: SearchProps) => {
    const searchButtonRef = useRef<HTMLButtonElement>(null);
    const [portalId] = usePathParam('portalId');

    useEffect(() => {
        if (isSinglePortalSearch(portalId)) {
            fetchPortals({
                id: getPortalIdAsNumber(portalId),
                expand: ['kbs', 'reqTypes', 'reqGroups', 'orderMapping'],
            });
        } else if (!isSharedPortalCodeRemoved()) {
            fetchHelpCenter();
        }
    }, [portalId, fetchHelpCenter, fetchPortals]);

    const onReturnFocus = useCallback((_: Element) => {
        //FocusLock trap is still active due to that we deffer setting focus with zero-timeout
        setTimeout(() => {
            searchButtonRef?.current?.focus();
        }, 0);
        return true;
    }, []);

    useEffect(
        () => () => {
            resetSearch();
        },
        [resetSearch]
    );

    return (
        <SearchErrorBoundary id="search">
            <>
                {isOpen && (
                    <SearchModal
                        isOpen={isOpen}
                        term={term}
                        portalId={portalId}
                        onSearch={onSearch}
                        onClose={onClose}
                        onReturnFocus={onReturnFocus}
                    />
                )}
                {trigger === 'icon' ? (
                    <SearchButton ref={searchButtonRef} shown={isOpen} onClick={onOpen} disableSearch={disableSearch} />
                ) : (
                    <>
                        {!isOpen && (
                            <InlineResultsContextProvider>
                                <SearchTextboxV2
                                    term={term}
                                    isAiAnswersEnabled={isAiAnswersEnabled}
                                    disableSearch={disableSearch}
                                />
                            </InlineResultsContextProvider>
                        )}
                    </>
                )}
            </>
        </SearchErrorBoundary>
    );
};

const selector = createSelector(getShown, getTerm, getIsAIAnswersEnabled, (shown, term, isAiAnswersEnabled) => ({
    term,
    isOpen: shown,
    isAiAnswersEnabled: isAiAnswersEnabled && isUnifiedHelpInJSMEnabled(),
}));

const ConnectedSearch = connect(selector, {
    onOpen: () => showSearchAction(true),
    onClose: () => showSearchAction(false),
    onSearch: (term: string) => updateSearchAction(term, {}),
    resetSearch: resetSearchAction,
    fetchPortals: fetchPortalAction,
    fetchHelpCenter: fetchHelpCenterAction,
})(
    PortalAnalyticsModal<SearchProps>(ScreenName.HELP_CENTER_SEARCH)(
        withPathParams(
            withAnalyticsContext({ component: ScreenName.HELP_CENTER_SEARCH })(withAnalyticsEvents()(Search))
        )
    )
);

export default ConnectedSearch;
