import * as React from 'react';
import type { WithAnalyticsEventsProps } from '@atlaskit/analytics-next';
import { withCreateFireUIAnalytics } from '../with-create-fire-ui-analytics';

export type HtmlButtonProps = React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
> &
    WithAnalyticsEventsProps & {
        actionSubjectId: string;
    };

const SplicedPropsButton: React.FunctionComponent<HtmlButtonProps> = ({
    actionSubjectId,
    createAnalyticsEvent,
    children,
    ...props
}: HtmlButtonProps) => <button {...props}>{children}</button>;

export const HtmlButton = withCreateFireUIAnalytics<HtmlButtonProps>('button', {
    onClick: () => ({ action: 'clicked' }),
})(SplicedPropsButton);
