import * as React from 'react';
import type { InjectedIntlProps } from 'react-intl';
import { FormattedHTMLMessage, injectIntl } from 'react-intl';
import type { RequestDetailsResponse } from 'rest/request-details';
import { dismissRequestDetailsShowHideAnnouncement as dismissRequestDetailsShowHideAnnouncementRest } from 'rest/user';
import type { fetchPortalAction } from 'state/actions/portal';

import type { fetchRequestDetailsModel, changeSubscription } from 'state/actions/request-details';
import type { dismissRequestDetailsShowHideAnnouncement as dismissRequestDetailsShowHideAnnouncementAction } from 'state/actions/user';
import type { Portal } from 'state/persisted/portal';

import { withErrorPage } from '@atlassian/help-center-common-component/error-page';
import { trackIssueEvent } from '@atlassian/help-center-common-util/analytics';
import type { IssueAnalyticsContext } from '@atlassian/help-center-common-util/analytics/issue-context';
import * as qs from '@atlassian/help-center-common-util/history-qs';

import { ApprovalErrorFlag } from './approval-error-flag';
import approvalErrorMessages from './approval-error-flag/messages';
import { NUM_ACTIVITY_ITEMS_TO_HIDE_DETAILS_SECTION, HIDE_ACTIVITY_ITEMS_THRESHOLD } from './constants';
import RequestDetailsStateless from './request-details-stateless';

interface RequestDetailsProps {
    portal: Portal | undefined;
    requestDetails: RequestDetailsResponse | undefined;
    issueAnalyticsContext: IssueAnalyticsContext;
    isLoading: boolean;
    portalId: string;
    portalName: string | undefined;
    requestKey: string;
    hasConnectPanels: boolean;
    isAtlassianManagedAccount: boolean;
    fetchRequestDetails: typeof fetchRequestDetailsModel;
    fetchPortal: typeof fetchPortalAction;
    changeSubscriptionAction: typeof changeSubscription;
    showHideAnnouncementVisible: boolean;
    dismissShowHideAnnouncementAction: typeof dismissRequestDetailsShowHideAnnouncementAction;
}

interface State {
    isApprovalError: boolean;
}

export const APPROVAL_BY_EMAIL_RESULT_QUERY_PARAM = 'approval';
export const APPROVAL_BY_EMAIL_QUERY_PARAM_SUCCESS = '200';

export class RequestDetails extends React.Component<RequestDetailsProps & InjectedIntlProps, State> {
    detailsHiddenOnInitialView: boolean | undefined;
    activityItemsHiddenOnInitialView: boolean | undefined;

    state = {
        isApprovalError: false,
    };

    componentDidMount() {
        const { portalId, fetchPortal, fetchRequestDetails, requestKey: key } = this.props;

        fetchRequestDetails({ key, portalId: Number(portalId) });

        fetchPortal({ id: Number(portalId), expand: ['analyticsContext'] });

        this.trackIssueViewed();

        this.setState({
            isApprovalError: this.checkIfApprovalError(),
        });
    }

    componentDidUpdate(prevProps: RequestDetailsProps) {
        const { portal, requestDetails } = this.props;
        if (
            portal &&
            portal.analyticAttributes &&
            requestDetails &&
            !requestDetails.userMentionedDuringRequestCreation
        ) {
            if (!prevProps.portal || !prevProps.portal.analyticAttributes || !prevProps.requestDetails) {
                this.trackIssueViewed();
            } else if (
                portal.id !== prevProps.portal.id ||
                requestDetails.issue.id !== prevProps.requestDetails.issue.id
            ) {
                this.trackIssueViewed();
            }
        }
    }

    onNotificationChange = (shouldSubscribe: boolean) => {
        const { requestDetails, changeSubscriptionAction } = this.props;
        if (requestDetails) {
            changeSubscriptionAction(requestDetails.key, requestDetails.issue.id, shouldSubscribe);
        }
    };

    trackIssueViewed() {
        const { portal, requestDetails, issueAnalyticsContext } = this.props;
        if (!portal || !portal.analyticAttributes || !requestDetails) return;

        const { issue, issueType, workflowTransitions } = requestDetails;
        const { requestTypeId, id: issueId } = issue;

        const customerTransitionIds = workflowTransitions
            ? workflowTransitions.map((transition) => transition.id)
            : undefined;

        trackIssueEvent(
            {
                issueId,
                projectId: String(portal.projectId),
                action: 'viewed',
                attributes: {
                    customerTransitionIds,
                    numberOfCustomerTransitionIds: customerTransitionIds && customerTransitionIds.length,
                    portalId: String(portal.id),
                    issueTypeId: issueType,
                    requestTypeId: String(requestTypeId),
                    ...portal.analyticAttributes,
                },
            },
            issueAnalyticsContext.user,
            issueAnalyticsContext.portal
        );
    }

    isShowHideAnnouncementVisible = () => {
        if (this.detailsHiddenOnInitialView === undefined) {
            const { requestDetails, hasConnectPanels } = this.props;
            if (requestDetails) {
                const activityStreamLength = requestDetails.issue.activityStream.length;
                this.detailsHiddenOnInitialView =
                    !hasConnectPanels &&
                    activityStreamLength > NUM_ACTIVITY_ITEMS_TO_HIDE_DETAILS_SECTION &&
                    requestDetails.issue.fields.length > 0;
                this.activityItemsHiddenOnInitialView =
                    requestDetails.issue.activityStream.length > HIDE_ACTIVITY_ITEMS_THRESHOLD;
            }
        }

        return (
            this.props.showHideAnnouncementVisible &&
            !!(this.detailsHiddenOnInitialView || this.activityItemsHiddenOnInitialView)
        );
    };

    onShowHideAnnouncementDismiss = async () => {
        this.props.dismissShowHideAnnouncementAction();
        await dismissRequestDetailsShowHideAnnouncementRest();
    };

    checkIfApprovalError = () => {
        return (
            !!qs.get(APPROVAL_BY_EMAIL_RESULT_QUERY_PARAM) &&
            qs.get(APPROVAL_BY_EMAIL_RESULT_QUERY_PARAM) !== APPROVAL_BY_EMAIL_QUERY_PARAM_SUCCESS
        );
    };

    getApprovalError = (): string | undefined => {
        return this.checkIfApprovalError() ? qs.get(APPROVAL_BY_EMAIL_RESULT_QUERY_PARAM) : undefined;
    };

    onDismissError = () => {
        this.setState({
            isApprovalError: false,
        });
    };

    removeOldParticipantsAndReporterFromComment = (accountIds: Map<string, string>): Map<string, string> => {
        const requestDetails = this.props.requestDetails;
        if (requestDetails) {
            const { participants, reporter } = requestDetails;

            /* Removing user mentions which should not be added as request participants */
            reporter?.accountId && accountIds.delete(reporter?.accountId);
            participants.forEach((participant) => accountIds.delete(participant.accountId));
        }
        return accountIds;
    };

    getFlagTitleAndDescription = (): { title: string; description: React.ReactNode } => {
        // Error messages for approve by email
        const { intl, requestDetails } = this.props;
        const decision = requestDetails?.approvalStatus[0]?.decision?.decision;
        switch (qs.get(APPROVAL_BY_EMAIL_RESULT_QUERY_PARAM)) {
            case '400':
                if (decision) {
                    // if decision already made
                    return {
                        title: intl.formatMessage(approvalErrorMessages.approvalByEmailErrorFlagTitle400_2),
                        description: intl.formatMessage(approvalErrorMessages.approvalByEmailErrorFlagDescription400_2),
                    };
                }
                // if misspelling in url
                return {
                    title: intl.formatMessage(approvalErrorMessages.approvalByEmailErrorFlagTitle400_1),
                    description: (
                        <FormattedHTMLMessage {...approvalErrorMessages.approvalByEmailErrorFlagDescription400_1} />
                    ),
                };
            case '403':
                // if approver has been removed as approver by admin
                return {
                    title: intl.formatMessage(approvalErrorMessages.approvalByEmailErrorFlagTitle403),
                    description: intl.formatMessage(approvalErrorMessages.approvalByEmailErrorFlagDescription403),
                };
            default:
                return {
                    title: intl.formatMessage(approvalErrorMessages.approvalByEmailErrorFlagTitleDefault, {
                        value: qs.get(APPROVAL_BY_EMAIL_RESULT_QUERY_PARAM) ?? '',
                    }),
                    description: (
                        <FormattedHTMLMessage {...approvalErrorMessages.approvalByEmailErrorFlagDescriptionDefault} />
                    ),
                };
        }
    };

    render() {
        const { isApprovalError } = this.state;
        const { requestDetails, isLoading, portalName, requestKey, portal, hasConnectPanels } = this.props;
        const serviceDeskId = Number(portal?.serviceDeskId);
        const portalId = Number(this.props.portalId);
        const projectId = Number(portal?.projectId);
        const isAtlassianManagedAccount = this.props.isAtlassianManagedAccount;

        return (
            <>
                {isApprovalError ? (
                    <ApprovalErrorFlag
                        approvalError={this.getApprovalError()}
                        hasDecision={requestDetails?.approvalStatus[0]?.decision?.decision !== undefined}
                        onDismissError={this.onDismissError}
                    />
                ) : (
                    []
                )}
                <RequestDetailsStateless
                    requestDetails={requestDetails}
                    isLoading={isLoading}
                    isAtlassianManagedAccount={isAtlassianManagedAccount}
                    serviceDeskId={serviceDeskId}
                    portalId={portalId}
                    projectId={projectId}
                    requestKey={requestKey}
                    onNotificationChange={this.onNotificationChange}
                    portalName={portalName}
                    hasConnectPanels={hasConnectPanels}
                    showHideAnnouncementBannerVisible={this.isShowHideAnnouncementVisible()}
                    detailsHiddenOnInitialView={!!this.detailsHiddenOnInitialView}
                    activityItemsHiddenOnInitialView={!!this.activityItemsHiddenOnInitialView}
                    // @typescript-eslint upgrade to v5.61.0
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onShowHideAnnouncementDismiss={this.onShowHideAnnouncementDismiss}
                    removeOldParticipantsAndReporterFromComment={this.removeOldParticipantsAndReporterFromComment}
                />
            </>
        );
    }
}

export default withErrorPage(injectIntl(RequestDetails));
