export const ConversationProvider = {
    VIRTUAL_AGENT: 'VIRTUAL_AGENT',
    AI_ANSWERS: 'AI_ANSWERS',
} as const;

export type ConversationProvider = (typeof ConversationProvider)[keyof typeof ConversationProvider];

export interface InferenceResult {
    intentId: string;
    containerId: string;
}

export interface GlobalInterenceApiResponse {
    highConfidenceIntent?: InferenceResult;
    suggestedIntents: InferenceResult[];
}

export interface IntentInferenceProvider {
    inferIntent: (message: string) => Promise<GlobalInterenceApiResponse>;
}
